body {
  background: #f2f2f2 url(../img/background-image_tiny.png) no-repeat;
  background-size: cover !important;

  &.cursor-brush {
    cursor: url("../img/paint-brush.png") 12 15, auto !important;

    .item:hover,
    .content,
    .content:hover {
      cursor: url("../img/paint-brush.png") 12 15, auto !important;
    }
  }

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

// sampas enerji demo sayfasi
.sampas-page {
  height: 100vh;

  .sampas-widget {
    width: 500px;
    height: 300px;
    // background-color: rgb(245, 245, 191);
    border: 1px solid;
    padding: 5px 2px;
  }

  .tablo {
    display: flex;
  }

  ul {
    display: inline-block;
    &:last-of-type {
      margin-left: 8px;
    }

    li {
      font-size: 11px;
      line-height: 1.3;
      span {
        display: inline-block;
      }
      .title {
        width: 106px;
        font-weight: bold;
        color: #537339;
      }
    }
  }

  .tabs {
    margin-top: 20px;

    .btn {
      font-size: 12px;
      padding: 5px 10px;
      border: 1px solid transparent;
      @include border-radius(0);
      font-weight: normal;
      border-bottom: none;
      @include box-shadow(none);

      &.active {
        font-weight: bold;
        color: #269dd5 !important;
        border-bottom: 1px solid #269dd5;
        @include box-shadow(none);
        @include border-radius(none);
      }
    }

    .tab-contents {
      font-size: 13px;
      padding: 5px 3px;
    }

    table {
      width: 100%;
      td {
        font-size: 10px;
      }
      th {
        font-size: 10px;
      }
      tbody {
        max-height: 150px;
        overflow-y: scroll;

        tr {
          line-height: 1.5;
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

// for category and template grid height
.category-tampleta-grid {
  .template-panel {
    height: calc(100vh - 150px);

    .file-box {
      max-height: calc(100% - 64px);
    }
  }
}

// dikey A4
.a4-grid-lines.vertical {
  position: absolute;
  // top: 0;
  top: calc(50% - 421px);
  left: calc(50% - 298px);
  width: 595px;
  height: 842px;
  // width: 446px;
  // height: 631px;
  border: 1px solid #000;
  background: white;
}
.a4-grid-lines.horizontal {
  display: none;
  position: absolute;
  top: calc(50% - 298px);
  left: calc(50% - 421px);
  height: 595px;
  width: 842px;
  // width: 446px;
  // height: 631px;
  border: 1px solid #000;
  background: white;
}

.tip {
  height: 100vh;
}

.landing-page {
  font-family: "Montserrat", sans-serif;
}

// .landing-page,
.template-panel {
  * {
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  }
}

.form-group {
  margin-bottom: 0.8rem;
}

.login-form,
.register-form,
.forgotpassword-form,
.new-password-form {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: $color-white;
  align-items: center;
  background: #ffffff url(../img/form-bg.png) center 100% no-repeat;
  // background-size: 1040px 300px;
  padding-bottom: 250px;
  -webkit-justify-content: center;
  -webkit-align-items: center;
}

.login-form,
.register-form {
  padding-bottom: 0;
}

.register-form {
  .button {
    margin: 10px 0px;
  }

  input[id="email"] {
    text-transform: lowercase;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      text-transform: initial;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      text-transform: initial;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      text-transform: initial;
    }
  }

  @include phone {
    height: 100%;
    padding-top: 20px;
  }
}

.register-logo {
  margin: 25px 0px 18px 0px;
}

.click-link {
  font-size: 14px !important;
}

.recaptcha-text {
  color: #ff9595;
  font-size: 11px;
  display: block;
  margin-top: 5px;
}

.recaptcha-style {
  overflow: hidden !important;
}

.login-language {
  margin-top: 15px;
  position: absolute;
  z-index: 1;
  right: 25px;

  @include phone {
    right: -50px;
    position: absolute;
  }

  select {
    border: 0;
    background-color: $color-concrete;
    padding: 7px 10px;
    font-size: 13px;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
    font-weight: 500;
    color: $color-tundora;
    border-radius: 2px;

    option {
      font-size: 13px;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      font-weight: 500;
    }
  }
}

.profile-form {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  background: inherit;
  margin: 30px 0px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;

  .agreement-wrapper {
    input {
      width: 0;
    }
  }

  form {
    background-color: $color-white;
    padding: 20px 0px 30px 0px;
    border-radius: 15px;

    h4 {
      line-height: 3em;
    }

    input {
      width: 100%;
      height: 40px;
      border: 0;
      background-color: $color-concrete;
      font-size: 14px;
      text-indent: 10px;
      line-height: 20px;
      color: $color-mine-shaft;
      border-radius: 130px;
      border: 0;
      padding: 10px;
      @include transition(all 0.15s ease-in-out);
    }

    textarea {
      width: 100%;
      height: 100px;
      border: 0;
      resize: none;
      background-color: $color-concrete;
      font-size: 14px;
      text-indent: 10px;
      line-height: 20px;
      border-radius: 25px;
      padding: 10px;
      color: $color-mine-shaft;
      @include transition(all 0.15s ease-in-out);
    }

    #the-count {
      float: right;
      padding: 0.1rem 0 0 0;
      font-size: 0.875rem;
    }

    input.profile-email-input,
    input.contact-email-input {
      opacity: 0.8;
    }

    input:not(.profile-email-input):focus,
    textarea:focus {
      @include box-shadow(inset 0px 2px 7px rgba(0, 0, 0, 0.2));
      border: none;
      overflow: auto;
      outline: none;
      resize: none;
    }

    .button {
      @include transition(all 0.15s ease-in-out);
      color: $color-white;
      border: 0;
      font-size: $font-size-default;
      padding: 12px 30px;
      min-width: 100px;
      cursor: pointer;
    }

    .img-wrapper {
      height: 190px;
      width: 190px;
      border-radius: 100%;
      display: inline-block;
      overflow: hidden;
      background-color: #00ceff;
      border: none;

      .user-name-letters {
        color: white;
        font-size: 5rem;
        font-weight: bold;
        line-height: 190px;
      }
    }

    .photo {
      height: 190px;
      width: 190px;
      border-radius: 100%;
    }

    .button:hover {
      text-decoration: none;
    }

    .color-box {
      height: 30px;
      width: 30px;
      border-radius: 100%;
      text-indent: -99999px;
      position: absolute;
      left: calc(100% - 75%);

      @include phone {
        left: calc(100% - 95%);
      }

      @include tablet {
        left: calc(100% - 100%);
      }
    }

    .profile-agreement-info {
      font-size: 13px;

      i {
        color: #ffb100;
      }
    }

    .required-err {
      font-size: 10px;
      padding-left: 25px;
      &.red {
        color: red;
      }
    }

    .agreement-wrapper {
      span {
        text-align: left;
        left: 25px;
      }
    }
  }

  .a-position1 {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    border-width: 6px;
    padding: 5px;
    color: #ffffff !important;
    font-size: 14px;
    position: absolute;
    left: calc(100% - 75%);

    @include phone {
      left: calc(100% - 95%);
    }

    @include tablet {
      left: calc(100% - 100%);
    }

    &.remove-photo {
      background-color: red;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .edit-box {
    position: relative;
    top: -20px;
    width: 40%;
    margin-right: auto;
    margin-left: auto;
  }

  .image-box {
    display: none;
  }

  .a-position2 {
    font-size: 14px;
    background-color: $color-cyan-aqua-v2;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    color: #ffffff !important;
    position: absolute;
    right: calc(100% - 75%);
    padding: 5px;

    &:hover {
      cursor: pointer;
    }

    @include phone {
      right: calc(100% - 95%);
    }

    @include tablet {
      right: calc(100% - 100%);
    }
  }
}

header.header {
  // height: 78px;
  height: 56px;
  background: url("../img/header.png") center 100% no-repeat,
    linear-gradient(#01bfd9, #0083f4);
  width: 100%;
  text-align: center;
  position: relative;
  // z-index: 99;
  z-index: 10;

  .subscription-link {
    margin-right: 25px;
    margin-top: 8px;
    z-index: 9999;

    a {
      display: block;
      padding: 6px 10px;
      border: 2px solid #f58744;
      background: #f58744;
      font-weight: 500;
      font-size: 15px;
      color: white;
      @include border-radius(5px);
      transition: all 0.4s;

      &:hover {
        background: #f5701f;
        border-color: #f5701f;
        color: white;
      }
    }
  }

  .language {
    margin-right: 10px;
    margin-top: 9px;
    z-index: 9999;

    @include phone {
      margin-right: 10px;
      z-index: 9999;
    }

    select {
      border: 0;
      background-color: $color-concrete;
      padding: 7px 10px;
      font-size: 13px;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      font-weight: 500;
      color: $color-tundora;
      border-radius: 2px;

      option {
        font-size: 13px;
        font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        font-weight: 500;
      }
    }
  }

  .right-menu {
    position: relative;
    float: right;
    top: 0;
    right: 0;
    padding: 8px 0;
    margin-right: 15px;
    cursor: pointer;

    .user-name-letters {
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: white;
      font-weight: bold;
      text-align: center;
      border-radius: 100%;
      background-color: #cbcbcb;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 100%;
    }

    @include phone {
      right: 0px;
      margin-right: 15px;
    }

    .dropdown-menu-wrapper {
      position: absolute;
      top: 8px;
      right: 30px;
      padding-bottom: 16px;
      @include border-radius(4px);
      @include box-shadow(0 8px 12px 0 rgba(0, 0, 0, 0.12));

      @include phone {
        right: 0;
      }

      .dropdown-menu {
        padding-bottom: 16px;
        border: none;
        @include box-shadow(0 8px 12px 0 rgba(0, 0, 0, 0.12));
        ul {
          li {
            .sub-menu {
              a {
                color: #393939;
                background-color: #f5f5f5;
                i {
                  color: #393939;
                }
              }
            }

            a {
              display: flex;
              align-items: center;
              font-family: Century Gothic, CenturyGothic, AppleGothic,
                sans-serif;
              width: 255px;
              height: 33px;
              background-color: $color-white;
              font-size: 13px;
              color: #33bdd8;
              padding: 5px 16px 5px 16px;
              cursor: pointer;

              i {
                margin: 0px 0px 0px 5px;
                color: #33bdd8;
                margin-right: 16px;
                font-size: 24px;
              }

              .arrow-down {
                position: absolute;
                right: 15px;
                transition: all 0.4s ease;
                color: $color-white;
                top: 65px;
                font-size: 13px;
              }

              .rotate {
                transform: rotate(180deg);
                color: $color-cerulean;
              }

              &.up-menu {
                height: 56px;
              }
            }

            a:hover {
              background-color: #f9f9f9;
            }
          }
        }

        .arrow-size {
          font-size: 10px;
          margin-left: 30px;
        }

        .arrow {
          position: absolute;
          right: 7px;
          transform: rotate(45deg);
          -webkit-transform: rotate(180deg);
          width: 0;
          height: 0;
          border-left: 18px solid transparent;
          border-right: 18px solid transparent;
          border-top: 18px solid white;
          top: -15px;

          @include phone {
            left: 185px;
          }
        }
      }
    }
  }

  .header-icons {
    margin: 0 !important;
    img {
      width: 30px;
      height: 30px;
      margin-right: 13px;
    }
  }

  .remaining-container {
    margin-right: 82px;
    display: flex;
  }

  .remaining-maps{
  align-items: center;
  appearance: none;
  background: white;
  border-radius: 30px;
  border: 3px solid #fd7021;
  padding: 2px;
  box-sizing: border-box;
  display: inline-flex;
  height: 37px;
  width: 282px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  margin-top: 2.4%;
  margin-right: 1%;
  padding-left: 16px;
  position: relative;
  text-align: left;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 15px;
  color:#fd7021;
  padding-right: 40px;
}


  .circle {
    width: 45px;
    height: 45px;
    border: solid 3px #fd7021;
    background-color: #ffff;
    border-radius: 50%;
    margin-top: 0.2%;
    position: absolute;
    right: 210px;
}


  // added for click
  .right-menu {
    .dropdown-menu-wrapper {
      display: block;
    }
    .dropdown-menu {
      display: block;
      right: -35px;
      left: auto;
      top: 57px;
      padding: 0;
    }
  }
  // added for click

  .right-menu:hover {
    .dropdown-menu {
      // display: block;
      // right: -35px;
      // left: auto;
      // top: 57px;
      // padding: 0;

      @include phone {
        right: -15px;
      }
    }
  }

  .left-menu {
    position: fixed;
    left: 0;
    height: 100%;
    background-color: $color-white;
    width: 55px;
    transition: width 0.15s;
    border-left: 2px solid #dddddd;
    padding: 0px 0 90px 0;
    box-sizing: border-box;
    overflow-y: auto;
    cursor: pointer;
    font-size: 13px;
    -webkit-box-shadow: 1px 0 17px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 1px 0 17px 0 rgba(0, 0, 0, 0.1);

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-thumb {
      background-color: $color-silver;
    }

    &.wide {
      width: 238px;

      .left-logo {
        &.small {
          display: none;
        }
        &.big {
          display: block;
          // height: 41px;
          // width: 121px;
        }
      }

      .title {
        display: inline-block !important;
        margin-left: 30px;
      }
    }

    .sub-left-menu {
      padding: 25px 0;
    }

    .dropdown-menu-wrapper {
      position: absolute;
      top: 0;
      left: 0px;
    }

    .left-logo {
      &.small {
        display: block;
      }
      &.big {
        display: none;
      }
    }

    .left-logo {
      margin: 15px auto 0 auto;
    }

    .sub-bar {
      height: 48px;
      color: $color-blue-shine;
      position: relative;
      padding-left: 15px;
      display: flex;
      align-items: center;

      &:after {
        content: "";
        width: 4px;
        height: 48px;
        position: absolute;
        right: 0;
        background-color: transparent;
      }

      i {
        color: #a3a9ad;
        font-size: 24px;
      }

      .circle-icon {
        color: $color-aqua-blue;
        margin-right: 5px;
      }

      .left-icon {
        color: white;
      }

      i {
        // @include phone {
        // 	display: none;
        // }
        @include desktop {
          margin-left: 0;
        }
      }

      .title {
        display: none;
        color: $color-light-black;
        object-fit: contain;
        font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 3.5;
        letter-spacing: normal;
        text-align: left;
        float: left;

        &:hover {
          text-decoration: none;
        }
      }
    }

    .sub-bar:hover,
    .sub-bar.current-url {
      background-color: #f5f5f5;

      &:after {
        background-color: #33bdd8;
      }

      i {
        color: #33bdd8;
      }
    }
  }
}

.notification-form {
  background: $color-concrete;
  position: relative;

  form {
    background-color: $color-white;
    margin: 30px 0;
    padding: 30px 40px 30px 40px;
  }
}

//********************************Checkbox input********************************//

.container-checkbox {
  cursor: pointer;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  @include phone {
    position: fixed;
  }
}

.checkmark {
  position: absolute;
  left: 0;
  height: 25px !important;
  width: 25px !important;
  background-color: $color-pass-french !important;
}

.container-checkbox input:checked ~ .checkmark {
  background-color: $color-checkbox !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.container-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.container-checkbox .checkmark:after {
  left: 9px;
  top: 3px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

//----------------------------------------------------------------------------//

//********************************Switch input********************************//
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch-p {
  display: inline-block;
  padding: 0px 0px 7px 16px;
  margin-top: 14px;
  top: -10px;
  position: relative;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $color-dodger-blue;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: $color-white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  padding: 2px 0px 0px 0px;
}

input:checked + .slider {
  background-color: $color-green;
}

input:focus + .slider {
  box-shadow: 0 0 1px $color-green;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round::before {
  border-radius: 50%;
  content: "OFF";
  color: $color-white;
  font-size: 10px;
  font-weight: 500;
  text-indent: 23px;
}

.slider.round::after {
  border-radius: 50%;
  content: "ON";
  margin: 4px 2px;
  color: $color-white;
  font-size: 10px;
  padding-left: 5px;
  font-weight: 500;
  display: block;
}

.checkbox {
  display: none;
}

//----------------------------------------------------------------------------//

//********************************Radio input********************************//
.container-radio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    padding: 25px 5px 0px 0px;
    margin-top: -41px;
    font-size: 15px;
  }
}

.small-box {
  font-size: 13px;
  opacity: 0.6;
}

.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark-radio {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: $color-pass-french;
  border-radius: 50%;
}

.container-radio input:checked ~ .checkmark-radio {
  background-color: $color-checkbox;
}

.checkmark-radio:after {
  content: "";
  position: absolute;
  display: none;
}

.container-radio input:checked ~ .checkmark-radio:after {
  display: block;
}

.container-radio .checkmark-radio:after {
  top: 6px;
  left: 6px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $color-white;
}

.container-label {
  padding: 10px 25px 7px 25px;
}

.myplanner-table {
  width: 100%;

  .table-list {
    margin: 0px 30px 20px 30px;

    @include phone {
      overflow: scroll;
      margin: 0px 10px 0px 10px;
    }
  }
}

.taskboards-table {
  width: 100%;

  .table-list {
    margin: 0px 30px 20px 30px;

    @include phone {
      overflow: scroll;
      margin: 0px 10px 0px 10px;
    }
  }
}

.mindmap-table {
  margin-left: 238px !important;
  height: calc(100vh - 56px);
  overflow-y: scroll;
  background-image: url(./../img/map-list-bg-01.png);
  background-repeat: no-repeat;
  background-size: cover;

  &.wide {
    margin-left: 55px !important;
  }

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  .list-grid-options {
    i {
      font-size: 24px;
    }

    a {
      display: inline-block;
      line-height: 56px;

      &:hover {
        cursor: pointer;
      }

      &:not(.fav) {
        padding: 0 4px;
      }
    }

    .share {
      position: relative;
      margin-right: 8px;
      color: #e3e3e3;
      padding: 0 !important;

      &:hover {
        color: #e3e3e3;
      }

      &.active {
        color: #33bdd8 !important;
        &:hover {
          color: #33bdd8 !important;
        }
      }
    }

    .fav {
      position: relative;
      padding-right: 8px;
      margin-right: 8px;
      color: #e3e3e3;

      &:hover {
        color: #e3e3e3;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 13px;
        width: 1px;
        height: 24px;
        background-color: #e3e3e3;
      }

      &.active {
        color: #fd7021 !important;
        &:hover {
          color: #fd7021 !important;
        }
      }
    }

    .list,
    .grid {
      color: #a3a9ad;

      &:hover {
        color: #a3a9ad;
      }

      &.active {
        color: #33bdd8;
        &:hover {
          color: #33bdd8;
        }
      }
    }
  }

  .fav-list {
    display: none;

    &.show {
      display: block;
    }
  }

  .filter-line {
    .title-type {
      margin-top: 4px;
    }
    .help-filter {
      border-radius: 20px !important;
      height: 36px;
      width: 100%;
      border: solid 1px #d8d7d7;
      margin-bottom: 15px;
      padding: 5px 15px;
    }
  }

  .table-grid {
    display: none;
    min-height: 350px;

    &.show {
      display: block;

      .sub-list {
        display: none;
        width: 100%;

        &.show {
          display: block;
        }
      }
    }

    .grid-items {
      display: grid;
      grid-template-columns: repeat(auto-fill, 109px);
      justify-content: space-between;
      margin-top: 20px;
      max-height: 400px;
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 7px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: darkgrey;
      }

      &:after {
        content: "";
        flex: auto;
      }
    }

    .grid-item {
      position: relative;
      text-align: center;
      width: 109px;
      height: 99px;
      padding: 8px;
      @include border-radius(4px);
      border: solid 2px transparent;

      &:hover {
        border: solid 2px #33bdd8;
        cursor: pointer;
      }

      .shared-icon {
        position: absolute;
        right: 14px;
        top: 8px;
      }

      a.fav-action {
        width: 18px;
        height: 18px;
        position: absolute;
        left: 14px;
        top: 8px;
        color: #e3e3e3;

        i {
          font-size: 18px;
          padding: 0;
        }

        &:hover {
          cursor: pointer;
          color: #e3e3e3;
        }

        &.active {
          color: #fd7021 !important;
          &:hover {
            color: #fd7021 !important;
          }
        }
      }

      .name {
        font-size: 12px;
        font-weight: bold;
        line-height: 1.25;
        color: #393939;
        margin-top: 8px;
        max-height: 30px;
        overflow: hidden;
      }
    }
  }

  .table-list {
    display: none;
    margin-top: 30px;

    &.show {
      display: block;

      .sub-list {
        display: none;
        width: 100%;
        position: relative;

        a.list-search-btn {
          position: absolute;
          top: 6px;
          left: 71%;
          margin-right: 10px;
          color: #33bdd8;
          font-size: 18px;
          margin-right: 0;
          z-index: 100;

          @media screen and (min-width: 1025px) and (max-width: 1199px) {
            left: 70%;
          }

          @media screen and (min-width: 768px) and (max-width: 1024px) {
            left: 58%;
          }

          &:hover {
            cursor: pointer;
          }
        }

        &.show {
          display: block;
        }

        .ReactTable {
          width: 100%;
        }

        .video-container {
          .outline-sbox {
            margin-bottom: 20px;
            .sbox {
              border: 1px solid rgb(245, 245, 245);
              border-radius: 15px;
              box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
              height: 100%;
              padding: 10px;

              .card-body {
                a {
                  h6 {
                    color: #a3a9ad;
                    text-align: center;
                    word-break: break-word;
                    &:hover {
                      color: #33bdd8;
                    }
                  }
                }
              }
              a {
                img {
                  width: 100%;
                  padding: 10px 0px;
                  border-radius: 25px;
                }
              }
            }
          }
        }
      }
    }

    @include phone {
      overflow: scroll;
      margin: 0px 10px 0px 10px;
    }

    .action-button {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.filter-line {
  .input-title {
    border: 1px solid transparent;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    color: #a3a9ad;
    background-color: #f5f5f5;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
    border-radius: 100px;
    min-height: 37px;
    margin-bottom: 15px;
    padding: 6px 15px 2px;
  }

  .input-title input {
    border: none;
    width: 100%;
    border-radius: 15px;
    border: 1px solid grey;
    padding-left: 15px;
    height: 30px;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #a3a9ad;
    opacity: 1; /* Firefox */
    font-style: italic;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a3a9ad;
    font-style: italic;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #a3a9ad;
    font-style: italic;
  }

  .input-title_titles {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .input-title_titles li {
    align-items: center;
    background: #85a3bf;
    border-radius: 2px;
    color: white;
    display: flex;
    font-weight: 300;
    list-style: none;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px 10px;
    border-radius: 14px;
  }

  .input-title_titles li button {
    align-items: center;
    appearance: none;
    background: #333333;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    display: inline-flex;
    font-size: 12px;
    height: 15px;
    justify-content: center;
    line-height: 0;
    margin-left: 8px;
    padding: 0;
    transform: rotate(45deg);
    width: 15px;
  }

  .input-title_titles li.input-titles_titles_input {
    background: none;
    flex-grow: 1;
    padding: 0;
  }
}

.mindmap-table,
.myplanner-table {
  .template-panel {
    i {
      padding: 0px 8px 0px 2px;
    }

    .template-list-header {
      justify-content: space-between;

      display: flex;

      width: 100%;

      .input-tag {
        border: 1px solid transparent;

        display: flex;

        flex-wrap: wrap;

        font-size: 12px;

        color: #a3a9ad;

        background-color: #f5f5f5;

        font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;

        border-radius: 68px;

        min-height: 37px;

        margin-top: 8px;

        padding: 11px 14px 6px;
      }

      .input-tag input {
        border: none;

        width: 100%;

        border-radius: 6px;

        border: 1px solid grey;

        padding-left: 10px;

        height: 30px;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */

        color: #a3a9ad;

        opacity: 1; /* Firefox */

        font-style: italic;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */

        color: #a3a9ad;

        font-style: italic;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */

        color: #a3a9ad;

        font-style: italic;
      }

      .input-tag__tags {
        display: inline-flex;

        flex-wrap: wrap;

        margin: 0;

        padding: 0;

        width: 100%;
      }

      .input-tag__tags li {
        align-items: center;

        background: #85a3bf;

        border-radius: 2px;

        color: white;

        display: flex;

        font-weight: 300;

        list-style: none;

        margin-bottom: 5px;

        margin-right: 5px;

        padding: 5px 10px;

        border-radius: 14px;
      }

      .input-tag__tags li button {
        align-items: center;

        appearance: none;

        background: #333333;

        border: none;

        border-radius: 50%;

        color: white;

        cursor: pointer;

        display: inline-flex;

        font-size: 12px;

        height: 15px;

        justify-content: center;

        line-height: 0;

        margin-left: 8px;

        padding: 0;

        transform: rotate(45deg);

        width: 15px;
      }

      .input-tag__tags li.input-tag__tags__input {
        background: none;

        flex-grow: 1;

        padding: 0;
      }
    }

    .tabs-buttons {
      border: none;

      &:focus {
        color: #2c9cb2;

        background-color: #f5f5f5;

        box-shadow: 0 0 0 0.2rem rgba(69, 188, 209, 0.255);
      }
    }

    .hr-border {
      margin: 3px 0px 10px 0px;
    }
  }
}

.change-password-form {
  background: inherit;
  margin: 30px 0px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;

  form {
    background-color: $color-white;
    padding: 50px 0px 50px 0px;
    border-radius: 15px;

    input {
      width: 100%;
      height: 40px;
      border: 0;
      background-color: $color-concrete;
      border-radius: 130px;
      font-size: $font-size-default;
      text-indent: 10px;
      line-height: 20px;
      color: $color-mine-shaft;
      border: 0;
      @include transition(all 0.15s ease-in-out);

      @include phone {
        width: 100%;
      }
    }

    input:focus {
      @include box-shadow(inset 0px 2px 7px rgba(0, 0, 0, 0.2));
    }

    .button {
      @include transition(all 0.15s ease-in-out);
      color: $color-white;
      border: 0;
      font-size: $font-size-default;
      padding: 12px 30px;
      min-width: 100px;
      cursor: pointer;
    }

    .button:hover {
      text-decoration: none;
    }
  }
}

.register-label {
  color: #000;
  font-size: 12px;
  margin-bottom: 0;
  padding: 0px 13px 0px 48px;
}

.login-label {
  font-size: 14px;
  padding: 0px 13px 0px 48px;
  color: $color-light-black !important;
  margin-bottom: 0;

  @include phone {
    font-size: 13px !important;
  }
}

.agreement-wrapper {
  display: flex;
  align-items: center;
  height: 20px;

  label {
    padding-right: 5px;
  }

  span.text {
    font-size: 12px;
    margin-right: 6px;
    text-align: left;
  }

  a {
    font-size: 12px;

    &:hover {
      cursor: pointer;
    }

    i {
      font-family: fontAwesome;
      font-size: 13px;
    }
  }
}

.membership-agreement {
  font-size: 12px;
  text-align: left;
  overflow-y: scroll;
  height: 35vh;
  list-style-type: circle;

  dt,
  dd {
    float: left;
  }
  dt {
    clear: both;
    width: 26%;
  }

  .responsive-table {
    overflow-x: auto;
    margin-bottom: 20px;
  }
  table {
    width: 100%;
    td {
      border: 1px solid;
      padding: 5px;
    }
  }

  a {
    text-decoration: underline;
    color: #33bdd8;
    font-weight: 500;
  }

  .agree-with-checkbox {
    input[type="checkbox"] {
      position: relative;
      top: 3px;
      left: 5px;
      &:disabled {
        background-color: transparent;
        border-color: black;
      }
    }
  }
}

.agreement-detail-page {
  padding: 20px 40px 40px 40px;
  font-size: 14px;

  .logo {
    margin-bottom: 40px;
  }

  h5 {
    text-align: center;
    margin-bottom: 20px;
  }

  h6 {
    font-size: 1rem;
  }
}

.membership-agreement-color {
  color: #000 !important;
}

.register-checkbox {
  .checkmark {
    position: absolute;
    left: 32px;
    top: -2px;
    height: 24px !important;
    width: 24px !important;
    border-radius: 4px;
    border: solid 2px #33bdd8;
    background-color: white !important;
    // margin: -11px 14px;
  }

  .checkmark::after {
    left: 6px !important;
    top: 2.3px !important;
    width: 7px !important;
    height: 12px !important;
    border-width: 0 2px 2px 0 !important;
    top: 14px;
    margin: 0px 0px;
  }
}

.landing-page {
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: $color-white !important;
  background-size: cover;
  overflow: hidden;

  .landing-page-mail {
    font-size: 1rem;
    line-height: 1.5rem;
    color: #00bfd8;
    margin-bottom: 20px;
    margin: 10px;
    font-weight: 400;

    i {
      font-family: fontAwesome;
      @include phone {
        margin-right: 8px !important;
      }
    }

    @include phone {
      margin-top: 5px !important;
      margin-bottom: 0;
      margin: 0;
    }
  }

  .navbar-toggler {
    margin-right: 10px;

    @include phone {
      position: fixed;
      .close-icon {
        img {
          margin: 0px !important;
        }
      }
    }
  }

  .navbar {
    .yellow-button:hover {
      color: $color-white;
      text-decoration: none;
    }
  }

  .home-collapse {
    @include tablet {
      // max-width: 300px;
      background-color: $color-white;
      margin-left: auto;
      padding-bottom: 15px;
      // padding: 14px 10px;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      .navbar-nav .nav-link {
        color: #656565 !important;
      }
    }

    @include phone {
      align-items: flex-start;
      min-height: 863px;
      padding-bottom: 10px;
      background: #fff;
    }
  }

  .header-wraps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .language-page {
      position: fixed;
      right: 1rem;
    }
    @include tablet {
      width: 100%;
      height: 100%;
    }

    @include phone {
      display: grid !important;
      justify-content: center !important;
      width: 100%;
      height: 100%;
    }
  }

  .nav-item {
    padding: 0 4px;
    @include phone {
      margin: 0 16px;
      padding: 0px !important;
      border-bottom: 1px solid #f2f2f2;
    }
  }

  .navbar-nav {
    @include phone {
      padding: 0px 16px;
      width: 100%;
      text-align: center;
      .hg-button-container {
        display: flex;
        width: 100%;
        margin-top: 32px;
        place-content: center;
      }
    }
  }

  img {
    position: relative;
    margin: 5px 35px !important;

    @include phone {
      margin: 0px 5px !important;
    }
  }

  @include phone {
    background-color: $color-white !important;
  }

  .navbar {
    padding: 0;
  }

  .navbar-brand {
    img {
      height: 51px;
      width: auto;
      margin: 0 15px !important;
      @include phone {
        width: 121px;
        height: 40px;
      }
    }
  }
}

// custom navbar
.navbar-custom {
  font-family: "Montserrat", sans-serif;
  height: 74px;
  padding: 2.125rem 1.5rem 2.125rem 2rem;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  transition: all 0.2s;

  @media (min-width: 768px) {
    padding: 0 86px !important;
  }

  .navbar-nav {
    .nav-item {
      &.corporate-menu {
        position: relative;

        &:hover {
          ul {
            display: block;
          }
        }
        .dropdown-toggle {
          color: #656565;
          line-height: 1.43;
          font-size: 14px;
          transition: all 0.15s ease-in-out;
          font-weight: 400;
          display: block;
          @include phone {
            font-family: Montserrat !important;
            font-size: 20px !important;
            font-weight: 500 !important;
            line-height: 1.68 !important;
            padding: 0.625rem 0.75rem 0.625rem 0.75rem;
            color: #393939 !important;
            text-decoration: none;
            transition: all 0.2s ease;
            font: 600 0.7rem "Monserrat", sans-serif;
          }
        }
        ul {
          display: none;
          position: absolute;
          top: 21px;
          left: 0;
          padding: 15px 10px;
          width: 180px;
          background: white;
          box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.12);

          &:before {
            content: "";
            width: 0;
            height: 0;
            display: unset;
            position: absolute;
            top: -7px;
            left: 20px;
            border-left: 9px solid transparent;
            border-right: 9px solid transparent;
            border-bottom: 9px solid white;
          }
          @include phone {
            top: 38px;
            left: 19%;
          }
        }
      }
    }

    @media (min-width: 992px) {
      align-items: center;
    }
  }

  .navbar-nav .nav-link,
  .navbar-nav .nav-link.external-link {
    color: #656565;
    line-height: 1.43;
    font-size: 14px;
    transition: all 0.15s ease-in-out;
    font-weight: 400;

    &:before {
      display: block;
      content: attr(title);
      font-weight: bold;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover {
      font-weight: 600;
    }

    &:hover,
    &:focus {
      color: #333333;
    }

    @media screen and (min-width: 992px) and (max-width: 1200px) {
      max-width: 110px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .navbar-nav .active > .nav-link {
    color: #333333 !important;
    font-weight: 600 !important;
  }

  @include phone {
    height: 56px !important;
    display: block !important;
    background-color: #ffffff !important;
    background: #fff !important;
    box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1) !important;
    font: 600 0.875rem/0.875rem "Raleway", sans-serif !important;
    @include transition(all 0.5s);
  }

  .bars-icon,
  .close-icon {
    color: black !important;

    i {
      font-family: fontAwesome;
    }
  }

  .navbar-toggler-icon {
    @include phone {
      display: block;

      i {
        position: relative;
        top: 10px;
        z-index: 9999;
        color: black;
      }
    }
  }

  .navbar-toggler-icon-none {
    @include phone {
      display: none;
    }
  }

  .times-toggler-icon-block {
    @include phone {
      display: block;

      i {
        z-index: 9999;
        color: black;
      }
    }
  }

  .sub-menu-icon2 {
    @include phone {
      display: none;
    }
  }
}

// header content
.header-content {
  // position: relative;
  // top: 140px;

  .image-container {
    width: 90%;
  }

  img,
  video {
    height: 303px;

    @media (max-width: 768px) {
      height: auto;
      margin: 20px auto 0 auto !important;
      width: 78%;
    }
  }

  @media (min-width: 1025px) {
    padding: 0 86px !important;
  }
}

.home {
  position: relative;
  left: 0;
  top: 0;
  margin-top: 74px;
  height: 100%;
  width: 100%;
  // background: #f5f5f5 url(../img/header-banner-img-group.png) no-repeat;
  background: transparent;
  background-position-x: right;
  background-size: cover;
  box-shadow: none;
  text-align: unset;

  & > img {
    margin: 0 !important;
    height: 100%;
    width: 100%;

    @include phone {
      display: none;
    }
  }

  @media (min-width: 1600px) {
    background-size: contain;
    background-position-x: center;
  }

  @include tablet {
    height: 100%;
    background-position-x: 100%;
  }

  @include phone {
    margin-top: 56px !important;
    background: transparent url(../img/header-banner-img-group_tiny.png) center
      center no-repeat;
    background-size: cover;
    background-position-x: 85%;
    .text-container h1 {
      margin-bottom: 8px !important;
      line-height: 0.4 !important;
      max-width: 297px !important;
      .page-template-free {
        font-size: 24px;
        font-weight: 800;
        line-height: 1.4;
        color: #333;
      }
    }
    .text-container .w-100.p-large {
      letter-spacing: 0.2px;
      max-width: 249px;
      font-size: 16px;
      line-height: 1.4;
      color: #656565;
    }
    .free-btn {
      text-align: center !important;
      color: #fff !important;
      background-color: #f5701f !important;
    }
  }

  .free-btn {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 1.43 !important;
    color: #f5701f;
    border-radius: 2px;
    border: solid 3px #f5701f;
    background-color: transparent;

    &:hover {
      background-color: #f5701f;
      color: #fff;
    }
  }

  h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 1.4;
    margin-bottom: 20px;
    color: #333;
  }

  .turquoise {
    color: #00bfd8;
  }

  .btn-solid-lg {
    display: inline-block;
    padding: 1.375rem 2.625rem 1.375rem 2.625rem;
    border: 0.125rem solid #00bfd8;
    border-radius: 2rem;
    background-color: #00bfd8;
    color: #fff;
    font: 700 0.75rem/0 "Raleway", sans-serif;
    text-decoration: none;
    transition: all 0.2s;
  }

  .header-content {
    position: absolute;
    top: calc(50% - 240px);

    @media (min-width: 768px) and (max-width: 1200px) {
      top: 0;
    }

    @include phone {
      position: unset;
    }
  }

  .text-container {
    margin-left: 1rem;
    margin-right: 2rem;
    height: 100%;
    max-width: 510px;
    padding: 60px 0 46px 30px;

    h1 {
      width: 100%;
      max-width: 510px;
      font-size: 44px !important;
      font-weight: 800;
      line-height: 1.4;
      color: #333;

      @media (min-width: 768px) and (max-width: 1200px) {
        font-size: 30px !important;
      }
    }

    p {
      margin-bottom: 30px;
      font-size: 16px;
      color: #656565;
    }

    @media (min-width: 768px) and (max-width: 1200px) {
      padding: 20px 0;
      max-width: 90%;
    }

    @include tablet {
      margin-top: 0;
    }

    @include phone {
      padding-left: 0;
    }
  }
}

.cards-1,
.features {
  padding-top: 7rem;
  padding-bottom: 1.625rem;
  text-align: center;

  @include phone {
    top: 340px;
    position: relative;
  }

  .p-heading,
  .p-wrapper {
    width: 55%;
    margin-right: auto;
    margin-left: auto;
  }

  .p-large {
    font: 400 1rem/1.5rem "Raleway", sans-serif;

    @include phone {
      font-size: 14px;
    }
  }

  .card {
    max-width: 20.3rem;
    display: inline-block;
    vertical-align: top;
    margin-right: auto;
    margin-bottom: 4.5rem;
    margin-left: auto;
    padding: 3.25rem 2rem 2rem 2rem;
    border: 1px solid #c4d8dc;
    border-radius: 0.5rem;
    background: transparent;
    margin: 10px;
  }

  .card-image {
    width: 6rem;
    height: 6rem;
    margin-right: auto;
    margin-bottom: 2rem;
    margin-left: auto;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  .card-body {
    padding: 0;
  }

  .card-title {
    margin-bottom: 0.875rem;
  }

  h4 {
    color: #393939;
    font: 700 1.375rem/1.875rem "Raleway", sans-serif;
  }

  p {
    color: #626262;
    font: 400 0.875rem/1.375rem "Raleway", sans-serif;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h2 {
    padding-bottom: 1.625rem;
    text-align: center;
  }
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

// features start
.sub-features1 {
  background: transparent url(../img/green-feature-bg_tiny.png) center center
    no-repeat;
  background-size: cover;
  padding-top: 60px;
  padding-bottom: 60px;

  &__bottom {
    margin-top: 60px;
    @include phone {
      margin-top: 0px !important;
    }
  }

  &__item {
    color: #33bdd8;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.4;
    margin-bottom: 16px;
  }

  img {
    height: 320px;
    margin: 0 !important;

    @include phone {
      height: auto;
    }
  }

  .image-container {
    &__top {
      text-align: left;

      @include tablet {
        text-align: center;
      }
    }
    &__bottom {
      @include tablet {
        text-align: center;
      }
    }
  }

  .text-container {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > * {
      color: #fff;
    }

    &__top {
      padding-top: 30px;
      // padding-left: 30px;

      @include phone {
        padding-left: 0;
        padding-top: 15px;
      }
    }

    &__bottom {
      padding-top: 30px;
      padding-bottom: 30px;
      @include phone {
        padding-top: 15px !important;
        padding-bottom: 24px !important;
      }
    }
  }

  h2 {
    margin-bottom: 2.5rem;
    color: #33bdd8;
    font-size: 34px;
    font-weight: 800;
    line-height: 1.41;

    &.max-w {
      max-width: 340px;
    }
    @include phone {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 800;
    }
  }

  p {
    color: #183057;
    margin-bottom: 32px;
    font-size: 16px;
    @include phone {
      line-height: 1.4;
    }
  }

  @media (min-width: 1025px) {
    padding: 60px 86px 60px 86px !important;
  }
  @include phone {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
  }
}

.basic-2,
.sub-features2 {
  padding-top: 48px;
  padding-bottom: 96px;

  img {
    height: 372px;
    margin: 0 !important;
  }

  .image-container {
    text-align: center;

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .text-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    margin-bottom: 2.5rem;
    color: #33bdd8;
    font-size: 34px;
    font-weight: 900;
    line-height: 1.41;
  }

  a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
  }

  .fas,
  .i-icon {
    color: #00bfd8;
    line-height: 1.375rem;
    font-family: fontAwesome;
  }

  .media-body,
  .section-description {
    margin-bottom: 1.375rem;
    font-size: 16px;
    line-height: 1.38;
    color: #183057;
  }

  .desc-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__item {
      width: 50%;
      color: #33bdd8;
      font-size: 24px;
      font-weight: bold;
      line-height: 1.4;
      margin-bottom: 16px;
    }
  }

  .btn-solid-reg,
  .discover-button {
    display: inline-block;
    padding: 1.1875rem 2.125rem 1.1875rem 2.125rem;
    border: 0.125rem solid #00bfd8;
    border-radius: 2rem;
    background-color: #00bfd8;
    color: #fff;
    font: 700 0.75rem/0 "Raleway", sans-serif;
    text-decoration: none;
    transition: all 0.2s;
    margin-top: 0.625rem;
  }

  @media (min-width: 1025px) {
    padding: 48px 86px 96px 86px !important;
  }
}

.education {
  padding: 90px 0;
  background: #fd7021 url(../img/landing-page-education-bg.png) center center
    no-repeat;
  background-size: cover;

  .text-container {
    & > * {
      color: #fff;
    }

    h2 {
      font-size: 47px;
      font-weight: 900;
      line-height: 1.4;
      margin-bottom: 32px;
    }

    h6 {
      font-size: 24px;
      font-weight: 900;
      line-height: 1.4;
      margin-bottom: 30px;
    }

    .trainer {
      display: flex;

      img {
        margin: 0 !important;
        height: 200px;
      }

      &_wrapper {
        position: relative;
        .gradient-area {
          position: absolute;
          bottom: 0;
          left: 0;
          width: calc(100% - 10px);
          height: 70px;
          background-image: linear-gradient(
            to bottom,
            rgba(253, 112, 33, 0) 50%,
            #fd7021
          );
        }
        .see-more {
          position: absolute;
          bottom: 0;
          right: 0;
          text-decoration: underline;
          font-size: 13px;
        }
      }

      &_infos {
        margin-left: 32px;
        max-height: 200px;
        // overflow-y: scroll;
        // &::-webkit-scrollbar {
        //   width: 7px;
        // }
        // &::-webkit-scrollbar-thumb {
        //   background-color: rgba(255, 255, 255, 0.5);
        //   border-radius: 6px;
        // }
        // &::-webkit-scrollbar-track {
        //   background-color: rgba(255, 255, 255, 0.25);
        //   border-radius: 6px;
        // }
      }
      &_name,
      &_role {
        font-size: 16px;
        font-weight: bold;
        line-height: 1.38;
        margin-bottom: 13px;
      }
    }
  }

  .image-container {
    .big {
      text-align: center;

      img,
      video {
        height: 303px;
        width: 100%;
        margin: 0 !important;

        @media (min-width: 1200px) {
          margin: 0 0 -10px 0 !important;
        }

        &:focus {
          outline: none !important;
        }
      }

      @media (min-width: 1200px) {
        background-color: #fff;
      }
    }
    .small {
      margin-top: 16px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      img,
      video {
        height: 89px;
        margin: 0 !important;
      }
    }
    .see-more {
      display: flex;
      align-items: center;
      width: 140px;

      span {
        display: inline-block;
        width: 106px;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.43;
        margin-right: 10px;
      }
    }

    @media (max-width: 768px) {
      margin-top: 30px;
    }
  }

  @media (min-width: 1025px) {
    padding: 90px 86px !important;
  }
}

.triple-features {
  z-index: 10;
  background: #fff;
  background-size: cover;

  &.double-wrapper {
    padding: 550px 0 50px 0 !important;

    @media (max-width: 992px) {
      padding-top: 30px !important;
    }
  }

  .feature {
    z-index: 1000;
    max-width: 350px;
    min-height: 427px;
    max-height: 427px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.1);

    &.full-height {
      min-height: 100%;
      max-height: 100%;
      background: #fff;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      .text-wrapper {
        div {
          p {
            max-height: 100%;
            overflow: auto;
            display: block;
            -webkit-line-clamp: initial;
            -webkit-box-orient: vertical;
          }
        }
      }
    }

    &.feature-2 {
      @media (min-width: 992px) {
        margin: 0 auto;
      }
    }
    &.feature-3 {
      @media (min-width: 992px) {
        margin: 0 0 0 auto;
      }
    }

    .color-bg {
      padding: 15px;
      height: 197px;
      border-radius: 2px;
      border: solid 2px #333333;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      img {
        position: inherit;
        margin: 0 !important;
        width: 100%;
        height: 100%;

        &.small-img {
          position: absolute;
          top: auto;
          left: auto;
          width: auto;
          height: auto;
        }
      }

      @media (max-width: 992px) and (min-width: 768px) {
        min-width: 348px;
      }
      @include phone {
        min-width: 258px;
      }
    }

    .text-wrapper {
      padding: 23px 30px;

      div {
        margin-bottom: 10px;
      }

      h1 {
        color: #333333;
        font-size: 34px;
        font-weight: 800;
        line-height: 1.4;
        margin-bottom: 15px;

        @media (max-width: 1250px) and (min-width: 993px) {
          max-height: 95px;
          margin-bottom: 12px;
        }
        @include phone {
          font-size: 24px;
          margin-bottom: 5px;
        }
      }

      p {
        color: #656565;
        font-size: 16px;
        line-height: 1.38;
        max-height: 282px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        @include phone {
          -webkit-line-clamp: 6;
        }
      }

      #more {
        display: none;
      }
      .read-more {
        display: inline-block;
        color: #33bdd8;
        text-decoration: underline;
        font-size: 14px;
        &:hover {
          cursor: pointer;
        }
      }

      @media (max-width: 992px) and (min-width: 768px) {
        padding: 20px 20px 0 20px;
      }
      @include phone {
        padding: 16px !important;
      }
    }

    @media (max-width: 992px) and (min-width: 768px) {
      display: flex;
      align-items: flex-start;
      height: auto;
      min-height: auto;
      margin-bottom: 30px;
      max-width: unset;
    }

    @media (max-width: 767px) {
      margin-bottom: 18px;
      max-width: unset;
      max-height: 404px;
      min-height: 404px;
    }
  }

  .relative-container {
    position: relative;

    .triple-features-row {
      position: absolute;
      top: 30px;
      left: 15px;
      right: 15px;
      z-index: 1000;
      .col-lg-4 {
        @include phone {
          padding-right: 0px !important;
          padding-left: 16px !important;
        }
      }
      @media (max-width: 992px) {
        position: unset;
      }
      @include phone {
        flex-wrap: inherit;
        overflow-x: auto;
        padding-bottom: 20px;
      }
    }

    .double-features-row {
      padding-top: 520px;
      z-index: -1000;

      @media (max-width: 992px) {
        padding-top: 30px;
      }
    }
  }
  // double features
  .double-features-row {
    .feature-wrap {
      z-index: -1000;
      border-radius: 2px;
      box-shadow: 0 11px 20px 0 rgba(0, 0, 0, 0.1);
      min-height: 716px;
      @include phone {
        margin-bottom: 25px;
        min-height: 367px;
        min-width: 296px;
        padding-top: 0px !important;
        &.full-height {
          min-height: 100%;
          max-height: 100%;
          background: #fff;
          transition: all 0.2s ease-in-out;
          cursor: pointer;

          .text-wrapper {
            div {
              p {
                max-height: 100%;
                overflow: auto;
                display: block;
                -webkit-line-clamp: initial;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
        .text-wrapper {
          padding: 16px !important;
          h1 {
            font-size: 24px;
            margin-bottom: 4px;
          }
          p {
            max-height: 282px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 6;
            -webkit-box-orient: vertical;
            line-height: 1.38;
            color: #656565;
          }
        }
      }

      & > * {
        z-index: -1000;
      }

      h1 {
        font-size: 34px;
        font-weight: 800;
        color: #333;
        line-height: 1.4;
        margin-bottom: 15px;
      }

      img {
        position: inherit;
        margin: 0 !important;
        width: 100%;
        height: 100%;
      }

      .text-wrapper {
        padding: 30px;

        p {
          margin-bottom: 0;
        }
      }

      @media (max-width: 991px) {
        padding-top: 15px;
      }
    }
  }

  @media (min-width: 1025px) {
    padding: 60px 86px 0 86px !important;
  }

  @media (max-width: 1024px) {
    padding-top: 40px !important;
  }
  @include phone {
    padding-top: 24px !important;
  }
}
.triple-features.double-wrapper {
  @include phone {
    padding-bottom: 0px !important;
    padding-top: 0px !important;

    .double-features-row {
      flex-wrap: inherit;
      overflow-x: auto;
    }
  }
}
.feedbacks {
  z-index: 1;
  padding: 90px 0;
  background: #183057;
  @include phone {
    padding: 24px 0;
  }
  .not-login-info {
    color: #fff;
    font-size: 16px;
    line-height: 1.38;
  }

  .top-area {
    width: 100%;

    .title {
      color: #fff;
      font-size: 34px;
      font-weight: 800;
      line-height: 1.41;
      margin-bottom: 32px;
      @include phone {
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
    .sub-title {
      color: #fff;
      font-size: 16px;
      line-height: 1.38;
      max-width: 540px;
      margin-bottom: 0;
    }
    .stars-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .stars {
        padding: 10px 30px;
        margin-left: 30px;
        border-radius: 32px;
        box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.12);
        border: solid 2px #ffffff;

        .star-rating {
          display: flex;
          align-items: center;
        }

        .custom-star {
          font-size: 21px;
          color: #fcbc2c;
          margin-right: 10px;

          &:last-of-type {
            margin-right: 0;
          }

          &:hover {
            cursor: pointer;
          }
        }

        * {
          border: none !important;
          outline: none !important;
        }

        .react-stars {
          width: 155px;
          text-align: center;

          span {
            &:last-of-type {
              i {
                margin-right: 0;
              }
            }
          }
        }

        @media (max-width: 600px) {
          margin-top: 30px;
          margin-left: 0;
        }
      }

      i {
        font-family: "fontAwesome";
        font-style: normal;
        margin-right: 15px;
      }

      @media (max-width: 600px) {
        flex-direction: column;
      }
    }
  }

  .bottom-area {
    position: relative;
    background-color: #fff;
    padding: 32px;
    margin-top: 45px;
    border-radius: 4px;
    box-shadow: 0 3px 22px 0 rgba(0, 0, 0, 0.12);

    .gradient-area {
      position: absolute;
      bottom: 31px;
      height: 70px;
      width: calc(100% - 32px);
      background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0) 50%,
        #ffffff
      );
    }

    .feedback-list {
      max-height: 320px;
      padding-right: 6px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #d1d1d1;
        border-radius: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: #f2f2f2;
        border-radius: 4px;
      }

      &__item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 15px 0;
        border-bottom: solid 1px #ededed;

        &:first-of-type {
          border-top: solid 1px #ededed;
        }

        img {
          margin: 0 32px 0 0 !important;
          height: 40px;

          @media (max-width: 767px) {
            align-self: flex-start;
          }
        }

        .user-img {
          margin: 0 32px 0 0 !important;
          height: 41px;
          width: 41px;
          border-radius: 100%;
          color: #fff;
          text-align: center;
          line-height: 41px;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          overflow: hidden;

          @media (max-width: 767px) {
            align-self: flex-start;
          }
        }

        .right-wrap {
          flex: 1;
        }

        .name {
          font-family: "Roboto", sans-serif;
          color: #bcbcbc;
          font-size: 16px;
          line-height: 1.38;
          margin-bottom: 10px;
        }

        .title {
          font-family: "Roboto", sans-serif;
          color: #000;
          font-size: 15px;
          line-height: 1;
          margin-bottom: 5px;
        }

        .text-area {
          display: flex;
          justify-content: space-between;
          flex: 1;

          .text {
            text-align: left;
            color: #656565;
            font-size: 16px;
            line-height: 1.38;
            max-width: 550px;
            min-height: 44px;
          }

          .date {
            align-self: flex-end;
            font-size: 14px;
            line-height: 1.43;
            color: #bcbcbc;
            margin-left: 10px;
          }
        }

        .star-area {
          padding: 4px 30px;
          margin-left: 30px;
          border-radius: 32px;
          border: solid 2px #ededed;
          align-self: flex-start;

          .react-stars {
            width: 155px;
            text-align: center;

            span {
              &:last-of-type {
                i {
                  margin-right: 0;
                }
              }
            }
          }

          i {
            font-family: "fontAwesome";
            font-style: normal;
            margin-right: 15px;
          }

          @media (max-width: 767px) {
            margin-top: 10px;
            margin-left: 0;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: flex-end;
        }
      }
    }
  }

  @media (min-width: 1025px) {
    padding: 90px 86px !important;
  }
}

.landing-page-footer {
  background-color: #020303;
  padding: 28px 0;
  font-size: 14px;
  line-height: 1.4;
  @include phone {
    padding: 18px 0;
  }
}

// blog page
.blog-page {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  margin-top: 74px;

  &.no-blog-data {
    // data yoksa
    height: calc(100vh - 150px);
    padding: 30.5px 85px 96px 85px;
  }

  .no-blog-data {
    // data yoksa
    height: calc(100vh - 240px);
    padding: 50px 0 0 0;
  }

  .blog-item {
    border-top: 1px solid #ededed;
    padding: 18.5px;

    &--title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 15px;
      }
    }

    &--title {
      font-size: 20px;
      font-weight: 600;
      line-height: 1.4;
      text-align: left;
      color: #333333;
      // margin-bottom: 15px;

      @media (min-width: 769px) {
        // max-width: 540px;
      }
    }

    &--blogger-info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-left: 25px;

      .name {
        margin-right: 15px;
        font-size: 16px;
        line-height: 1.38;
        color: #656565;
      }

      .image {
        width: 41px;
        height: 41px;
        @include border-radius(100%);

        img {
          margin: 0 !important;
          width: 41px;
          height: 41px;
          @include border-radius(100%);
        }
        div {
          width: 41px;
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include border-radius(100%);
          color: #fff;
        }
      }
    }

    &--content-text {
      word-break: break-all;
    }

    &--content-wrapper {
      text-align: justify;
      margin-top: 10px;

      a {
        color: #33bdd8 !important;
        text-decoration: underline !important;
      }

      .img-wrapper {
        margin: 0 0 20px 0 !important;
        background: white;

        img.big,
        img.small,
        video.big,
        video.small {
          &:hover {
            cursor: pointer;
          }
        }

        img.big,
        video.big {
          width: 100%;
          margin: 0 !important;
          height: 300px !important;
        }

        .video-wrapper {
          position: relative;

          &.big {
            a {
              &:before {
                height: calc(100% - 6.4px);
              }
              &:after {
                height: 62px;
                width: 62px;
                top: calc(50% - 30px);
                left: calc(50% - 30px);
              }
            }
          }

          &.small {
            a {
              &:before {
                height: calc(100% - 4.8px);
              }
              &:after {
                height: 48px;
                width: 48px;
                top: calc(50% - 24px);
                left: calc(50% - 24px);
                background-size: contain;
              }
            }
          }

          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              position: absolute;
              margin: 0 !important;
            }

            &:before {
              content: "";
              position: absolute;
              top: 0;
              width: 100%;
              background-color: #333333;
              opacity: 0.5;
            }

            &:after {
              content: "";
              position: absolute;
              background-image: url("../img/play-btn-img.png");
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        video {
          object-fit: cover;
        }
        video[poster] {
          height: 100%;
          width: 100%;
        }

        .small-images {
          display: flex;
          justify-content: right;
          margin-top: 15px;

          video {
            height: 90px;
          }

          img {
            height: 90px;
            margin: 0 !important;
          }

          & div.item-wrap {
            margin-right: 30px !important;

            &:first-of-type {
              margin-right: 0 !important;
            }

            &:last-of-type {
              margin-right: 0 !important;
            }
          }
        }

        @media (min-width: 991px) {
          min-width: 520px;
          max-width: 520px;
          height: 100%;
          float: right;
          margin: 0 0 20px 30px !important;
        }
      }

      p {
        font-size: 16px;
        color: #656565;
        line-height: 1.4;
      }

      @media (max-width: 992px) {
        flex-direction: column;
      }
    }

    &:last-of-type {
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
    }
  }

  .content-wrapper {
    margin-top: 30px;
    margin-bottom: 60px;

    @media (min-width: 1025px) {
      padding: 0 70px;
    }
  }
}
// blog page detail
.blog-page-detail {
  height: calc(100vh - 150px);
  overflow-y: scroll;
  margin-top: 74px;

  &.no-blog-data {
    // data yoksa
    height: calc(100vh - 150px);
    padding: 30.5px 85px 96px 85px;
  }

  .no-blog-data {
    // data yoksa
    height: calc(100vh - 240px);
    padding: 50px 0 0 0;
  }

  .blog-item {
    border-top: 1px solid #ededed;
    padding: 30.5px;
    margin-top: 10px;

    &--title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 15px;
      }
    }

    &--title {
      font-size: 34px;
      font-weight: 800;
      line-height: 1.4;
      text-align: left;
      color: #333333;
      margin-bottom: 15px;

      @media (min-width: 769px) {
        max-width: 540px;
      }
    }

    &--blogger-info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-left: 25px;

      .name {
        margin-right: 15px;
        font-size: 16px;
        line-height: 1.38;
        color: #656565;
      }

      .image {
        width: 41px;
        height: 41px;
        @include border-radius(100%);

        img {
          margin: 0 !important;
          width: 41px;
          height: 41px;
          @include border-radius(100%);
        }
        div {
          width: 41px;
          height: 41px;
          display: flex;
          align-items: center;
          justify-content: center;
          @include border-radius(100%);
          color: #fff;
        }
      }
    }

    &--content-text {
      word-break: break-all;
    }

    &--content-wrapper {
      text-align: justify;

      a {
        color: #33bdd8 !important;
        text-decoration: underline !important;
      }

      .img-wrapper {
        margin: 0 0 20px 0 !important;
        background: white;

        img.big,
        img.small,
        video.big,
        video.small {
          &:hover {
            cursor: pointer;
          }
        }

        img.big,
        video.big {
          width: 100%;
          margin: 0 !important;
          height: 300px !important;
        }

        .video-wrapper {
          position: relative;

          &.big {
            a {
              &:before {
                height: calc(100% - 6.4px);
              }
              &:after {
                height: 62px;
                width: 62px;
                top: calc(50% - 30px);
                left: calc(50% - 30px);
              }
            }
          }

          &.small {
            a {
              &:before {
                height: calc(100% - 4.8px);
              }
              &:after {
                height: 48px;
                width: 48px;
                top: calc(50% - 24px);
                left: calc(50% - 24px);
                background-size: contain;
              }
            }
          }

          a {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              position: absolute;
              margin: 0 !important;
            }

            &:before {
              content: "";
              position: absolute;
              top: 0;
              width: 100%;
              background-color: #333333;
              opacity: 0.5;
            }

            &:after {
              content: "";
              position: absolute;
              background-image: url("../img/play-btn-img.png");
            }

            &:hover {
              cursor: pointer;
            }
          }
        }

        video {
          object-fit: cover;
        }
        video[poster] {
          height: 100%;
          width: 100%;
        }

        .small-images {
          display: flex;
          justify-content: left;
          margin-top: 15px;

          video {
            height: 90px;
          }

          img {
            height: 90px;
            margin: 0 !important;
          }

          & div.item-wrap {
            margin-right: 30px !important;

            &:first-of-type {
              margin-right: 0 !important;
            }

            &:last-of-type {
              margin-right: 0 !important;
            }
          }
        }

        @media (min-width: 991px) {
          min-width: 520px;
          max-width: 520px;
          height: 100%;
          float: right;
          margin: 0 0 20px 30px !important;
        }
      }

      p {
        font-size: 16px;
        color: #656565;
        line-height: 1.4;
      }

      @media (max-width: 992px) {
        flex-direction: column;
      }
    }

    &:last-of-type {
      border-top: 1px solid #ededed;
      border-bottom: 1px solid #ededed;
    }
  }

  .content-wrapper {
    margin-top: 30px;
    margin-bottom: 60px;
    .back-blog-list {
      cursor: pointer;
      color: #33bdd8;
      font-size: 17px;
      font-weight: 600;
      &:hover {
        text-decoration: underline !important;
      }
    }

    @media (min-width: 1025px) {
      padding: 0 70px;
    }
  }
}

// about page
.about-page {
  margin-top: 74px;
  padding: 96px 149px;

  h2 {
    text-align: center;
    color: #333333;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.4;
    margin-bottom: 30px;
  }

  .img-wrapper {
    position: relative;

    img {
      margin: 0 !important;
      width: 100%;
    }
  }

  .content-wrapper {
    margin-top: 30px;
    font-size: 16px;
    color: #656565;
    line-height: 1.4;
    padding: 0 32px;

    .manager-text {
      margin-bottom: 80px;
    }

    p:not(.manager-text) {
      margin-bottom: 25px;
    }

    @media (max-width: 767px) {
      padding: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 96px 0;
  }
}

// kvkk page
.kvkk-page {
  margin-top: 74px;
  padding: 96px 149px;
  height: calc(100vh - 150px);

  .kvkk-back {
    color: #33bdd8;
    font-weight: 500;

    i {
      font-size: 13px;
    }
  }

  &.text-content {
    height: 100%;
    padding: 60px 149px;

    @media (max-width: 767px) {
      padding: 40px 0;
    }
  }

  h2 {
    text-align: center;
    color: #333333;
    font-size: 38px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      font-size: 25px;
    }

    @media (max-width: 320px) {
      font-size: 20px;
    }
  }

  h3 {
    text-align: center;
    color: #333333;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.4;
    margin-bottom: 60px;

    @media (max-width: 768px) {
      font-size: 25px;
    }

    @media (max-width: 320px) {
      font-size: 20px;
    }
  }

  .img-wrapper {
    position: relative;

    img {
      margin: 0 !important;
    }
  }

  img {
    margin: 0 !important;
  }

  .info-title-wrap {
    margin-bottom: 30px;

    a {
      display: block;
      padding: 20px 30px;
      background-color: #33bdd8;
      border: 2px solid #33bdd8;
      color: white;
      font-weight: 500;
      transition: all 0.4s;

      &:hover {
        background-color: transparent;
        color: #33bdd8;
      }
    }

    @media (min-width: 1300px) {
      max-width: 900px;
      margin: 0 auto 30px auto;
    }
  }

  .content-wrapper {
    font-size: 14px;

    .responsive-table {
      overflow-x: auto;
      margin-bottom: 20px;
    }
    table {
      width: 100%;

      &.no-border {
        tbody {
          td {
            border: none;
          }
        }
      }

      th,
      td {
        padding: 6px;
      }

      tbody {
        td {
          border: 1px solid;
        }
      }
      thead {
        th {
          border: 1px solid;
        }
      }
    }

    input[type="checkbox"] {
      position: relative;
      top: 3px;
      left: 5px;
      &:disabled {
        background-color: transparent;
        border-color: black;
      }
    }

    .line-bg {
      height: 25px;
      content: "";
      background: url("../img/line-bg.png");
      background-repeat: repeat-x;
    }

    .empty-square {
      display: inline-block;
      width: 25px;
      height: 25px;
      border: 2px solid;
    }

    a {
      text-decoration: underline;
      color: #33bdd8;
      font-weight: 500;
    }
  }

  @media (max-width: 767px) {
    padding: 35px 0;
  }
}

// features page
.features-page {
  margin-top: 74px;
  padding: 60px;

  h2 {
    margin: 0 auto 24px auto;
    text-align: center;
    color: #333333;
    font-size: 34px;
    line-height: 1.4;
    font-weight: 800;

    @media (min-width: 1024px) {
      max-width: 540px;
    }
  }

  .content-wrapper {
    margin: 0 auto;
    padding: 30px;
    border-radius: 2px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    font-size: 16px;

    table {
      width: 100%;

      thead {
        border-bottom: 1px solid #ededed;
      }

      th {
        line-height: 14px;
        font-weight: 800;
        padding: 5px 0;

        &:nth-of-type(2) {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        img {
          margin: 0 5px 0 !important;
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background-color: #f8f8f8;
          }
        }

        td {
          height: 39px;
          padding-left: 4px;
          color: #656565;
        }

        td + td {
          width: 110px;
          padding-right: 23px;
          text-align: right;
        }

        i {
          width: 24px;
          height: 24px;
          font-size: 20px;
          line-height: 24px;
          color: #34a853;
        }
      }
    }

    @media (min-width: 992px) {
      max-width: 540px;
    }

    @media (max-width: 767px) {
      padding: 30px 10px;
    }
  }

  @media (min-height: 1200px) {
    height: calc(100vh - 150px);
  }

  @media (max-width: 767px) {
    padding: 60px 0;
  }
}

// education page
.education-page {
  .education-banner {
    position: relative;
    background-color: #fff;
    margin-top: 74px;
    padding: 47px 86px;

    .text-container {
      @media (min-width: 1024px) {
        margin-left: 30px;
      }

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    .image-container {
      display: flex;
      height: 100%;
      align-items: flex-end;

      img {
        margin: 0 !important;
        height: 303px;

        @media (max-width: 768px) {
          margin: 0 auto !important;
        }
      }

      @media (min-width: 1024px) {
        justify-content: center;
      }

      @media (max-width: 768px) {
        text-align: center;
        margin-top: 20px;
      }
    }

    h1 {
      font-size: 48px;
      font-weight: 800;
      line-height: 1.4;
      color: #333333;
      margin-bottom: 41px;

      @media (min-width: 1024px) {
        max-width: 510px;
      }
    }

    .down-btn {
      position: absolute;
      right: 163px;
      bottom: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 158px;
      text-align: center;

      span {
        font-size: 15px;
        font-weight: 600;
        line-height: 1.38;
        color: #33bdd8;
      }

      img {
        width: 63px;
        margin-top: 16px;
      }
    }

    .down-btn-orange {
      display: flex;
      align-items: center;
      border-radius: 2px;
      border: solid 2.5px #f5701f;
      transition: all 0.2s;
      height: 45px;
      padding: 14px 30px;
      min-width: 350px;
      max-width: 390px;

      span {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.38;
        color: #f5701f;
      }

      i {
        font-size: 16px;
        line-height: 24px;
        margin-right: 10px;
        color: #f5701f;
        font-weight: 600;
      }

      &:hover {
        background-color: #f5701f;

        span,
        i {
          color: #fff;
        }
      }

      @media (max-width: 768px) {
        margin: 0 auto;
      }
    }

    @media (max-width: 768px) {
      padding: 47px 0;
    }
  }

  .education-infos {
    position: relative;
    padding: 80px 86px 60px 86px;
    background: #34a853 url(../img/education-page-trainer-bg.png) center center
      no-repeat;
    background-size: cover;

    h1 {
      margin-bottom: 32px;
      font-size: 34px;
      font-weight: 800;
      line-height: 1.41;
      color: #fff;
    }

    &-wrap {
      display: flex;
      align-items: flex-start;

      .image-wrap {
        margin-right: 32px;
        img {
          height: 143px;
          margin: 0 0 32px 0 !important;

          @media (max-width: 992px) {
            margin: 0 20px 32px 0 !important;
          }
        }

        h6 {
          color: #fff;
          font-size: 20px;
          line-height: 1.42;
          font-weight: 800;
          margin-bottom: 0;
        }

        p {
          font-size: 16px;
          color: #fff;
          margin-bottom: 0;
        }

        .logos {
          display: flex;
          margin-top: 20px;

          img {
            height: 65px;
            border-radius: 3px;

            &:first-of-type {
              margin-right: 10px !important;
            }
          }
        }

        @media (max-width: 992px) and (min-width: 768px) {
          display: flex;
          flex-direction: row;
        }

        @media (max-width: 767px) {
          display: flex;
          flex-direction: column;
        }
      }

      .text-wrap {
        color: #fff;
        column-count: 3;
        column-gap: 32px;
        font-size: 16px;
        line-height: 1.38;
        text-align: justify;

        img {
          height: 57px;
          float: left;
          margin: 2px 6px 0 0 !important;
        }

        @media (max-width: 1199px) and (min-width: 992px) {
          column-count: 2;
        }

        @media (max-width: 991px) {
          column-count: 1;
        }
      }

      @media (max-width: 992px) {
        flex-direction: column;
      }
    }

    // education rules content custom
    .text-wrapper {
      color: #fff;
      font-size: 16px;
      line-height: 1.4;
      text-align: justify;

      .img-wrapper {
        text-align: center;
        margin-left: 32px;
        margin-bottom: 25px;
        float: right;

        img {
          margin: 0 !important;
          // width: 100%;
          width: 538px;
          height: 303px;
        }

        video {
          margin: 0 !important;
          height: 303px;
          object-fit: cover;

          &:focus {
            outline: none !important;
          }

          @media (max-width: 992px) {
            width: 100%;
          }
        }

        @media (max-width: 992px) {
          margin-left: 0;
          float: none;
        }
      }
    }

    .down-btn {
      position: absolute;
      right: 163px;
      bottom: 32px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 158px;
      text-align: center;

      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        color: #fff;
      }

      img {
        width: 63px;
        margin-top: 16px;
      }
    }

    @media (max-width: 768px) {
      padding: 96px 0;
    }
  }

  .education-rules {
    background-color: #fff;
    padding: 60px 86px;

    .education-rules-wrapper {
      padding-bottom: 60px;
    }

    h1 {
      line-height: 1.4;
      font-size: 34px;
      font-weight: 800;
      color: #333333;
      margin-bottom: 15px;
    }

    .text-wrapper {
      color: #656565;
      font-size: 16px;
      line-height: 1.4;
      text-align: justify;

      .img-wrapper {
        text-align: center;
        margin-left: 32px;
        margin-bottom: 25px;
        float: right;

        img {
          margin: 0 !important;
          width: 100%;
        }

        video {
          margin: 0 !important;
          height: 303px;
          object-fit: cover;

          &:focus {
            outline: none !important;
          }

          @media (max-width: 992px) {
            width: 100%;
          }
        }

        @media (max-width: 992px) {
          margin-left: 0;
          float: none;
        }
      }
    }

    @media (max-width: 768px) {
      padding: 96px 0;
    }
  }

  .udemy-link-wrapper {
    padding: 0 86px 96px 86px;

    .text-wrapper {
      margin-bottom: 25px;
      font-weight: 500;

      a {
        text-decoration: underline;
        color: #33bdd8;
        font-weight: bold;
      }
    }

    img {
      height: 220px;
      margin: 0 !important;
      border-radius: 4px;

      @media (max-width: 768px) {
        width: 75%;
        height: auto;
      }
    }

    @media (max-width: 768px) {
      padding: 0 0 96px 0;
    }
  }
}

.basic-3,
.sub-features3 {
  padding-top: 6.875rem;
  padding-bottom: 0.125rem;

  h2 {
    margin-bottom: 3rem;
    text-align: center;
  }

  p {
    width: 55%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    color: #626262;
    font: 400 0.875rem/1.375rem "Raleway", sans-serif;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .image-container {
    max-width: 53.125rem;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 2.25rem;

    img {
      border-radius: 0.5rem;
    }
  }

  .video-wrapper {
    position: relative;
    text-align: center;
  }

  .video-play-button {
    position: absolute;
    z-index: 10;
    top: 37%;
    left: 53%;
    display: block;
    box-sizing: content-box;
    padding: -1.875rem 1.25rem 1.125rem 1.75rem;
    border-radius: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);

    @include phone {
      top: 36%;
      left: 47%;
    }
  }

  .video-play-button:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0%;
    left: 75%;
    display: block;
    width: 4.375rem;
    height: 4.375rem;
    border-radius: 50%;
    background: #00bfd8;
    transition: all 200ms;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  .video-play-button:before {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0%;
    left: 75%;
    display: block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    background: #00bfd8;
    animation: pulse-border 1500ms ease-out infinite;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }

  @keyframes pulse-border {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.1;
    }

    100% {
      opacity: 1;
    }
  }

  span {
    position: relative;
    display: block;
    z-index: 3;
    top: -0.925rem;
    left: -0.5rem;
    width: 0;
    height: 0;
    border-left: 1.625rem solid #fff;
    border-top: 1rem solid transparent;
    border-bottom: 1rem solid transparent;
  }
}

.cards-2,
.pricing {
  padding-top: 100px;
  padding-bottom: 0;
  text-align: center;
  color: #626262;

  h2 {
    margin-bottom: 32px;
    font-size: 34px;
    font-weight: 800;
    line-height: 1.4;
    text-align: center;
    color: #333;
    font-family: "Montserrat", sans-serif;
  }

  .p-heading,
  .p-wrapper {
    width: 55%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 1.875rem;
  }

  .p-large {
    font: 400 1rem/1.5rem "Raleway", sans-serif;
  }

  .card {
    border: none;
    @include box-shadow(0 11px 20px 0 rgba(0, 0, 0, 0.1));
    vertical-align: top;
    color: #626262;

    .label {
      position: absolute;
      top: -10px;
      right: 0;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .best-value {
        width: 20px;
        height: 20px;
        font-size: 7px;
        line-height: 1.43;
        margin-top: 14px;
        color: #fff;
      }
    }

    .list-unstyled,
    .landingPage-ulList {
      margin-top: 1.875rem;
      margin-bottom: 1.625rem;
      text-align: left;
      min-height: 306px;

      i {
        color: #34a853;
        padding-left: 8px;
        font-size: 24px;
      }

      .media {
        justify-content: space-between;
        align-items: center;
        padding: 10px 4px;

        &:nth-child(odd) {
          background-color: #f8f8f8;
        }
      }

      .media-body,
      .section-description {
        line-height: 1.4;
        font-size: 16px;
        color: #656565;
        max-width: 200px;
      }
    }
    .enterprice {
      padding-right: 4px;
      margin-right: -8px;
      max-height: 306px;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #e3e3e3;
        border-radius: 3px;
      }
    }
    .btn-solid-reg,
    .discover-button {
      display: block;
      height: 45px;
      margin: 0 auto;
      max-width: 120px;
      font-size: 14px;
      line-height: 1.43;
      color: #f5701f !important;
      padding: 10px 0;
      font-weight: 600;
      border: 3.1px solid #f5701f;
      @include border-radius(2px);
      color: #fff;
      text-decoration: none;
      transition: all 0.2s;

      &:hover {
        background-color: #f5701f;
        color: #fff !important;
      }
    }
  }

  .card-body {
    padding: 16px;

    &.enterprise-subscription {
      .card-title {
        line-height: 1.6;

        @include tablet {
          height: 101px;
        }
      }

      .price {
        padding-bottom: 26px;
        min-height: 154px;
      }

      .frequency {
        margin-top: 0;
        font-size: 29px;
        line-height: 1.7;

        a {
          color: #33bdd8;
          line-height: 1.4;
        }
      }

      a {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .card-title {
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 800;
    line-height: 1.98;
    color: #333;

    img {
      margin: 0 0 0 8px !important;
    }
  }

  .card-subtitle {
    color: #333;
    font-size: 14px;
    line-height: 1.4;
    margin-bottom: 16px;
  }

  .cell-divide-hr {
    height: 1px;
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    background-color: #d1d1d1;
  }

  .price {
    border-top: 1px solid #d1d1d1;
    border-bottom: 1px solid #d1d1d1;
    min-height: 142px;
    display: flex;
    flex-direction: column;
    padding-top: 26px;
    padding-bottom: 21px;
    font-size: 48px;
    font-weight: 800;
    color: #33bdd8;
    position: relative;

    & > div.price-wrap {
      line-height: 0.99;
    }
  }

  .currency {
    line-height: 1;
  }

  .many-value {
    line-height: 1;

    &.started-many {
      font-size: 34px;
      line-height: 1.4;
    }
  }

  .frequency {
    margin-top: 8px;
    font-size: 24px;
    line-height: 1.98;
    text-align: center;
    position: relative;
  }

  .card-monthly-info {
    font-size: 11px;
    position: absolute;
    bottom: -16px;
    left: calc(50% - 32px);
    color: black;
    font-weight: normal;
  }

  .kdv-info {
    font-size: 11px;
    position: absolute;
    top: 9px;
    left: calc(50% - 32px);
    color: black;
    font-weight: normal;
  }
}
.pricing {
  @include phone {
    padding: 0px;
    h2 {
      font-size: 24px;
      margin-bottom: 19px;
    }
    .price-card-row {
      flex-wrap: inherit;
      overflow-x: auto;
      padding-top: 15px;
      padding-bottom: 35px;
      .price-card {
        max-width: 285px;
        .card {
          min-height: 596px;
          box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
          .card-title {
            line-height: 1.4;
            margin-bottom: 8px;
          }
          .card-subtitle {
            font-size: 16px;
            margin-bottom: 8px;
          }
          .price {
            min-height: 122px;
            .many-value.started-many {
              font-size: 20px;
              line-height: 1.68;
              margin-top: 12px;
            }
            .many-value.month-many,
            .many-value.year-many,
            .currency {
              font-size: 34px !important;
              line-height: 1.4 !important;
            }
            .frequency {
              font-size: 20px;
              line-height: 1.68;
              margin-top: 0px;
            }
            .kdv-info {
              display: none;
            }
          }
        }
        .best-card {
          box-shadow: 0 4px 20px 0 rgba(253, 112, 33, 0.2);
          border: solid 3px #fd7021;
          .card-body {
            padding: 13px;
            .price {
              color: #fd7021 !important;
            }
          }
        }
      }
    }
  }
}

.references {
  padding: 90px 0 50px 0;

  &-wrap {
    display: flex;
    justify-content: space-around;

    @media (max-width: 500px) {
      flex-direction: column;
    }
  }

  .title {
    color: #333333;
    font-size: 34px;
    font-weight: 800;
    line-height: 1.4;
    text-align: center;
    margin-bottom: 30px;
  }

  .reference {
    align-self: center;

    img {
      width: 206px;
      margin: 0 !important;
    }

    @media (max-width: 500px) {
      min-height: 140px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
}

.foramind-counts {
  padding: 40px 0;
  background-color: #fff;
  @include phone {
    padding-top: 15px !important;
  }
  @media (max-width: 992px) {
    top: 0;
  }

  &-wrap {
    display: flex;
    justify-content: space-around;
    text-align: center;

    & > div {
      min-width: 290px;

      i {
        font-size: 60px;
        color: #33bdd8;
      }
      .title {
        line-height: 1.4;
        margin: 5px 0 12px 0;
        font-size: 20px;
        color: #333;

        @media (max-width: 1024px) and (min-width: 769px) {
          height: 94.4px;
        }
      }
      .line {
        height: 2px;
        width: 100%;
        background-color: #d1d1d1;
        margin-bottom: 15px;
      }
      .count-info {
        font-size: 35px;
        font-weight: 800;
        line-height: 0.99;
        color: #33bdd8;
      }

      @media (max-width: 1024px) and (min-width: 769px) {
        max-width: 250px;
      }
      @media (max-width: 768px) {
        max-width: 400px;
        margin: 12px 0;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
        background-color: #fff;
        min-width: 328px;
        padding: 16px 16px;
        i {
          font-size: 80px;
        }
        .title {
          font-size: 24px;
          line-height: 1.98;
          margin: 0px;
        }
        .line {
          margin-bottom: 12px;
        }
        .count-info {
          font-size: 34px;
          line-height: 1.4;
        }
      }
    }

    @media (max-width: 991px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.form-2,
.contact {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #fff;
  background-size: cover;
  color: #626262;

  h2 {
    margin-bottom: 48px;
    text-align: center;
    color: #333333;
    font-size: 34px;
    font-weight: 800;
  }

  a.landing-page-mail {
    text-decoration: underline;
    color: #33bdd8;
  }

  .list-unstyled,
  .landingPage-ulList {
    margin-bottom: 2.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    position: relative;
    top: -45px;

    .address {
      display: block;
    }

    .fab {
      margin-right: 0.5rem;
      font-size: 0.875rem;
      color: #00bfd8;
    }

    .fas {
      margin-right: 0.5rem;
      font-size: 1rem;
      color: #00bfd8;
    }

    a.turquoise {
      color: #00bfd8;
      padding-right: 68px;
    }
  }

  #contactForm {
    margin-right: auto;
    margin-left: auto;

    .register-checkbox .checkmark {
      left: 27px;
    }

    @include phone {
      margin-top: 14px;
    }
  }

  .form-control-input,
  .form-control-select {
    display: block;
    width: 100%;
    height: 45px;
    padding: 13px 30px;
    border: none;
    font-size: 14px;
    border-radius: 2px;
    background-color: #f2f2f2;
    color: #656565;
    transition: all 0.2s;
    -webkit-appearance: none;
    @include phone {
      padding: 13px 16px;
    }
  }

  .label-control {
    position: absolute;
    top: 0.87rem;
    left: 1.375rem;
    color: #626262;
    opacity: 1;
    font: 400 0.875rem/1.375rem "Raleway", sans-serif;
    cursor: text;
    transition: all 0.2s ease;
  }

  .form-group {
    position: relative;
    margin-bottom: 10px;
  }

  .checkbox-form-2 {
    font: 400 0.75rem/1.25rem "Raleway", sans-serif;
  }

  .form-control-submit-button {
    display: inline-block;
    width: 100%;
    height: 3.125rem;
    background-color: #fff;
    color: #f5701f;
    font-size: 14px !important;
    font-weight: 600 !important;
    border-radius: 2px;
    border: solid 3px #f5701f;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background-color: #f5701f;
      color: #fff;
    }
  }

  .form-control-textarea {
    display: block;
    width: 100%;
    height: 7rem;
    font-size: 14px;
    padding: 13px 30px;
    border-radius: 2px;
    background-color: #f2f2f2;
    border: none;
    color: #656565;
    resize: none;
    transition: all 0.2s;

    &:focus {
      outline: none;
    }
    @include phone {
      padding: 13px 16px;
    }
  }
}
.contact {
  @include phone {
    padding-top: 15px;
    padding-bottom: 48px;
    h2 {
      font-size: 24px;
      line-height: 1.4;
      margin-bottom: 0px;
    }
  }
}
.agr-row {
  @include phone {
    margin-top: 8px;
    .checkmark {
      top: -7px;
      left: 16px !important;
    }
  }
}
.hotjar-agreement-page {
  padding: 110px 0 70px 0;

  .title {
    h2 {
      font-size: 23px;
      font-weight: bold;
    }
  }

  .content-wrapper {
    ul li,
    p {
      font-size: 14px;
    }

    mark {
      background-color: yellow;
    }

    dt,
    dd {
      float: left;
      font-size: 14px;
    }
    dt {
      clear: both;
      width: 12%;

      @include phone {
        width: 100%;
      }
    }

    input[type="checkbox"] {
      position: relative;
      top: 3px;
      left: 5px;
      &:disabled {
        background-color: transparent;
        border-color: black;
      }
    }
  }
}

.dashboard-page {
  margin-left: 260px;
  // height: 100% !important;
  height: calc(100vh - 56px) !important;

  .form-wrapper {
    max-width: 680px;
    margin: 0 auto;
  }

  .overlay {
    z-index: 100 !important;
  }
}

.profile-page {
  padding: 30px 0;

  .profile-form {
    max-width: 680px;
    margin: 0 auto;
  }
}

.signin-page {
  position: relative;
  float: left;
  margin-left: 2.5pc;
  left: -24px;
  margin-right: -1.2pc;
  transition: all 0.15s ease-in-out;
  color: #f5701f;
  border: 0;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 600;
  padding: 10px 25px;
  min-width: 115px;
  min-height: 40px;
  cursor: pointer;
  border-radius: 2px;
  border: solid 2.5px #f5701f;
  background: none !important;
  text-align: center;

  .login-wrap {
    text-align: left;
    line-height: 1;
    position: relative;
    top: -1px;

    span {
      font-weight: normal;
      font-size: 11px;
      line-height: 1;
    }

    div.user-name {
      font-size: 12px;
      line-height: 1.1;
      margin-top: 2px;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-left: 5pc;
    left: -80px;
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    padding: 10px 5px;
  }

  .signin-text {
    color: #f5701f !important;
  }

  @include phone {
    position: relative;
    padding: 0;
    width: 156px;
    margin: 0px;
    float: left;
    left: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 79px;
    min-height: 30px;
    top: 2px;
    background-color: #f5701f !important;
    color: #ffffff;
    margin-left: 16px;
    height: 45px;
    border-radius: 5px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
  }

  &:hover {
    background: #f5701f !important;
    color: #fff !important;
  }
}
.register-button {
  display: none;
  @include phone {
    background-color: transparent;
    border: solid 2.5px #f5701f;
    color: #f5701f;
    display: block;
    position: relative;
    padding: 0;
    width: 156px;
    margin: 0px;
    float: left;
    left: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 79px;
    min-height: 30px;
    top: 2px;
    height: 45px;
    border-radius: 5px;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
  }
}
// Header language select custom css
.language-page {
  .language-select.select {
    width: 60px !important;

    @media screen and (min-width: 768px) and (max-width: 991px) {
      width: 100px !important;
    }
  }
  .css-1hwfws3 {
    text-align: right;
    padding: 0;
  }
  .css-1xlujwa-control,
  .css-1k03ng9-control {
    border-radius: 80px;
    background-color: #fff;
    border-color: transparent !important;
  }
  .css-b8ldur-Input {
    width: 100%;
    height: 21px;
    & > div {
      display: none !important;
    }
  }
  .css-zk88xw-singleValue {
    color: #656565;
    width: 100%;
    font-size: 14px;
    @include phone {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 500;
      line-height: 2.1;
      color: #333;
    }
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    color: #333333;
    padding: 0 10px 0 0;

    &:hover {
      color: #333333;
    }
  }

  .css-1wkj2sz-menu {
    margin-top: 5px;
    margin-bottom: 0;
  }

  .css-4ljt47-MenuList {
    padding-top: 0;
    padding-bottom: 0;
  }

  @include phone {
    position: fixed;
    margin: 8px 0px;
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    padding-right: 0;
    padding-left: 0;
    right: 0px;
    top: -6px;
  }
}

.active {
  color: #00bfd8 !important;
  opacity: 1;

  a {
    color: #00bfd8 !important;
    opacity: 1;
  }
}

.navbar-activate {
  background-color: #fff !important;
  // box-shadow: 0 0.0625rem 0.375rem 0 rgba(0, 0, 0, 0.1);
}

.text-color-activate {
  color: #393939 !important;
}

.home-color-set {
  a {
    color: #fff !important;
    opacity: 1;
  }

  @include phone {
    a {
      color: #00bfd8 !important;
      opacity: 1;
    }
  }
}
.nav-item {
  .nav-link {
    @include phone {
      font-family: Montserrat !important;
      font-size: 20px !important;
      font-weight: 500 !important;
      line-height: 1.68 !important;
    }
  }
}
.nav-link {
  display: block;
  color: unset;

  @include phone {
    padding: 0.625rem 0.75rem 0.625rem 0.75rem;
    color: #393939 !important;
    text-decoration: none;
    transition: all 0.2s ease;
    font: 600 0.7rem "Monserrat", sans-serif;
  }
}

// subscription page
.subscription-detail {
  font-size: 16px;

  .file-box {
    padding: 0 15px 30px 15px !important;
    max-height: unset !important;
    overflow-y: hidden !important;
  }

  .val {
    color: #33bdd8;
    font-weight: bold;
    display: inline-block;
    padding-left: 8px;
  }

  .line {
    height: 1px;
    background-color: #d1d1d1;
    margin: 16px 0;
  }

  a {
    color: #33bdd8 !important;
    text-decoration: underline !important;
    font-weight: bold;

    &.back-to-template-list {
      text-decoration: none !important;
      font-weight: normal;
      width: 155px;

      &:hover {
        text-decoration: underline !important;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  ul {
    margin-top: 8px !important;
    margin-bottom: 0 !important;
    li {
      padding: 4px 0;
    }
  }

  .card {
    padding: 16px;
    margin: 8px 0;
    border: none;
    border-radius: 2px;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12);

    .card-body {
      padding: 0;
    }

    .card-title {
      margin-bottom: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      font-weight: 800;
      line-height: 1;
      color: #333;

      img {
        margin: 0 0 0 8px !important;
      }
    }

    .price {
      border-top: 1px solid #d1d1d1;
      border-bottom: 1px solid #d1d1d1;
      display: flex;
      flex-direction: column;
      padding: 16px 0;
      font-size: 34px;
      font-weight: 800;
      color: #33bdd8;

      &-wrap {
        font-weight: bold;
      }

      .frequency {
        font-size: 24px;
        position: relative;
      }

      .card-monthly-info {
        font-size: 11px;
        color: black;
        font-weight: normal;
      }

      .kdv-info {
        font-size: 11px;
        color: black;
        font-weight: normal;
      }
    }

    .list-unstyled,
    .landingPage-ulList {
      margin-top: 1.875rem;
      margin-bottom: 1.625rem;
      text-align: left;

      i {
        color: #34a853;
        padding-left: 8px;
        font-size: 24px;
      }

      .media {
        justify-content: space-between;
        align-items: center;
        padding: 10px 4px;

        &:nth-child(odd) {
          background-color: #f8f8f8;
        }
      }

      .media-body,
      .section-description {
        line-height: 1.4;
        font-size: 16px;
        color: #656565;
        max-width: 200px;
      }
    }
  }

  @include desktop {
    max-width: 1200px !important;
    min-height: 530px !important;
    margin: 40px 20px !important;
  }
}

// payment page
.payment-page {
  padding: 0 30px;
}

.css-1o0i5g4-Close-Close {
  line-height: 1;
}
.css-1e2nqws-Holder {
  height: 100% !important;
}
.css-12yd9r-Wrapper {
  padding: 0 !important;
}

.buy-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin: 0;
  color: #3e3e3e;
  font-size: 24px;
  font-weight: bold;
  line-height: 1.2;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 16px;

  i {
    color: #33bdd8;
    margin-right: 8px;
  }
}

.buy-form {
  background: white !important;
  max-width: 1000px;
  margin: 30px auto;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  @include border-radius(4px);
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;

  .cards-wrap {
    display: flex;
    justify-content: space-between;
    align-items: end;
    flex-direction: column;

    @include phone {
      flex-direction: column;
    }
  }

  .card {
    @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));
    @include border-radius(22px);
    border: solid 2px transparent;
  }

  .title-wrap {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-left: 8px;
    }
  }

  .card-title {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    color: #333;
    padding: 16px 0;
    margin-bottom: 0;
  }

  .price {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 13px 0 16px 0;
    color: #33bdd8;
    font-weight: bold;
    border-top: solid 1px #d1d1d1;
    border-bottom: solid 1px #d1d1d1;
    position: relative;

    .card-monthly-info {
      font-size: 11px;
      position: absolute;
      bottom: 4px;
      left: calc(50% - 32px);
      color: black;
      font-weight: normal;
    }

    .kdv-info {
      font-size: 11px;
      position: absolute;
      top: 4px;
      left: calc(50% - 32px);
      color: black;
      font-weight: normal;
    }

    > div {
      &:first-of-type {
        font-size: 34px;
      }
      &:last-of-type {
        font-size: 24px;
        position: relative;
      }
    }
  }

  .user-info {
    padding-right: 20px;
    border-right: 1px solid #d1d1d1;

    @include phone {
      border-right: none;
      margin-bottom: 20px;
    }
  }

  .item-text{
    p {
     font-size: 14px;
     margin-top: 5%;
    }
  }

  .scrollable{
    max-height: 450px; 
    overflow-y: auto;
  }

  .scrollable::-webkit-scrollbar {
    width: 10px;
  }
  
  .scrollable::-webkit-scrollbar-thumb {
    background: rgb(62,193,218);
    background: linear-gradient(0deg, rgba(62,193,218,1) 0%, rgba(184,213,218,1) 100%);
    border-radius: 5px; 
  }
    
 .gpt-payment {
  .month-card {
    width: 100%;
    height: 210px;
  }

  .card-body{
    padding-top: 15px;
  }

  .price > div:last-of-type { 
    font-size: 30px;
  }
   
 }

  .trial-card,
  .month-card,
  .year-card {
    width: 94%;
    height: 190px;
    margin-bottom: 32px;

    &:hover {
      cursor: pointer;
    }

    @include phone {
      width: 100%;
      margin-top: 0rem;
    }
  }

  .card-body {
    padding: 0 8px 16px 8px;
  }

  .activate {
    border-color: #33bdd8 !important;
  }

  form {
    background-color: $color-white;
    padding: 16px 16px 0 16px;
    border-radius: 15px;

    h4 {
      line-height: 3em;
    }

    input {
      width: 100%;
      height: 37px;
      border: 0;
      background-color: $color-concrete;
      font-size: 14px;
      text-indent: 10px;
      line-height: 20px;
      color: #393939 !important;
      border-radius: 130px;
      border: 0;
      padding: 10px;
      @include transition(all 0.15s ease-in-out);

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #393939;
        opacity: 1; /* Firefox */
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #393939;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #393939;
      }
    }

    .form-group {
      margin-bottom: 8px;

      @include phone {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .select {
      height: 37px;
      width: 100%;
      & > div {
        top: 0;
        height: 37px;
        min-height: unset;
        border: none !important;

        &.css-vy708i-menu {
          top: 35px;
          height: 300px;
        }
      }
    }

    button {
      @include transition(all 0.15s ease-in-out);
      color: $color-white;
      background-color: #33bdd8;
      border: 0;
      padding: 8px 30px;
      min-width: 134px;
      min-height: 30px;
      font-size: 12px !important;
      font-weight: bold !important;
      @include border-radius(80px);
      cursor: pointer;

      &:disabled {
        opacity: 0.5;
        &:hover {
          cursor: not-allowed;
        }
      }

      &:hover {
        background-color: #2ea5bd;
      }
    }

    .buy-checkbox {
      margin-left: 8px;
      margin-top: 8px;

      span {
        margin-left: 0;
        left: 0;
        top: 5px;
        height: 15px !important;
        width: 15px !important;
        border-radius: 100%;
        border: solid 2px #2a89f2;
      }
      .container-checkbox {
        font-size: 12px;

        a {
          color: #33bdd8;
          font-weight: bold;
        }
      }
      .container-checkbox input:checked ~ .checkmark {
        background-color: #2a89f2 !important;
      }
      .register-checkbox .checkmark::after {
        left: 3.5px !important;
        top: 0.5px !important;
        width: 4px !important;
        height: 9px !important;
      }
      input {
        width: 0;
      }
    }

    hr.style1 {
      display: block;
      position: relative;
      transition: auto;
      transform: rotate(90deg);
      // width: 25%;
      width: 208px;
      top: -125px;
      border-top-color: #d1d1d1;

      @include phone {
        display: none;
      }
    }
  }

  hr.style2 {
    position: relative;
    transition: auto;
    width: 95%;
    top: -6px;

    @include phone {
      width: 0%;
    }
  }

  .many-show-text {
    display: flex;
    justify-content: left;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 16px;
  }

  .country-select svg {
    position: relative;
    width: 15px;
    height: 15px;
    margin: 0px;
    top: -5px;

    @include phone {
      width: 210px;
    }
  }

  .country-select span {
    position: absolute;
    margin: 5px;
  }

  .layout-select.select,
  .country-select {
    [class*="-control"] {
      @include phone {
        width: 100%;
        svg {
          width: 20px;
        }
      }
      [class*="-placeholder"] {
        top: 40% !important;
        @include phone {
          margin-top: 12px;
          position: relative;
        }
      }
    }
  }

  .layout-select.select,
  .country-select {
    [class*="-control"] {
      [class*="-singleValue"] {
        top: 40% !important;
      }
    }
  }

  .buy-button {
    margin: 10px 0;

    span {
      margin-left: 25px;
      font-weight: 550;
    }
  }

  .card-logo {
    width: 35px;
    height: 25px;
    position: relative;
    float: right;
    right: auto;
  }

  .distant-sales-contract-color {
    color: #33bdd8 !important;
    text-decoration: underline !important;
  }

  .buy-progress-bar svg {
    width: 40%;
    margin-left: 5px;
  }
}

.buy-footer {
  padding: 0 16px 16px 16px;

  img {
    height: 22px;
  }
}

.color-gray {
  color: $color-gray !important;
}

.success-payment {
  width: 100%;
  height: 100%;
}

// map page toolbox
.toolbox {
  .overlay {
    .popup {
      top: 10%;
      height: max-content;
      border-radius: 22px;
      padding: 20px;

      &.change-map-name-modal {
        .mail-input {
          input {
            padding-right: 10px;
          }

          p {
            text-align: center;
            width: 100%;
            color: black;
            font-size: 13px;
          }
        }

        .button-wrap {
          margin-top: 10px;
          justify-content: flex-end;

          .mail-save-btn {
            &.update-btn {
              &[disabled] {
                opacity: 0.5;
              }
            }
            &.cancel-btn {
              background-color: white;
              color: #33bdd8;
              width: auto;
              border: 2px solid #33bdd8;
              margin-left: 5px;
            }
          }
        }
      }

      &.shared-map-name-modal {
        .mail-input {
          input {
            padding-right: 10px;
            margin-right: 15px;
          }
        }
      }

      &-input {
        position: unset !important;
        top: unset;
      }

      .close {
        color: #34c8f5;
        right: 25px;
        font-size: 24px;
        line-height: 1.23;
      }

      .title {
        left: 0;
        margin: 0 30px 15px 0;
        width: calc(100% - 30px);
        display: flex;
        align-items: center;

        .icon-wrap {
          width: 44px;
          height: 44px;
        }

        .circle-icon {
          color: #fcd04d;
          width: 44px;
          height: 44px;
          font-size: 44px;
          position: absolute;
          top: -4px;
          left: -2px;
        }

        .sitemap-icon {
          color: white;
          font-size: 20px;
          width: auto;

          &:before {
            position: absolute;
            top: 9px;
            left: 11px;
          }
        }

        .text {
          margin-left: 10px;
          font-size: 22px;
          line-height: 1;
          font-weight: bold;
          color: #33bdd8;
        }
      }

      ul {
        .buttons {
          margin: 0;
          border: none;
        }
      }

      .input-select-wrapper {
        flex: 1;
        margin-right: 15px;
        position: relative;

        select {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 12px;
          font-weight: bold;
          background: transparent;
          outline: none !important;
          box-shadow: inset 0 0 0 1px #f5f5f5 !important;
          border-width: 0;
          -o-border-width: 0;
          -webkit-border-width: 0;
          -moz-border-width: 0;
          -webkit-border-width: 0;
          -khtml-border-width: 0;
          border: none;
          -o-border: none;
          -webkit-border: none;
          -moz-border: none;
          -webkit-border: none;
          -khtml-border: none;
          color: #33bdd8;
          text-align-last: right;
        }
      }

      .mail-input,
      .message-area {
        input,
        textarea {
          border: none;
          margin: 0;
          font-size: 12px;
          color: #a3a9ad;
          background-color: #f5f5f5;
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        }
      }

      .mail-input {
        height: 37px;
        align-items: center;

        input {
          display: block !important;
          padding: 6px 110px 6px 15px;
          width: 100% !important;
          border-radius: 68px;
          height: 37px;
          color: #393939;
          font-size: 14px;
        }
      }

      .message-area {
        textarea {
          border-radius: 8px;
          height: 74px;

          &:focus {
            outline: none;
          }
        }
      }

      .mail-save-btn {
        height: 30px;
        width: 80px;
        border-radius: 68px;
        background-color: #33bdd8;
        color: white;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
      }

      .info-box {
        margin: 15px 0;
        color: #393939;
        font-size: 12px;
        line-height: 15px;
        align-items: center;

        i {
          color: #2a89f2;
          font-size: 15px;
          margin-right: 5px;
        }
      }

      .share-selected-user {
        &-wrapper {
          width: 100%;
          max-height: 180px;
          overflow-y: scroll;
          overflow-x: hidden;
          margin: 0 0 30px 0;
          padding-right: 10px;

          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 3px;
            background-color: #ebebeb;
          }
        }

        &-list {
          display: flex;
          flex-direction: column;

          .no-shared-user {
            font-size: 14px;
          }

          select {
            margin-right: 15px;
            font-size: 13px;
            font-weight: bold;
            background: transparent;
            border: none;
            color: #33bdd8;
          }
        }

        &-permission {
          color: #33bdd8;
          font-size: 12px;
          font-weight: bold;
          line-height: 15px;
          margin-right: 15px;
        }

        &-remove {
          font-size: 12px;
          cursor: pointer;
        }

        &-item {
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #393939;
          margin-bottom: 20px;

          &:last-of-type {
            margin-bottom: 0;
          }

          .circle {
            width: 17px;
            height: 17px;
            background-color: #a3a9ad;
            border-radius: 100%;
            display: inline-block;
            position: relative;
            margin-right: 5px;
            top: 3px;

            i {
              font-size: 9px;
              color: white;
              position: absolute;
              top: 4.5px;
              right: 5px;
            }
          }
        }

        &-mail {
          flex: 1;
          text-align: left;
        }
      }

      .share-send-button {
        margin: 30px 0 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .share-map-name {
          min-width: 149px;
          height: 30px;
          color: #33bdd8;
          font-size: 14px;
          line-height: 17px;
          border-radius: 15px;
          border: solid 1px #33bdd8;
          padding: 5px 7px;
        }

        button {
          font-weight: bold !important;
        }
      }

      &.share-modal {
        ul .buttons {
          display: none;
          &.active {
            display: block !important;
          }
        }

        .share-send-button {
          .right-botton-wrap {
            align-items: center;
            display: flex;
          }
          button {
            font-family: "Monserrat", sans-serif !important;
          }
        }

        @media (max-width: 1040px) and (min-width: 992px) {
          width: 70%;
        }

        @include phone {
          width: 100%;
        }
      }

      // map page save as template modal
      &.save-as-template-modal {
        .select-shared {
          max-height: 400px;
          overflow-y: scroll;
          padding-right: 10px;
          &::-webkit-scrollbar {
            width: 6px;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #e3e3e3;
            border-radius: 3px;
          }
        }
        .input-select-wrapper {
          flex: 1 1;
          position: relative;
          border: none;
          margin: 0;
          font-size: 12px;
          color: #a3a9ad;
          background-color: #f5f5f5;
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
          border-radius: 68px;
        }

        .map-info-input {
          .input-select-wrapper {
            background: transparent;
            height: 37px;
          }

          .current-map-name {
            position: absolute;
            left: 8px;
            top: 5px;
            font-size: 14px;
            display: flex;
            align-items: center;
            border-radius: 14px;
            padding: 5px 25px 5px 0;
            color: #393939;
            font-style: normal;
            line-height: 1;
            min-width: 225px;
          }
        }

        .template-name-input {
          input {
            display: block !important;
            padding: 6px 15px 6px 15px;
            width: 100% !important;
            border-radius: 68px;
            height: 37px;
            color: #393939;
            font-size: 14px;
            border: none;
            background-color: #f5f5f5;
            font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
          }

          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #a3a9ad;
            opacity: 1; /* Firefox */
            font-style: italic;
          }
          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #a3a9ad;
            font-style: italic;
          }
          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #a3a9ad;
            font-style: italic;
          }
        }

        .category-input {
          margin-bottom: 8px;
          .input-select-wrapper {
            height: 37px;

            .selected-file-name {
              position: absolute;
              left: 15px;
              top: 8px;
              font-size: 14px;
              color: #a3a9ad;
              font-style: italic;

              &.file-name-exist {
                display: flex;
                align-items: center;
                border-radius: 14px;
                border: solid 1px #a3a9ad;
                background-color: white;
                padding: 4px 25px 4px 10px;
                color: #393939;
                font-style: normal;
                line-height: 1;
                top: 5px;
                left: 9px;
                max-width: 70%;

                a {
                  position: absolute;
                  right: 10px;
                  margin-left: 10px;
                  font-weight: bold;
                  color: #33bdd8;
                }
              }
            }
          }

          .select-category-btn {
            position: absolute;
            right: 10px;
            top: 10px;
            font-size: 12px;
            font-weight: bold;
            background: transparent;
            border: none;
            color: #33bdd8;

            &:hover {
              cursor: pointer;
            }

            i {
              font-size: 9px;
              margin: 0 5px 10px;
            }
          }

          .category-list {
            display: none;
            position: absolute;
            top: 40px;
            width: 100%;
            padding: 6px 10px;
            border-radius: 5px;
            z-index: 6;
            background-color: white;
            @include box-shadow(0 0 5px rgba(0, 0, 0, 0.2));

            &.show {
              display: block;
            }

            &--search {
              input {
                width: 100%;
                border-radius: 7px;
                margin-top: 8px;
                margin-bottom: 20px;
                padding: 8px 15px;
              }
            }

            &--items {
              min-height: 50px;
              max-height: 320px;
              overflow-y: scroll;
              padding-right: 10px;

              &::-webkit-scrollbar {
                width: 8px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #e3e3e3;
                border-radius: 3px;
              }
            }

            &--item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 14px;
              line-height: 25px;
              color: black;

              b {
                font-size: 11px;
                font-style: italic;
              }

              &:hover {
                cursor: pointer;
                color: #a3a9ad;
              }
            }
          }
        }

        .image-input {
          height: 37px;
          align-items: center;
          justify-content: unset;

          input.file-input-custom {
            opacity: 0;
            padding: 0 !important;
            display: inline !important;
            margin: 0 !important;
            &.width-0 {
              width: 0 !important;
            }
          }

          select {
            text-indent: 25px;
            text-overflow: "";

            &:hover {
              cursor: pointer;
            }
          }

          .selected-file-name {
            position: absolute;
            left: 15px;
            top: 8px;
            font-size: 14px;
            color: #a3a9ad;
            font-style: italic;

            &.file-name-exist {
              display: flex;
              align-items: center;
              border-radius: 14px;
              border: solid 1px #a3a9ad;
              background-color: white;
              padding: 4px 25px 4px 10px;
              color: #393939;
              font-style: normal;
              line-height: 1;
              top: 5px;
              left: 9px;
              max-width: 70%;

              a {
                position: absolute;
                right: 10px;
                margin-left: 10px;
                font-weight: bold;
                color: #33bdd8;
              }
            }
          }
        }

        .image-preview {
          justify-content: space-between;
          position: relative !important;

          .image-preview--title {
            font-size: 13px;
            font-weight: bold;
            margin-bottom: 10px;
          }

          img.preview {
            @include box-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
          }
        }

        .share-send-button {
          justify-content: flex-end;
        }
      }
    }
  }
}

// category page add/update modal custom modal height
.category-tampleta-grid {
  .overlay {
    .popup .select-shared {
      max-height: 400px;
      overflow-y: scroll;
      padding-right: 10px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #e3e3e3;
        border-radius: 3px;
      }
    }
  }
}

// category page add modal
.dashboard-page {
  .overlay {
    .popup {
      top: 10%;
      height: max-content;
      border-radius: 22px;
      padding: 20px;

      &.shared-map-name-modal {
        .mail-input {
          input {
            padding-right: 10px;
            margin-right: 15px;
          }
        }
      }

      &-input {
        position: unset !important;
        top: unset;
      }

      .close {
        color: #34c8f5;
        right: 25px;
        font-size: 24px;
        line-height: 1.23;
        opacity: 1;
      }

      .title {
        left: 0;
        margin: 0 30px 15px 0;
        width: calc(100% - 30px);
        display: flex;
        align-items: center;

        .icon-wrap {
          width: 44px;
          height: 44px;
        }

        .circle-icon {
          color: #fcd04d;
          width: 44px;
          height: 44px;
          font-size: 44px;
          position: absolute;
          top: -4px;
          left: -2px;
        }

        .sitemap-icon {
          color: white;
          font-size: 20px;
          width: auto;

          &:before {
            position: absolute;
            top: 9px;
            left: 11px;
          }
        }

        .text {
          margin-left: 10px;
          font-size: 22px;
          line-height: 1;
          font-weight: bold;
          color: #33bdd8;
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        }
      }

      ul {
        .buttons {
          margin: 0;
          border: none;
        }
      }

      .input-select-wrapper {
        flex: 1;
        position: relative;
        border: none;
        margin: 0;
        font-size: 12px;
        color: #a3a9ad;
        background-color: #f5f5f5;
        font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        border-radius: 68px;

        select,
        a.select-mind-map-btn {
          position: absolute;
          right: 10px;
          top: 10px;
          font-size: 12px;
          font-weight: bold;
          background: transparent;
          border: none;
          color: #33bdd8;
        }

        a.select-mind-map-btn {
          i {
            font-size: 9px;
            margin: 0 5px 10px;
          }
          &:hover {
            cursor: pointer;
          }
        }
      }

      .cat-name-input,
      .image-input {
        input {
          border: none;
          margin: 0;
          font-size: 12px;
          color: #a3a9ad;
          background-color: #f5f5f5;
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        }
      }

      .cat-name-input {
        height: 37px;
        align-items: center;
        margin-bottom: 8px;

        input {
          display: block !important;
          padding: 6px 15px 6px 15px;
          width: 100% !important;
          border-radius: 68px;
          height: 37px;
          color: #393939;
          font-size: 14px;
        }

        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #a3a9ad;
          opacity: 1; /* Firefox */
          font-style: italic;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #a3a9ad;
          font-style: italic;
        }
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #a3a9ad;
          font-style: italic;
        }

        .input-select-wrapper {
          height: 37px;
          background: transparent;
        }

        span.current-category-name {
          position: absolute;
          left: 8px;
          top: 5px;
          font-size: 14px;
          display: flex;
          align-items: center;
          border-radius: 14px;
          // border: solid 1px #a3a9ad;
          // background-color: white;
          padding: 5px 25px 5px 0;
          color: #393939;
          font-style: normal;
          line-height: 1;
          min-width: 225px;
        }
      }

      .mind-map-select-wrapper {
        height: 37px;
        margin-bottom: 8px;

        .input-select-wrapper {
          height: 37px;
          position: relative;
        }

        .map-list {
          display: none;
          position: absolute;
          top: 40px;
          width: 100%;
          padding: 6px 10px;
          border-radius: 5px;
          z-index: 6;
          background-color: white;
          @include box-shadow(0 0 5px rgba(0, 0, 0, 0.2));

          &.show {
            display: block;
          }

          &--search {
            input {
              width: 100%;
              border-radius: 7px;
              margin-top: 8px;
              margin-bottom: 20px;
              padding: 8px 15px;
            }
          }

          &--items {
            min-height: 50px;
            max-height: 320px;
            overflow-y: scroll;
            padding-right: 5px;

            &::-webkit-scrollbar {
              width: 6px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: #e3e3e3;
              border-radius: 3px;
            }
          }

          &--item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            line-height: 25px;
            color: black;

            .map-name {
              flex: 1;
              text-align: left;
            }

            .map-shared-user-name {
              margin-right: 10px;
              font-style: italic;
              font-size: 12px;
            }

            b {
              margin-left: 5px;
              img {
                height: 15px;
                position: relative;
                top: -1px;
              }
            }

            &:hover {
              cursor: pointer;
              color: #a3a9ad;
            }
          }
        }

        span.selected-file-name {
          position: absolute;
          left: 15px;
          top: 8px;
          font-size: 14px;
          color: #a3a9ad;
          font-style: italic;

          &.file-name-exist {
            display: flex;
            align-items: center;
            border-radius: 14px;
            border: solid 1px #a3a9ad;
            background-color: white;
            padding: 4px 25px 4px 10px;
            color: #393939;
            font-style: normal;
            line-height: 1;
            top: 5px;
            left: 9px;
            max-width: 70%;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 1.2;
            }

            a {
              position: absolute;
              right: 10px;
              margin-left: 10px;
              font-weight: bold;
              color: #33bdd8;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }

      .image-input {
        height: 37px;
        align-items: center;

        input {
          display: block !important;
          padding: 6px 110px 6px 15px;
          width: 100% !important;
          border-radius: 68px;
          height: 37px;
          color: #393939;
          font-size: 14px;

          &.file-input-custom {
            opacity: 0;
            padding: 0 !important;
            display: inline !important;
            margin: 0 !important;

            &:hover {
              cursor: pointer;
            }

            &.width-0 {
              width: 0 !important;
            }
          }
        }

        span.selected-file-name {
          position: absolute;
          left: 15px;
          top: 8px;
          font-size: 14px;
          color: #a3a9ad;
          font-style: italic;

          &.file-name-exist {
            display: flex;
            align-items: center;
            border-radius: 14px;
            border: solid 1px #a3a9ad;
            background-color: white;
            padding: 4px 25px 4px 10px;
            color: #393939;
            font-style: normal;
            line-height: 1;
            top: 5px;
            left: 9px;
            max-width: 70%;

            span {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              line-height: 1.2;
            }

            a {
              position: absolute;
              right: 10px;
              margin-left: 10px;
              font-weight: bold;
              color: #33bdd8;

              &:hover {
                cursor: pointer;
              }
            }
          }
        }
      }
      .image-preview {
        justify-content: space-between;
        position: relative !important;
        // height: 200px;

        img.preview {
          @include box-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
        }

        &--title {
          font-size: 13px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }

      .selected-cover-image {
        margin-top: 10px;
        justify-content: flex-start;

        img {
          max-width: 100%;
          max-height: 250px;
          @include box-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
        }
      }

      .default-image-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16px;
        max-height: 260px;
        overflow-y: scroll;
        padding-right: 4px;

        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #e3e3e3;
          border-radius: 3px;
        }

        .image-wrap {
          width: 24%;
          height: 113px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px #e3e3e3;
          border-radius: 4px;
          margin-bottom: 15px;

          &.selected {
            border: solid 2px #33bdd8;
          }

          &:hover {
            cursor: pointer;
            border: solid 2px #33bdd8;
          }
        }
      }

      .share-send-button {
        margin: 30px 0 0 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        button {
          background-color: #33bdd8;
          font-weight: bold !important;
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
        }
      }
    }
  }
}

// landing page review modal
.landing-page {
  .overlay {
    z-index: 1038 !important;

    .popup {
      height: 32rem;
    }
  }

  .popup {
    &.img-video-preview-modal {
      // height: 30rem;
      width: 70%;
      height: 80%;
      top: 10%;

      .select-shared {
        height: calc(100% - 100px);
      }

      .title {
        display: inline-block;
        margin: 0 0 30px 0;

        .text {
          font-size: 34px;
          font-weight: 800;
          color: #333333;
          line-height: 1.4;

          @media (max-width: 500px) {
            font-size: 28px;
          }
        }
      }
      ul {
        height: 100%;
        position: relative;
      }
      .email-tab-contents {
        height: 100%;
      }
      .buttons {
        border: none !important;
        position: absolute;
        top: 0;
        height: 100%;
      }
      .email-box.active {
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      .email-box img,
      .email-box video {
        margin: 0 !important;
        width: auto;
        height: auto;
        max-height: 460px;
        max-width: 100%;
      }

      .email-box video {
        width: 85%;
      }
    }

    &.enterprise-apply-modal {
      top: 17%;
      min-height: 28rem;
      height: fit-content !important;
      max-height: 35rem;
      width: 80%;
      padding: 32px;

      .close {
        top: 10px;
        right: 17px;
        opacity: 1;

        img {
          margin: 0 !important;
        }
      }

      .title {
        left: 0;
        margin: 0;
        text-align: center;

        .text {
          font-size: 34px;
          font-weight: 800;
          line-height: 1.4;
          color: #333;
        }

        .landing-page-mail {
          margin: 10px 0 0 0;
          text-decoration: underline;
        }
      }

      .email-tab-contents {
        .email-box {
          display: block;
          padding-top: 40px;
          -moz-opacity: 1;
          -khtml-opacity: 1;
          opacity: 1;
          visibility: visible;

          @media (max-width: 500px) {
            padding: 0 15px;
          }
        }
      }

      .form-group {
        margin-bottom: 10px;
      }

      input {
        height: 45px;
        width: 100%;
        border: none;
        background-color: #f2f2f2;
        font-size: 14px;
        color: #656565;
        padding: 0 30px;
      }

      textarea {
        height: 200px;
        width: 100%;
        resize: none;
        border: none;
        background-color: #f2f2f2;
        font-size: 14px;
        color: #656565;
        padding: 13px 30px;

        &:focus {
          outline: none;
        }
      }

      .submit-form-button {
        height: 45px;
        min-height: 45px;
        width: 100%;
        font-weight: 600 !important;
        @include border-radius(2px);
        border: solid 3px #f5701f;
        color: #f5701f;
        background-color: white;

        &:hover {
          color: white;
          background-color: #f5701f;
        }
      }
    }

    &.review-modal {
      border-radius: 0;
      padding-top: 0 !important;
      padding: 0;
      top: 11%;
      height: 36.5rem;

      .container-checkbox input:checked ~ .checkmark {
        background-color: #fd7021 !important;
      }

      .close {
        color: #656565;
        font-size: unset;
        opacity: 1;
        top: 5px;
        right: 5px;

        img {
          margin: 0 !important;
        }
      }

      .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0 15px 0 !important;
        left: 0 !important;
        padding: 0 30px;

        .text {
          font-size: 34px;
          font-weight: 800;
          color: #333333;
          line-height: 1.4;

          @media (max-width: 500px) {
            font-size: 28px;
          }
        }

        .selected-rate {
          margin-left: 20px;
          padding: 7px 26px;
          border-radius: 32px;
          border: solid 2px #ededed;

          span {
            &:last-of-type {
              i {
                margin-right: 0;
              }
            }
          }

          i {
            font-style: normal;
            margin-right: 10px;
            font-size: 20px;
            line-height: 24px;

            &:before {
              font-family: "fontAwesome";
            }
          }
        }

        @media (max-width: 500px) {
          left: 0 !important;
          padding: 0 !important;
        }
      }

      .email-tab-contents {
        .email-box {
          padding: 0 30px;
          display: block;
          -moz-opacity: 1;
          -khtml-opacity: 1;
          opacity: 1;
          visibility: visible;

          min-height: 470px;

          @media (max-width: 500px) {
            padding: 0 15px;
          }
        }
      }

      .popup-input {
        position: unset;
        top: 0;

        input {
          margin-top: 0;
          margin-bottom: 10px;
          height: 45px;
          border-radius: 2px;
          background-color: #f2f2f2;
          color: #656565;
          line-height: 1.4;
          border: none;
          padding: 13px 30px !important;
        }

        .review-title,
        .review-message {
          &.red {
            border: 1px solid;
            border-color: red;
          }
        }

        input:not([type="checkbox"]) {
          width: 100% !important;
          padding: 5px 15px;
          font-size: 14px;
        }

        input[type="checkbox"] {
          width: 30px;
          left: 0;
          height: 20px;
        }

        .register-checkbox {
          padding-top: 0 !important;
          .checkmark {
            width: 18px !important;
            height: 18px !important;
            border-color: #fd7021;
            border-radius: 2px;

            &:after {
              left: 4px !important;
              top: -1px !important;
              width: 6px !important;
            }
          }
        }

        &.info-box {
          margin: 5px 0 15px 0;
          .hide-show-check {
            flex: 1;
          }
        }

        &.count-area {
          #message-count {
            color: #33bdd8;
          }
        }

        .agreement-wrapper {
          width: 100%;
          input[type="checkbox"] {
            top: -10px;
          }
          .register-checkbox .checkmark {
            top: -8px;
          }
        }
        .feedback-clarify-info {
          font-size: 12px;
          line-height: 1.3;

          i {
            color: #fd7021;
          }
        }
        .clarify-required-error {
          font-size: 13px;
          &.red {
            color: red;
          }
        }

        .register-checkbox {
          position: relative;
          padding-left: 30px !important;

          .checkmark {
            left: 0;
            top: 0px;
          }
        }

        textarea {
          border: none;
          padding: 13px 30px;
          height: 150px;
          font-size: 13px;
          border-radius: 2px;
          background-color: #f2f2f2;

          &:focus {
            outline: none;
          }
        }
      }

      .info-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #656565;
        line-height: 1.4;
        font-size: 14px;

        #required-fields-error {
          &.red {
            color: red;
          }
        }
      }

      .share-send-button {
        margin: 10px 0 0 0;

        position: absolute;
        width: 100%;
        padding: 0 30px;
        right: 0;
        left: 0;
        bottom: 0;

        .cancel {
          font-size: 14px;
          color: #f5701f !important;
          text-transform: unset;
          line-height: 1.43;
          font-weight: 600;

          &:hover {
            cursor: pointer;
          }
        }

        .button {
          font-family: "Montserrat", sans-serif !important;
          font-size: 14px !important;
          color: #f5701f;
          background-color: white;
          border-radius: 2px;
          border: solid 2.5px #f5701f;
          line-height: 1.43 !important;
          font-weight: 600 !important;
          padding: 13px 30px;
          transition: all 0.2s;

          &:hover {
            color: white;
            background-color: #f5701f;
          }
        }
      }

      .right-button-wrap {
        display: flex;
        align-items: center;
      }

      @media (max-width: 768px) {
        width: 90%;
      }

      @media (max-width: 500px) {
        width: 100% !important;
        height: 36rem;
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  opacity: 1;
  z-index: 38 !important;

  // subscription information modal
  &.subscription-information-modal {
    z-index: 110 !important;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;

    .popup {
      padding: 20px !important;
      width: 50%;
      height: 18rem;

      .title {
        left: 0;
        margin: 0 0 16px 0;
        font-size: 22px;
        font-weight: bold;
        line-height: 1.23;
        color: #33bdd8;
      }

      .close {
        right: 21px;
        color: #33bdd8;
        opacity: 1;
        font-size: 22px;
        line-height: 1.23;
      }

      .select-shared {
        max-height: 310px;
        overflow-y: scroll;
        padding-right: 6px;
        font-size: 14px;
        line-height: 1.4;
        color: #393939;

        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ebebeb;
          @include border-radius(3px);
        }

        a {
          color: #33bdd8;
          font-weight: bold;
          text-decoration: underline;
        }
      }

      .modal-content-wrapper {
        text-align: center;
        height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .popup {
    padding: 20px 0;
    background: #fff;
    border-radius: 25px;
    width: 61%;
    -webkit-transition: all 5s ease-in-out;
    transition: all 5s ease-in-out;
    top: 20%;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    height: 25rem;

    // login page custom modal css ---
    &.get-info-for-google-login-modal {
      top: 25%;
      height: 350px;

      .title {
        left: 0;
        margin: 0 21px 16px 21px;
        width: calc(100% - 42px);
        display: flex;
        align-items: center;

        .icon-wrap {
          width: 44px;
          height: 44px;
        }

        .circle-icon {
          color: #fcd04d;
          width: 44px;
          height: 44px;
          font-size: 44px;
        }

        .sitemap-icon {
          color: white;
          font-size: 20px;
          width: auto;
          position: relative;
          top: 10px;
          left: 10px;
        }

        .text {
          margin-left: 10px;
          font-size: 22px;
          line-height: 1;
          font-weight: bold;
          color: #33bdd8;
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        }
      }
      ul {
        .buttons {
          border: none;
          i {
            color: unset;
          }
        }
        .shared-select {
          margin: 0 21px;
        }
      }
      .email-box {
        .info {
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
          font-size: 16px;
          line-height: 1.4;
          color: #393939;
        }
      }
      .user-type-select-wrapper {
        color: black !important;
        margin: 0 auto;
      }
      .radio-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;

        .form-check {
          width: calc(50% - 15px);
          text-align: left;
          padding-left: 0;

          &:first-of-type {
            margin-right: 30px;
          }

          &-input.active {
            & ~ label {
              border-bottom: 2px solid #33bdd8;
              font-weight: bold;
              i {
                font-weight: bold;
              }
            }
          }

          &-label {
            width: 100%;
            display: flex;
            align-items: center;
            padding: 8px !important;
            color: #33bdd8;
            border-bottom: 2px solid transparent;
            font-size: 16px;
            line-height: 20px;
            i {
              font-size: 24px;
              margin-right: 8px;
            }
            &:hover {
              cursor: pointer;
            }
          }
          &-input {
            margin-top: 0.6rem;
            display: none;
            &:checked ~ label {
              color: #33bdd8;
              border-bottom-color: #33bdd8;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }

        .teacher-info,
        .student-info {
          width: 100%;
          margin: 17px 0;

          & > * {
            width: 100%;
            height: 56px;
            border: none;
            font-style: italic;
            line-height: 1.31;
            background-color: #f5f5f5;
            @include border-radius(28px);
            color: #a3a9ad;
            font-size: 16px;
            padding: 18px 32px;
            font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
          }
        }

        .student-info {
          width: 100%;
          height: 56px;
          border: none;
          background-color: #f5f5f5;
          font-style: italic;
          line-height: 1.31;
          @include border-radius(28px);
          color: #a3a9ad;
          font-size: 16px;
          padding: 16px 32px;
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        }
      }

      .yellow-button {
        height: 56px;
        font-weight: bold !important;
        font-size: 15px !important;
        font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
      }
    }
    // --- login page custom modal css

    .title {
      margin: 30px 0px;
      font-size: 18px;
      font-weight: 400;
      left: 50px;
      position: relative;
    }

    .sub-title {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .email-tab-buttons {
      display: flex;
      justify-content: space-around;
      margin-bottom: 25px;
      a {
        display: flex;
        align-items: center;
        padding-left: 8px;
        margin-right: 7.5px;
        color: #33bdd8;
        font-size: 14px;
        width: 100%;
        line-height: 35px;
        text-align: center;
        border-bottom: 2.5px solid transparent;

        &:last-of-type {
          margin-right: 0;
        }

        &.active {
          font-weight: bold;
          border-bottom: 2.5px solid #33bdd8;
        }

        &:hover {
          cursor: pointer;
        }

        i {
          margin-right: 5px;
          font-size: 24px;
        }

        @include phone {
          flex-direction: column;
          font-size: 12px;
          line-height: 25px;
        }
      }
    }

    .popup-input {
      position: relative;
      top: 20px;
      display: flex;
      justify-content: center;

      &.mail-input {
        justify-content: space-between;
      }

      &.info-box {
        justify-content: left;
      }

      input {
        font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        border: 1px solid #cccccc;
        height: 35px;
        width: 90%;
        border-radius: 60px;
        background-color: transparent;
        color: #454545;
        font-weight: 500;
        margin-bottom: 7px;
        margin-top: 10px;
        font-size: 15px;
        padding: 15px;
      }

      textarea {
        resize: none;
        width: 100%;
        height: 74px;
        border-radius: 10px;
        background-color: transparent;
        color: #454545;
        font-weight: 500;
        margin-bottom: 7px;
        margin-top: 10px;
        font-size: 15px;
        padding: 15px;
      }

      &.count-area {
        justify-content: flex-end;
        margin-top: 2px;

        #message-count {
          font-size: 10px;
          font-weight: bold;
        }
      }
    }

    .close {
      position: absolute;
      top: 20px;
      right: 30px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: #333;
      cursor: pointer;
    }

    .close:hover {
      color: #34c8f5;
    }

    .content-box {
      margin: 30px 0 25px 0;
      text-align: center;
    }

    .right-botton-wrap {
      align-items: center;
      display: flex;
    }

    .share-send-button {
      margin: 15px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .cancel {
        position: static;
        background-color: transparent;
        color: gray !important;
        font-size: 12px;
        margin-right: 20px;
        text-transform: lowercase;
      }

      .button {
        min-height: 0;
        min-width: 0;
        background-color: $color-blue-shine;
        padding: 9px 30px;
      }
    }

    .link-send-button {
      position: relative;
      top: 15px;
      margin: 15px;

      .button {
        min-height: 0;
        min-width: 0;
        background-color: $color-blue-shine;
        padding: 9px 30px;
      }
    }

    .email-tab-contents {
      label {
        padding: 0px 0 !important;
        padding-top: 3px !important;
      }
      .email-box {
        display: none;
        @include transition(all 0.2s ease-in-out);
        @include opacity(0);
        visibility: hidden;
        position: relative;
        .form-radio {
          position: relative;
          display: block;
          padding-left: 0.9rem;
          text-align: left;
          border: none;
          margin-top: 8px;
          background-color: #f5f5f5;
          border-radius: 68px;
          height: 37px;
          padding-top: 4px;
          display: flex;
          justify-content: space-between;
          .form-radio-label-ask {
            margin-right: 3rem;
            font-size: 14px;
            color: #a3a9ad;
            font-style: italic;
            font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
          }
          .radio-group {
            input[type="radio"] {
              position: absolute;
              opacity: 0;
              + .form-radio-label {
                &:before {
                  content: "";
                  background: #f4f4f4;
                  border-radius: 100%;
                  border: 1px solid darken(#f4f4f4, 25%);
                  display: inline-block;
                  width: 1.6em;
                  height: 1.6em;
                  position: relative;
                  top: -0.2em;
                  margin-right: 1em;
                  vertical-align: top;
                  cursor: pointer;
                  text-align: center;
                  transition: all 250ms ease;
                  top: 0.03rem;
                  left: 5px;
                }
              }
              &:checked {
                + .form-radio-label {
                  &:before {
                    background-color: #3197ee;
                    box-shadow: inset 0 0 0 4px #f4f4f4;
                  }
                }
              }
              &:focus {
                + .form-radio-label {
                  &:before {
                    outline: none;
                    border-color: #3197ee;
                  }
                }
              }
            }
            .form-radio-input {
              position: relative;
              top: 2px;
            }
            .form-radio-label {
              font-size: 12px;
              font-weight: bold;
              color: #33bdd8;
              margin-left: 0.4rem;
              margin-right: 1rem;
              margin-bottom: 0px;
            }
          }
        }
        .input-tag {
          border: 1px solid transparent;
          display: flex;
          flex-wrap: wrap;
          font-size: 12px;
          color: #a3a9ad;
          background-color: #f5f5f5;
          font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
          border-radius: 68px;
          min-height: 37px;
          margin-top: 8px;
          padding: 11px 14px 6px;
          margin-bottom: 8px;
        }

        .input-tag input {
          border: none;
          width: 100%;
          border-radius: 6px;
          border: 1px solid grey;
          padding-left: 10px;
          height: 30px;
        }
        ::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #a3a9ad;
          opacity: 1; /* Firefox */
          font-style: italic;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #a3a9ad;
          font-style: italic;
        }
        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #a3a9ad;
          font-style: italic;
        }

        .input-tag__tags {
          display: inline-flex;
          flex-wrap: wrap;
          margin: 0;
          padding: 0;
          width: 100%;
        }

        .input-tag__tags li {
          align-items: center;
          background: #85a3bf;
          border-radius: 2px;
          color: white;
          display: flex;
          font-weight: 300;
          list-style: none;
          margin-bottom: 5px;
          margin-right: 5px;
          padding: 5px 10px;
          border-radius: 14px;
        }

        .input-tag__tags li button {
          align-items: center;
          appearance: none;
          background: #333333;
          border: none;
          border-radius: 50%;
          color: white;
          cursor: pointer;
          display: inline-flex;
          font-size: 12px;
          height: 15px;
          justify-content: center;
          line-height: 0;
          margin-left: 8px;
          padding: 0;
          transform: rotate(45deg);
          width: 15px;
        }

        .input-tag__tags li.input-tag__tags__input {
          background: none;
          flex-grow: 1;
          padding: 0;
          width: 100%;
        }
      }
      .email-box.active {
        display: block;
        @include opacity(1);
        visibility: visible;
      }
    }

    @media screen and (max-width: 700px) {
      .box {
        width: 70%;
      }
    }

    ul {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      .buttons {
        transition: all 0.2s ease-in-out;
        // display: inline-block;
        min-width: 0px;
        min-height: 0px;
        border-radius: 22px;
        border: solid 1px #c2c2c2;
        // margin-left: 55px;
        // margin-right: 55px;
        margin: 0 30px;
        // height: 16rem;
        width: 50%;

        &.public-share {
          width: 100%;

          .copied-info {
            font-size: 14px;
            color: coral;
            font-weight: bold;
          }

          .public-share-input-wrap {
            align-items: center;
            position: relative !important;
            top: 0;
          }

          input.public-link {
            padding: 6px 100px 6px 15px;
            width: 100% !important;
            border-radius: 68px;
            border: none;
            height: 37px;
            color: #393939;
            background-color: #f5f5f5;
            font-size: 14px;
          }

          .copy-link-btn {
            position: absolute;
            right: 0;
            top: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #33bdd8;
            color: white !important;
            font-size: 12px;
            border-radius: 68px;
            min-width: 100px;
            height: 37px;
            padding: 0 12px;

            &:hover {
              cursor: pointer;
            }

            i {
              color: #fff;
              font-size: 24px;
              margin-right: 10px;
            }
          }
        }

        i {
          color: $color-picton-blue;
        }

        .circle-icon {
          color: $color-yellow-shine;
        }

        .sitemap-icon {
          color: $color-white;
          font-size: 20px;
        }

        .shared-email {
          display: flex;
          margin: 10px;

          p {
            font-size: 14px;
            color: black;
          }

          .fa-2x {
            font-size: 1.5em;
          }
        }
      }
      .shared-select {
        width: 100%;
        border-radius: 22px;

        .circle-icon {
          color: $color-yellow-shine;
        }
        .sitemap-icon {
          color: $color-white;
          font-size: 20px;
        }

        .shared-email {
          display: flex;
        }
      }
    }
  }
}

.format-painter-info {
  position: absolute;
  bottom: 15px;
  left: 140px;
  // bottom: 20px;
  // left: calc(50% - 190px);
  // max-width: 380px;
  // line-height: 1.3;
  text-align: center;
  font-size: 13px;
  color: rgba(33, 37, 41, 0.6) !important;
  padding: 5px 12px;
  border-radius: 10px;
  background-color: rgba(51, 189, 216, 0.45);
  @include box-shadow(3px 3px 5px 0px rgba(0, 0, 0, 0.25));
  transition: all 0.3s;

  &.passive {
    visibility: hidden;
    opacity: 0;
  }
  &.active {
    visibility: visible;
    opacity: 1;
  }
}

.editor-header-tools {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-size: 15px;
  color: #454545;
  font-weight: 500;
  border: 0;
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 0px;
  width: calc(100% - 15px);
}

.back-btn {
  cursor: pointer;
  font-size: 18px;
}

.back-icon {
  font-size: 24px;
  vertical-align: middle;
  color: #393939;
}

.editor-wrapper {
  border-radius: 5px;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  float: left;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 47px;
  width: 10rem;
  padding: 0 8px 0 8px;
  text-align: center;
  color: #555;

  @media screen and (min-width: 500px) and (max-width: 767px) {
    width: 14rem;
  }

  .buttons {
    cursor: pointer;

    i {
      font-size: 24px;
      line-height: 30px;
    }
  }

  .doc-name {
    width: 15rem;
  }

  @include tablet {
    width: 12rem;
  }
}

.shape-size-wrapper {
  position: relative;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #555;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.toolbox-item-border {
    border: none;

    i {
      color: #33bdd8;
    }
  }

  i {
    font-size: 24px;
    color: #a3a9ad;
  }
}

.shape-size-button {
  cursor: pointer;
}

.shape-size-dropdown-content {
  display: none;
  background-color: #ffffff;
  @include box-shadow(0 0 6px rgba(0, 0, 0, 0.2));
  z-index: 99;
  padding: 8px;
  position: absolute;
  top: 36px;
  left: 0;
  width: 220px;
  @include border-radius(5px);
  justify-content: space-between;
  flex-wrap: wrap;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19px solid white;
    position: absolute;
    top: -15px;
    left: 3px;
  }
}

.shape-size-dropdown-content div {
  color: black;
  padding: 5px 4px;
  text-decoration: none;
  display: flex;
  font-size: 14px;
  border: none;
  cursor: pointer;
  border: 2px solid rgba(50, 70, 90, 0.5);
  border-radius: 5px;
  height: 5%;
  margin: 5px 0;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 48%;
  height: 30px;
}

.shape-size-dropdown-content div:hover,
.shape-size-dropdown-content div:focus {
  border: 2px solid #33bdd8;
}

.show {
  display: flex;
}

.shape-size-select {
  border: 2px solid #33bdd8 !important;
  color: #33bdd8 !important;
}

// Layout wrapper
.layout-wrapper {
  border-radius: 5px;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  float: left;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 60px;
  height: 55px;
  text-align: center;
  color: #555;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  left: 30px;
}

.layout-item-name {
  font-size: 10px;
  word-break: break-word;
}

.layout-wrapper:hover,
.layout-wrapper:focus {
  border: 3px solid #33bdd8;
}

.layout-wrapper-border {
  border: 3px solid #33bdd8;
}

.layout-wrapper-border:hover,
.layout-wrapper:focus {
  border: 3px solid #33bdd8;
}

.layout-wrapper-border:hover::before {
  font-family: FontAwesome;
  display: flex;
  vertical-align: middle;
  top: 43px;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.layout-wrapper-border:hover::before {
  content: "\f0d7";
  color: white;
}

.layout-wrapper-border:before {
  font-family: FontAwesome;
  display: flex;
  vertical-align: middle;
  top: 43px;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.layout-wrapper-border:before {
  content: "\f0d7";
  color: white;
}

.layout-dropdown {
  position: relative;

  .tree {
    max-height: 38px;
  }

  img {
    max-height: 28px;
  }
}

.layout-button {
  color: black;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
}

.layout-dropdown-content {
  display: none;
  position: fixed;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  width: 280px;
  left: 410px;
  top: 80px;
  border-radius: 4px;
}

.layout-dropdown-content div {
  color: black;
  padding: 0px;
  text-decoration: none;
  display: flex;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 70px;
  justify-content: center;
  align-items: center;
  height: 55px;
}

.layout-dropdown-content div:hover,
.layout-dropdown-content div:focus {
  border: 2px solid #33bdd8;
}

.layout-select {
  border: 2px solid #33bdd8 !important;
}

.show-users {
  text-align: center;
}

.undo-changes-button,
.redo-changes-button {
  // position: absolute;
  // top: 24px;
  text-align: center;
  margin-left: 0;

  .right-bottom-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    @include box-shadow(none);
    &[disabled] {
      opacity: 0.5;
    }
  }

  i {
    font-size: 20px !important;
  }
}

.undo-changes-button {
  margin-left: 16px;
}
.redo-changes-button {
  margin-left: 8px;
}

.copy-map {
  position: absolute;
  top: 163px;
  right: 26px;

  .right-bottom-button {
    background-color: #fff;
    color: #33bdd8 !important;
  }

  &.my-map {
    position: absolute;
    top: 270px;
    right: 26px;
  }
}

.fav-button {
  position: absolute;
  top: 217px;
  right: 26px;
  a {
    width: 47px;
    height: 47px;
    font-size: 9px;
    font-weight: bold;
    @include border-radius(100%);
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    @include box-shadow(0 0 12px rgba(0, 0, 0, 0.2));

    i {
      color: #fd7021;
      font-size: 24px;
    }

    &.active {
      i {
        color: #fd7021;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &.noOwnerFavBtn {
    top: 107px;
  }

  &.private-public-btn {
    position: absolute;
    top: 52px;
    right: 26px;

    a {
      i {
        color: #33bdd8;
      }
    }
  }
}

.shared-link {
  position: absolute;
  top: 162px;
  right: 26px;
  margin-left: 8px;

  @media screen and (min-width: 500px) and (max-width: 991px) {
    min-width: 110px;
    margin-left: 5px;
    margin-bottom: 3px;
  }
}

.format-painter-btn {
  position: absolute;
  top: 79px;
  left: 16px;

  &.active {
    .right-bottom-button {
      border: 2px solid #33bdd8;
    }
  }

  .right-bottom-button {
    border: 2px solid transparent;
    padding: 6px 15px;
    font-size: 9.5px;
    background-color: #fff;

    i {
      font-size: 24px;
      color: #33bdd8;
    }
  }
}

.save-template-btn {
  position: absolute;
  top: 189px;
  left: 16px;

  .right-bottom-button {
    padding: 6px 15px;
    font-size: 9.5px;
    background-color: #fff;

    i {
      font-size: 24px;
      color: #33bdd8;
    }
  }
}

.download-link {
  position: absolute;
  top: 107px;
  right: 26px;

  &.noOwnerDownloadBtn {
    top: 52px;
  }

  &.copy-map-btn {
    top: 16px;

    &.noOwnerDownloadBtn {
      top: 163px;
      right: 26px;
    }

    .right-bottom-button {
      background-color: #fff;
      color: #33bdd8 !important;
    }
  }

  .download-dropdown {
    position: relative;
  }

  .download-options {
    display: none;
    position: absolute;
    top: 12px;
    right: 60px;
    width: 95px;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    padding: 4px;
    @include box-shadow(0 0 10px rgba(0, 0, 0, 0.2));
    a {
      line-height: 1.7;
      font-size: 12px;
      border: 1px solid #fff;
      padding: 2px 3px;
      border-radius: 3px;
      color: #000;
      font-weight: normal;

      &:hover {
        cursor: pointer;
        border-color: #33bdd8;
        color: #000;
      }
    }

    &.show {
      display: flex !important;
    }

    &:before {
      font-family: FontAwesome;
      display: flex;
      vertical-align: middle;
      position: absolute;
      justify-content: center;
      align-items: center;
      content: "\f0da";
      color: white;
      right: -5px;
      top: 6px;
      font-size: 15px;
    }
  }

  &:last-of-type {
    z-index: 12;
  }
}

.right-bottom-button {
  cursor: pointer;
  background-color: #33bdd8;
  border-radius: 100%;
  padding: 6px 10px;
  color: #fff !important;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 32px;
  font-size: 9px;
  line-height: 10px;
  font-weight: 600;
  @include box-shadow(0 0 12px rgba(0, 0, 0, 0.2));
  width: 47px;
  height: 47px;

  &.download-dropdown,
  &.share-modal-btn {
    background-color: #fff;
    color: #33bdd8 !important;
  }

  i {
    font-size: 24px;
  }
}

#fit-map {
  position: absolute;
  right: 19px;
  bottom: 19px;
  background-color: #33bdd8;
  color: white;
  padding: 4.5px 10px;
  font-size: 14px;
  @include border-radius(6px);

  &:disabled {
    opacity: 0.5;
  }
}

.editor-header-left {
  position: absolute;
  top: 16px;
  display: flex;
  align-items: center;
}

.editor-header-right {
  position: absolute;
  top: 12px;
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex !important; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-wrap: wrap;
  float: right;
  right: 0;

  @media screen and (min-width: 500px) and (max-width: 991px) {
    max-width: 235px;
  }
}
.editor-right-header {
  right: 16px;
  position: absolute;
  top: 16px;
  display: flex;
  align-items: center;
}
.share-button-wrapper,
.public-button-wrapper {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex !important; /* NEW, Spec - Opera 12.1, Firefox 20+ */

  @media screen and (min-width: 500px) and (max-width: 991px) {
    max-width: 235px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

// shape toolbox
.shape-toolbar {
  background-color: #fff;
}
.shape-wrapper {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 0;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-top: none;

  &.toolbox-item-border {
    border: none;

    .shape-button i {
      color: #33bdd8;
    }
  }
}

.shape-dropdown {
  position: relative;

  img {
    height: 24px;
    width: 34px;
    margin: 0 auto;
  }
}

.shape-button {
  color: #a3a9ad;
  font-size: 24px;
  border: none;
  cursor: pointer;
}

.shape-dropdown-content {
  display: none;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));
  width: 220px;
  position: absolute;
  left: -50px;
  top: 40px;
  @include border-radius(4px);
  padding: 16px 8px;
  z-index: 99;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19px solid white;
    position: absolute;
    top: -16px;
    left: 50px;
  }
}

.shape-dropdown-content div.shapeSelectItem {
  color: black;
  padding: 0px;
  text-decoration: none;
  display: flex;
  font-size: 10px;
  border: none;
  cursor: pointer;
  @include border-radius(4px);
  justify-content: space-between;
  border: 2px solid transparent;

  .circle-shape {
    border: 3.5px solid #d5eff9;
    border-radius: 100%;
    display: inline-block;
    width: 29px;
    height: 29px;
  }
}

.shape-dropdown-content div.shapeSelectItem:hover,
.shape-dropdown-content div.shapeSelectItem:focus {
  border: 2px solid #33bdd8;
}

.shape-dropdown-content div.shapeSelectContinue {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 40px;

  &.hide {
    display: none;
  }

  .text {
    margin-left: 6px;
    font-size: 13px;
    height: 24px;
    line-height: 24px;
  }

  label {
    position: relative;
    width: 24px;
    .checkmark {
      position: absolute;
      left: 0;
      top: 4px;
      height: 24px !important;
      width: 24px !important;
      border-radius: 4px;
      border: 2px solid #33bdd8;
      background-color: #fff !important;
      &:after {
        left: 6px !important;
        top: 2.3px !important;
        width: 7px !important;
        height: 12px !important;
        border-width: 0 2px 2px 0 !important;
        border: solid #fff;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 14px;
        margin: 0;
      }
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    cursor: pointer;
    width: 10px;
    height: 10px;
  }

  input:checked ~ .checkmark {
    background-color: #33bdd8 !important;
    &:after {
      display: block;
    }
  }
}

.shape-select {
  border: 2px solid #33bdd8 !important;
}

// icon wrapper
.icon-toolbar {
  background-color: #fff;
}
.icon-wrapper {
  position: relative;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  &.toolbox-item-border {
    border: none;

    .icon-button i {
      color: #33bdd8;
    }
  }
}

.icon-dropdown {
  position: relative;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;

  img {
    width: 40px;
    height: 25px;
  }
}

.icon-button {
  color: #a3a9ad;
  font-size: 24px;
  border: none;
  cursor: pointer;
  padding: 3px;
}

.icon-dropdown-content {
  display: none;
  background-color: #ffffff;
  @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));
  width: 200px;
  height: 140px;
  position: absolute;
  right: 0;
  top: 40px;
  @include border-radius(5px);

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19px solid white;
    position: absolute;
    top: -15px;
    right: 3px;
  }
}

.icon-dropdown-content div.icon-list {
  color: black;
  padding: 5px 15px 10px 0;
  text-decoration: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  overflow-y: scroll;
  height: 124px;

  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 5px;
  }

  // emoji list
  &.emoji-list {
    width: 184px;
    padding-top: 40px;
    padding-right: 5px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 30px);
  }
}
.icon-dropdown-content .icon-list-wrapper {
  position: relative;
  padding: 8px;
  .icon-list-actions {
    position: absolute;
    top: 0;
    width: calc(100% - 23px);
    height: 35px;
    background-color: white;
    border-top-left-radius: 5px;
    @include box-shadow(0px 1px 1px 0px rgba(0, 0, 0, 0.2));
  }
  .icon-delete-button {
    position: absolute;
    top: 8px;
    right: 8px;
    font-size: 11px;
    text-align: right;
    padding: 4px 7px;
    background-color: $color-crimson;
    color: $color-concrete;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
  }
}
// icon list
.icon-dropdown-content div.icon-list > div {
  &.iconSelect {
    width: 41%;
    font-size: 6px;
    padding: 2px;
    border: 2px solid transparent;
    text-align: left;
    align-items: center;
    display: flex;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
  }

  &.icon-select {
    border: 2px solid #33bdd8 !important;
  }

  i {
    color: $color-dodger-blue-v2;
    margin-right: 5px;
    font-size: 10px;
  }
}
.icon-dropdown-content div.icon-list > div:hover,
.icon-dropdown-content div.icon-list > div:focus {
  border: 2px solid #33bdd8;
}
// emoji list
.icon-dropdown-content div.emoji-list > div {
  &.iconSelect {
    width: 30px;
    height: 30px;
    font-size: 20px;
    padding: 2px;
    border: 2px solid transparent;
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 1px 0;

    span {
      font-family: "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji",
        Times, Symbola, Aegyptus, Code2000, Code2001, Code2002, Musica, serif,
        LastResort;

      &:before {
        display: block;
        width: 30px;
        height: 30px;
      }
    }
  }

  &.icon-select {
    border: 2px solid #33bdd8 !important;
  }
}

.icon-dropdown-content div.emoji-list > div:hover,
.icon-dropdown-content div.emoji-list > div:focus {
  border: 2px solid #33bdd8;
}

// font toolbox
.font-wrapper {
  position: relative;
  border-radius: 5px;
  background: #fff;
  height: 30px;
  width: 106px;
  border-radius: 15px;
  border: solid 2px #33bdd8;
  color: #33bdd8;
  cursor: pointer;
}

.font-dropdown {
  .font-name {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
}

.font-button {
  position: relative;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  padding: 0 0 0 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 27px;
  color: #33bdd8;

  span {
    display: block;
    width: calc(100% - 18px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  i {
    position: absolute;
    right: -2px;
    top: 1px;
    font-size: 24px;
    color: #33bdd8;
  }
}

.font-dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  top: 41px;
  background-color: #ffffff;
  @include border-radius(5px);
  @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));
  width: 106px;
  padding: 8px 0;
  z-index: 99;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19px solid white;
    position: absolute;
    top: -15px;
    left: calc(50% - 8px);
  }
}

.font-dropdown-content-wrap {
  max-height: 200px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 7px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #7a7878;
    border-radius: 10px;
  }
}

.font-dropdown-content .fontSelect {
  color: black;
  padding: 3px 5px;
  text-decoration: none;
  font-size: 11px;
  line-height: 1.1;
  border: none;
  cursor: pointer;
  @include border-radius(5px);
  width: 96%;
  border: 2px solid transparent;
}
.font-dropdown-content .fontSelect:hover,
.font-dropdown-content .fontSelect:focus {
  border: 2px solid #33bdd8;
}
.font-select {
  border: 2px solid #33bdd8 !important;
}
.fontSelect {
  display: flex;
  align-items: center;
  margin: auto;
}

// font size wrapper
.fontSize-wrapper {
  position: relative;
  border: none;
  cursor: pointer;
  @include border-radius(15px);
  padding: 0 0 0 5px;
  font-size: 14px;
  font-weight: bold;
  line-height: 20px;
  border: solid 2px #33bdd8;
  color: #33bdd8;
  width: 68px;
  height: 30px;

  span {
    display: block;
    width: calc(100% - 18px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  i {
    position: absolute;
    right: -2px;
    top: 1px;
    font-size: 24px;
    color: #33bdd8;
  }
}

.fontSize-dropdown {
  .fontSize-name {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
}

.fontsize-input-wrapper {
  display: flex;
}
.btn-font-size {
  background-color: #33bdd8;
  color: #fff !important;
  padding: 5px;
  font-size: 12px;
  margin-left: 10px;
  border-radius: 0;
}

.fontSize-button {
  color: black;
  font-size: 14px;
  line-height: 27px;
  color: #33bdd8;
  border: none;
  cursor: pointer;
  padding: 0 0 0 8px;
}
.fontSize-dropdown-content-wrap {
  width: 100%;
}
.fontSize-dropdown-content {
  display: none;
  background-color: #ffffff;
  @include border-radius(5px);
  @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));
  padding: 8px 0;
  position: absolute;
  left: 0;
  top: 41px;
  z-index: 99;
  width: 68px;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19px solid white;
    position: absolute;
    top: -15px;
    left: calc(50% - 8px);
  }
}

.fontSize-dropdown-content .fontSizeSelect {
  color: #393939;
  padding: 0;
  text-decoration: none;
  font-size: 14px;
  border: 2px solid transparent;
  cursor: pointer;
  @include border-radius(5px);
  line-height: 1.4;
  width: 80%;
}
.fontSize-dropdown-content .fontSizeSelect:hover,
.fontSize-dropdown-content .fontSizeSelect:focus {
  border: 2px solid #33bdd8;
}
.fontSize-select {
  border: 2px solid #33bdd8 !important;
}
.fontSizeSelect {
  display: flex;
  align-items: center;
  margin: auto;
}

// line toolbar - su an kullanilmiyor
.line-toolbar {
  top: 145px;
  position: absolute;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.line-wrapper {
  position: relative;
  width: 60px;
  height: 55px;
  border-radius: 5px;
  padding: 6px 0 0 0;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 3px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-item-name {
  margin-top: 6px;
  font-size: 10px;
  word-break: break-word;
}

.line-wrapper:hover,
.line-wrapper:focus {
  border: 3px solid #33bdd8;
}

.line-wrapper-border {
  border: 3px solid #33bdd8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-wrapper-border:hover,
.line-wrapper:focus {
  border: 3px solid #33bdd8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.line-wrapper-border:hover::before {
  font-family: FontAwesome;
  display: flex;
  vertical-align: middle;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.line-wrapper-border:hover::before {
  content: "\f0da";
  color: white;
  float: left;
  left: 58px;
  top: 12px;
}

.line-wrapper-border:before {
  font-family: FontAwesome;
  display: flex;
  vertical-align: middle;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.line-wrapper-border:before {
  content: "\f0da";
  color: white;
  float: left;
  left: 58px;
  top: 12px;
}

.line-dropdown {
  position: relative;

  img {
    width: 40px;
    height: 25px;
  }
}

.line-button {
  color: black;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 22px;
    height: 6px;
    margin: 6px 0;
  }
}

.line-dropdown-content {
  display: none;
  position: fixed;
  background-color: #ffffff;
  min-width: 0;
  overflow: auto;
  -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 0;
  width: inherit;
  top: 145px;
  left: 90px;
  width: 170px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  float: left;
  padding: 5px 0;
}

.line-dropdown-content div {
  color: black;
  padding: 12px 0px;
  text-decoration: none;
  display: flex;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 50px;
  height: 5%;
  justify-content: center;
  align-items: center;
}

.line-dropdown-content div:hover,
.line-dropdown-content div:focus {
  border: 2px solid #33bdd8;
  padding: 12px 0px;
}

.show {
  display: flex;
}

.line-select {
  border: 2px solid #33bdd8 !important;
}

.line-1 {
  border: 1.5px solid #a700bf;
  width: 50px;
  margin: 8px;
}

.line-2 {
  border: 1.5px dashed #a700bf;
  width: 50px;
  margin: 8px;
}

.line-3 {
  border: 1.5px dotted #a700bf;
  width: 50px;
  margin: 8px;
}

// background color toolbox
.background-toolbar {
  position: relative;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: white;

  &.toolbox-item-border {
    border: none;

    i {
      color: #33bdd8;
    }
  }

  i {
    font-size: 24px;
    color: #a3a9ad;
  }

  .color-list-wrapper-picker {
    position: absolute;
    top: 40px;
    // left: -70px;
    right: -160px;
  }
}

.background-wrapper-border {
  border: 3px solid #33bdd8 !important;
}
.background-wrapper-border-none {
  border: none !important;
}

// border toolbar
.border-toolbar {
  position: relative;
  height: 30px;
  width: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: white;

  &.toolbox-item-border {
    border: none;

    i {
      color: #33bdd8;
    }
  }

  i {
    font-size: 24px;
    color: #a3a9ad;
  }

  .color-list-wrapper-border {
    position: absolute;
    top: 40px;
    // right: -50px;
    right: -115px;
  }
}

// text toolbar
.text-toolbar {
  position: relative;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 30px;
  height: 30px;
  @include border-radius(4px);
  border: solid 2px #e3e3e3;

  &.toolbox-item-border {
    border: 2px solid #e3e3e3;
  }

  .color-list-wrapper-text {
    position: absolute;
    top: 36px;
    right: 0;
  }
}

.color-list-wrapper-picker {
  // width: 180px;
  width: 334px;
  background: #fff;
  // height: 111px;
  height: 165px;
  border: 5px solid #fff;
  @include border-radius(5px);
  @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));
  z-index: 9999;
  cursor: pointer;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19px solid white;
    position: absolute;
    top: -19px;
    right: calc(50% - 4px);
  }
}
.color-list-wrapper-picker .color-item {
  cursor: pointer;
  width: 15px !important;
  height: 15px !important;
  list-style-type: none;
  float: right;
  margin: 1px;
  border: 1px solid #ddd !important;
  &.bg-color-select {
    border: 3px solid !important;
  }
}
.color-list-wrapper-picker .color-item:hover {
  border: 1px solid #666 !important;
  // opacity: 0.8;
  // -moz-opacity: 0.8;
  // filter: alpha(opacity=8);
  &.bg-color-select {
    border: 3px solid !important;
  }
}

.color-list-wrapper-border {
  // width: 180px;
  width: 334px;
  background: #fff;
  // height: 111px;
  height: 165px;
  border: 5px solid #fff;
  @include border-radius(5px);
  @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));
  z-index: 9999;
  cursor: pointer;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19px solid white;
    position: absolute;
    top: -19px;
    right: calc(50% - 55px);
  }
}
.color-list-wrapper-border .color-item {
  cursor: pointer;
  width: 15px !important;
  height: 15px !important;
  list-style-type: none;
  float: right;
  margin: 1px;
  border: 1px solid #ddd !important;
  &.border-color-select {
    border: 3px solid !important;
  }
}
.color-list-wrapper-border .color-item:hover {
  border: 1px solid #666 !important;
  // opacity: 0.8;
  // -moz-opacity: 0.8;
  // filter: alpha(opacity=8);
  &.border-color-select {
    border: 3px solid !important;
  }
}

.color-list-wrapper-text {
  // width: 180px;
  width: 334px;
  background: #fff;
  // height: 111px;
  height: 165px;
  border: 5px solid #fff;
  @include border-radius(5px);
  @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));
  z-index: 9999;
  cursor: pointer;

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 19px solid white;
    position: absolute;
    top: -19px;
    right: -3px;
  }
}
.color-list-wrapper-text .color-item {
  cursor: pointer;
  width: 15px !important;
  height: 15px !important;
  list-style-type: none;
  float: right;
  margin: 1px;
  border: 1px solid #ddd !important;
  &.text-color-select {
    border: 3px solid !important;
  }
}
.color-list-wrapper-text .color-item:hover {
  border: 1px solid #666;
  opacity: 0.8;
  -moz-opacity: 0.8;
  filter: alpha(opacity=8);
  &.text-color-select {
    border: 3px solid !important;
  }
}

.color-picker {
  position: relative;
  border-radius: 0;
  margin-bottom: 4px;
  width: 40px;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  text-indent: -999999px;
  cursor: pointer;

  @include phone {
    left: 0px;
    margin-bottom: 0px;
  }
}

.color-picker-border {
  position: relative;
  width: 40px !important;
  border-radius: 0;
  border: 1px solid transparent !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  text-indent: -999999px;
  cursor: pointer;
  height: 30px;
  @include phone {
    left: 0px;
  }
}

.color-picker-text {
  position: relative;
  width: 40px !important;
  border-radius: 0;
  border: 1px solid transparent !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  text-indent: -999999px;
  cursor: pointer;
  height: 30px;
  @include phone {
    left: 0px;
  }
}

// Background Image Toolbar --
.background-image-toolbar {
  top: 134px;
  position: absolute;
  background-color: #fff;
  border-radius: 100%;
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include box-shadow(0 0 12px rgba(0, 0, 0, 0.2));
}
.background-image-wrapper {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 100%;
  width: 47px;
  height: 47px;
}
.background-image-dropdown {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.background-image-button {
  color: black;
  border: none;
  cursor: pointer;
  height: 24px;

  i {
    color: #33bdd8;
    font-size: 24px;
  }
}
.background-image-dropdown-content {
  display: none;
  flex-wrap: wrap;
  justify-content: left;
  background-color: #ffffff;
  @include box-shadow(0 0 10px rgba(0, 0, 0, 0.2));
  width: 184px;
  position: absolute;
  left: 60px;
  top: 0;
  border-radius: 4px;

  &:before {
    font-family: FontAwesome;
    display: flex;
    vertical-align: middle;
    position: absolute;
    justify-content: center;
    align-items: center;
    content: "\f0d9";
    color: white;
    left: -6px;
    top: 12px;
    font-size: 15px;
  }
}
.background-image-dropdown-content div {
  color: black;
  padding: 12px 0px;
  text-decoration: none;
  display: flex;
  font-size: 10px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  width: 46px;
  justify-content: center;
  align-items: center;
  height: 46px;

  img {
    height: 23px;
  }
}
.background-image-dropdown-content div:hover,
.background-image-dropdown-content div:focus {
  border: 2px solid #33bdd8;
}
.background-image-select {
  border: 2px solid #33bdd8 !important;
}
// -- Background Image Toolbar

.start-present-info {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: coral;
  color: white;
  padding: 3px 10px;
  line-height: 1.3;
  font-size: 14px;
  @include border-radius(3px);
  @include box-shadow(1px 1px 5px rgba(0, 0, 0, 0.35));
}
// Present buttons
.present-buttons {
  justify-content: center;
  width: 146px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 10px;
  bottom: 19px;
  left: 45%;
  position: fixed;
  display: flex;
}
.present-arrows {
  // right: 70px;
  // position: absolute;
  // display: flex;
  a {
    display: inline-block;
    width: 30px;
    height: 31px;
    text-align: center;
    color: white !important;
    margin-right: 10px;
    padding: 5px 8px;
    background-color: cornflowerblue;
    &[disabled] {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.show-before-present {
  height: 31px;
  background-color: #fff;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  @include box-shadow(0 0 12px rgba(0, 0, 0, 0.2));

  i {
    font-size: 16px;
  }

  &:hover {
    cursor: pointer;
  }

  &.start-present {
    &:hover {
      background-color: #59d46b;
      i {
        color: #ffffff;
      }
    }
  }

  &.before-present-start {
    &:hover {
      background-color: #59d46b;
      i {
        color: #ffffff;
      }
    }
  }
  &.before-present-cancel {
    background-color: #ffffff;
    color: black;
    height: 31px;
    &:hover {
      background-color: red;
      i {
        color: #ffffff;
      }
    }
  }
}

// general tollbox dropdown show for toolbox item
.toolbox-show {
  display: flex !important;
}

// general border for toolbox item
.toolbox-item-border {
  border: 2px solid #33bdd8;
}

// New Toolbox Wrapper Styles ---
.new-toolbox {
  &-wrapper {
    position: fixed;
    top: 240px;
    left: 16px;
    width: 252px;
    height: 170px;
    background-color: #fff;
    padding: 16px;
    z-index: 9999;
    @include border-radius(8px);
    @include box-shadow(0 0 12px 0 rgba(0, 0, 0, 0.12));

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 19px solid white;
      position: absolute;
      top: -17px;
      left: calc(50% - 11px);
    }

    .line {
      height: 1px;
      background-color: #e3e3e3;
    }
  }

  &-top {
    padding-bottom: 16.5px;
  }
  &-text-top-items {
    display: flex;
    justify-content: space-between;
  }
  &-text-bottom-items {
    display: flex;
    justify-content: center;
    padding-top: 19px;

    &--left {
      padding-right: 5px;
    }

    &--left,
    &--right {
      width: 110px;
      display: flex;
      justify-content: space-between;
    }

    .style-button {
      width: 30px;
      height: 30px;
      color: #a3a9ad;

      &.active {
        color: #33bdd8;
      }

      &:hover {
        cursor: pointer;
      }

      i {
        font-size: 24px;
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
    padding-top: 15.5px;
  }
}
// --- New Toolbox Styles
// feedback display none
._hj_feedback_container {
  display: none !important;
}


.scrollable{
  margin-top: 1%;
  max-height: 340px; 
  overflow-y: auto;
}

.scrollable::-webkit-scrollbar {
  width: 10px;
}

.scrollable::-webkit-scrollbar-thumb {
  background: rgb(62,193,218);
  background: linear-gradient(0deg, rgba(62,193,218,1) 0%, rgba(184,213,218,1) 100%);
  border-radius: 5px; 
}

.table-container {
  font-size: 15px;
  margin-left: 10px;
  width: 95%;
  cursor: pointer;

  th {
    color: #33bdd8;
    font-weight: bold;
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }

  td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ccc;
  }
}

.line {
  height: 3px !important;
  width: 100%;
  background-color: #d1d1d1;
  margin-bottom: 15px;
}
