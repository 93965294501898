#menu {
  position: absolute;
  z-index: 23;
  border: 1px solid #bebebe;
  background-color: #fff;
}

#menu button {
  display: block;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 2px 6px;
  font-size: 13px;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-weight: 400;
  width: 200px;
  text-align: left;
}

#menu button:hover {
  background-color: #eeeeee;
}

#menu span {
  display: block;
  border-top: 1px solid #bebebe;
}
