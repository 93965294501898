/* The switch - the box around the slider */

.form-check {
  .switch-ai-or-documents-container {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
    margin-left: -15px;
    margin-bottom: 15px;

    &__inner-container {
      position: relative;
      width: 450px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }
    .switch {
      position: relative;
      display: inline-block;
      width: 60px;
      height: 34px;
      margin-left: 12px;
      margin-top: 10px;
      margin-bottom: 0;
    }

    /* Hide default HTML checkbox */
    .switch input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    /* The slider */
    .slider {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #ccc;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    .slider:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }

    input:checked + .slider {
      background-color: #2196f3;
    }

    input:focus + .slider {
      box-shadow: 0 0 1px #2196f3;
    }

    input:checked + .slider:before {
      -webkit-transform: translateX(26px);
      -ms-transform: translateX(26px);
      transform: translateX(26px);
    }

    /* Rounded sliders */
    .slider.round {
      border-radius: 34px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
  }
}
.document-list {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 95% !important;
  padding: 10px 20px;
  height: 300px;
  overflow: auto;
  background-color: #ccc;
}

// Temel stiller
.custom-select-document {
  width: 85% !important;
  right: -15px;
  top: 8px;
  position: absolute;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 15px !important;
  border-color: #2196f3 !important;
}

// Hover durumu
.custom-select:hover {
  border-color: #aaa;
}

// Seçili durum
.custom-select:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

// Seçenekler
.custom-select option {
  padding: 8px 12px;
}

.create-mind-map-ai-passive {
  background-color: rgb(158, 215, 226) !important;
  cursor: not-allowed !important;
}
