// this block added for map preview wrapper
#viewerDiv {
  display: none;
  width: 170px;
  height: 120px;
  position: fixed;
  left: 150px;
  bottom: 25px;
  color: black;
  font-size: 15px;
  z-index: 23;
  -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  border-radius: 0 2px 2px 0;
  background: #fff;
  -webkit-transition: -webkit-transform 0.7s ease 0.1s;
  transition: -webkit-transform 0.7s ease 0.1s;
  transition: transform 0.7s ease 0.1s;
  transition: transform 0.7s ease 0.1s, -webkit-transform 0.7s ease 0.1s;

  #port {
    width: 170px !important;
    height: 120px !important;
  }
}

.__react_modal_image__header {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

// subscription history list table
.subscription-history {
  .table-list {
    margin-top: 10px;
  }

  .ReactTable {
    .rt-th {
      border-right: 1px solid #e3e3e3 !important;

      &.rt-resizable-header {
        height: 33px;
        line-height: 23px;
        background-color: #f5f5f5;
        text-align: left;
        padding-left: 16px;

        &:nth-of-type(1) {
          flex: 380 0 auto !important;
          width: 380px !important;
          max-width: 380px !important;
        }

        &:nth-of-type(2) {
          flex: 380 0 auto !important;
          width: 380px !important;
          max-width: 380px !important;
        }

        &:last-child {
          border-right: 0 !important;
          flex: 140 0 auto !important;
          width: 140px !important;
          max-width: unset !important;
        }
      }
    }

    .rt-td {
      border-right: 1px solid #e3e3e3 !important;
      text-align: left !important;
      padding-left: 16px;

      &:last-child {
        max-width: 55px !important;
        padding-left: 5px;
        border-right: 0 !important;
      }

      &.action-date-column {
        color: #393939;
        flex: 380 0 auto !important;
        width: 380px !important;
        max-width: 380px !important;
      }

      &.subscription-date-column {
        color: #393939;
        flex: 380 0 auto !important;
        width: 380px !important;
        max-width: 380px !important;
      }

      &.total-column {
        color: #33bdd8;
        font-weight: bold;
        flex: 140 0 auto !important;
        width: 140px !important;
        max-width: unset !important;
        padding-left: 16px;
      }
    }
  }
}

/* Map list table */
.map-table {
  .ReactTable {
    border-left: 0;
    border-right: 0;
    font-size: 14px;
    color: #393939;

    &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
      background-color: transparent !important;
    }

    .rt-thead {
      &.-header {
        box-shadow: none;
      }

      &.-headerGroups {
        display: none;
      }

      &.-cursor-pointer {
        background-color: #f5f5f5;
      }

      &.-filters {
        display: none;
        border: none;
        position: relative;
        top: -34px;
        // left: 330px;
        // width: 300px;
        width: 100% !important;
        z-index: 99;
        min-width: 300px;
        max-width: 55px;
        margin-left: 300px;

        &.show {
          display: block;
        }

        input {
          border-radius: 20px !important;
          height: 30px;
          width: 300px !important;
          border: solid 1px #bcb5b5;
          @include border-radius(0);
          margin-right: -80px;
          margin-top: 2px;

          &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-size: 14px;
            color: #a3a9ad;
            font-style: italic;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #a3a9ad;
          }

          &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #a3a9ad;
          }
        }

        .rt-tr {
          width: 100%;
          text-align: left !important;
        }

        .rt-th {
          border-right: none !important;
          padding: 0;
          width: 100% !important;
        }

        .rt-th:not(:first-of-type) {
          display: none;
        }
      }
    }

    .rt-th {
      border-right: 1px solid #e3e3e3 !important;

      &.rt-resizable-header {
        height: 33px;
        line-height: 23px;
        background-color: #f5f5f5;
        text-align: left;
        padding-left: 16px;

        &:last-child {
          border-right: 0 !important;
          flex: 86 0 auto !important;
          width: 86px !important;
          max-width: 86px !important;
        }

        &:nth-of-type(2) {
          flex: 170 0 auto !important;
          width: 170px !important;
          max-width: 170px !important;
        }
      }

      .rt-resizable-header-content {
        display: inline-block;
        position: relative;
        width: 100%;

        .title-search-wrap {
          display: flex;

          span {
            flex: 1;
          }
        }

        &:after {
          content: "";
          position: absolute;
          right: 7%;
          top: 9px;
          width: 0;
          height: 0;
        }
      }

      &.-sort-desc {
        -webkit-box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.6);
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.6) !important;

        .rt-resizable-header-content {
          &:after {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-bottom: 4px solid #a3a9ad;
          }
        }
      }

      &.-sort-asc {
        -webkit-box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.6);
        box-shadow: inset 0 1px 0 0 rgba(0, 0, 0, 0.6) !important;

        .rt-resizable-header-content {
          &:after {
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 4px solid #a3a9ad;
          }
        }
      }
    }

    .rt-tr {
      &.-odd {
        background-color: transparent !important;
      }
    }

    .rt-td {
      border-right: 1px solid #e3e3e3 !important;
      text-align: left !important;
      padding-left: 16px;

      &:last-child {
        max-width: 55px !important;
        padding-left: 5px;
        border-right: 0 !important;
      }

      &.date-column {
        color: #a3a9ad;
        flex: 170 0 auto !important;
        width: 170px !important;
        max-width: 170px !important;
      }

      &.action-column {
        flex: 86 0 auto !important;
        width: 86px !important;
        max-width: 86px !important;
        display: flex;
        justify-content: center;
      }

      &.name-column {
        padding-right: 40px;
        position: relative;

        .name-fav-wrapper {
          display: flex;
          align-items: center;

          &__item-name {
            a {
              font-weight: 600;

              &:hover {
                cursor: pointer;
                color: #33bdd8;
              }
            }
          }

          &__item-name,
          div[contenteditable] {
            max-width: calc(626px - 25px);
            overflow: hidden;
            display: flex;
          }

          .shared-user-name {
            margin-right: 5px;
            font-size: 11px;
            font-style: italic;
            position: absolute;
            right: 40px;
            top: 10px;
          }

          .copied-user-name {
            margin-right: 5px;
            font-size: 11px;
            font-style: italic;
            position: absolute;
            right: 60px;
            top: 10px;

            .copy-icon {
              font-family: "fontAwesome";
              font-size: 14px;
              padding-right: 5px;
              color: #41c1db;
              position: relative;
              top: 2px;
            }
          }
        }

        img {
          height: 18px;
          margin-left: 8px;
        }

        .copy-icon {
          font-family: "fontAwesome";
          font-size: 13px;
          margin-left: 5px;
          color: #65d4eb;
        }

        a.fav-action {
          width: 30px;
          height: 34px;
          position: absolute;
          right: 7px;
          top: 0;
          line-height: 42px;
          text-align: center;
          display: inline-block;
          z-index: 10;
          color: #e3e3e3;

          i {
            font-size: 18px;
            padding: 0;
          }

          &.public-private-action {
            right: 35px;

            i {
              font-family: "fontAwesome";
            }

            &.active {
              color: #33bdd8 !important;

              &:hover {
                color: #33bdd8 !important;
                cursor: pointer;
              }
            }
          }

          &.ai-icons {
            right: 70px;


            i {
              font-family: "fontAwesome";
            }

            &.active {
              color: #33bdd8 !important;

              &:hover {
                color: #33bdd8 !important;
                cursor: pointer;
              }
            }
          }


          .chatgpt-icon {
            width: 20px; 
            height: 20px;
            margin-bottom: 5px;
          }
  
          &:hover {
            cursor: default;
            color: #e3e3e3;
          }

          &.active {
            color: #fd7021 !important;

            &:hover {
              color: #fd7021 !important;
            }
          }
        }
      }
    }
    .rt-tbody {
      min-width: 800px !important;
      overflow: initial;

      &.show-filter-pos {
        position: relative;
        top: -34px;
        margin-bottom: -34px;
      }

      .rt-tr-group {
        border-bottom: 1px solid #e3e3e3 !important;
      }
    }

    .-pagination {
      padding: 2px;
      border-top-width: 0;

      .-next,
      .-previous {
        @media screen and (min-width: 768px) and (max-width: 1024px) {
          max-width: 110px;
        }
      }

      .-center {
        height: 30px;
        font-size: 0.85em;
      }

      .-pageSizeOptions,
      .-pageJump {
        position: relative;
        top: -3px;
      }

      .-btn {
        height: 30px;
        font-size: 0.9em;
      }
    }
  }
}



.subscription-map-table .ReactTable .rt-th.rt-resizable-header:nth-of-type(2) {
  max-width: 380px !important;
  }

.subscription-map-table .ReactTable .rt-th.rt-resizable-header:last-child {
  max-width: 253px !important;
}

.infos {

  .ReactTable .rt-thead .rt-th.-cursor-pointer,
  .ReactTable .rt-thead .rt-td.-cursor-pointer {
    cursor: pointer;
    font-weight: bold;
    text-align: left;
    color: #33BDD8
  }
}

/* Map list table */

.login-form,
.register-form,
.forgotpassword-form,
.new-password-form {
  * {
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  }

  .form-wrapper {
    // margin-top: 10%;

    @include ie {
      width: 35%;
    }

    @include phone {
      margin-top: 30px;
      width: 290px;
    }
  }

  .form-logo {
    margin-bottom: 30px;

    img {
      height: 66px;
      width: auto;
    }
  }

  .form-group {
    &.with-icon {
      position: relative;

      img.left-icon {
        position: absolute;
        left: 16px;
        top: 16px;
      }

      img.right-icon {
        position: absolute;
        right: 16px;
        top: 18px;

        &:hover {
          cursor: pointer;
        }
      }
    }

    &.remeber-forgot-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  input {
    width: 540px;
    height: 56px;
    font-size: 16px;
    line-height: 20px;
    color: $color-light-black !important;
    border: 0;
    border-radius: 28px;
    background-color: $color-login-input !important;
    padding-left: 52px;
    padding-right: 35px;
    @include transition(all 0.15s ease-in-out);

    @include phone {
      width: 100%;
    }
  }

  input[type="checkbox"] {
    width: 10px;
    height: 10px;
  }

  input:focus {
    @include box-shadow(inset 0px 5px 5px rgba(0, 0, 0, 0.1));
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $color-light-black !important;
    opacity: 1;
    /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-light-black !important;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $color-light-black !important;
  }

  .forgot-pass-btn {
    min-height: auto !important;
    padding: 0;
    color: $color-light-black;
    font-weight: bold !important;
    font-size: 14px !important;
    text-decoration: underline;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;

    &:hover {
      text-decoration: underline;
    }

    @include phone {
      font-size: 13px !important;
    }
  }

  .login-custom-btn {
    width: 100%;
    background-color: $color-selective-yellow-v2;
    height: 56px;
    font-size: 16px !important;
    font-weight: bold !important;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;

    &:hover {
      background-color: $color-login-btn-hover;
    }

    &:active,
    &:focus {
      background-color: $color-login-btn-active;
    }
  }

  .create-account-btn {
    width: 100%;
    display: block;
    min-height: auto !important;
    padding: 0;
    color: $color-light-black;
    font-weight: bold !important;
    font-size: 14px !important;
    text-decoration: underline;
    text-align: center;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;

    &:hover {
      text-decoration: underline;
    }
  }

  .login-social-media {
    transition: all 0.15s ease-in-out !important;
    color: white !important;
    border: 0 !important;
    padding: 2px 30px !important;
    width: 100%;
    height: 56px;
    font-size: 16px;
    border-radius: 28px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-weight: bold;
    box-shadow: none !important;
    background-color: transparent !important;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;

    &.google-btn {
      background-color: $color-login-input !important;
      color: $color-light-black !important;
      &:hover {
        background-color: $color-google-btn-hover !important;
      }
      &:active,
      &:focus {
        background-color: $color-google-btn-active !important;
      }
    }

    &.facebook-btn {
      background-color: $color-facebook-btn !important;
      color: white !important;

      i {
        font-size: 24px;
        margin-right: 16px;
      }

      &:hover {
        background-color: $color-facebook-btn-hover !important;
      }

      &:active,
      &:focus {
        background-color: $color-facebook-btn-active !important;
      }
    }

    span {
      font-weight: bold !important;
    }

    div {
      background: transparent !important;
    }
  }
}

select:focus {
  outline: 0;
}

.button {
  @include transition(all 0.15s ease-in-out);
  color: $color-white;
  border: 0;
  font: 500 0.8rem "Raleway", sans-serif !important;
  padding: 12px 30px;
  min-width: 130px;
  min-height: 49px;
  border-radius: 80px;
  cursor: pointer;

  @include phone {
    min-width: 90px;
  }
}

.button:hover {
  text-decoration: none;
}

.link-button {
  padding: 12px 0;
  min-width: 0px;
}

.aqua-button {
  color: $color-cyan-aqua;
}

.aqua-button:hover {
  color: $color-cyan-aqua-08;
}

.silver-gray-button {
  color: $color-silver-gray;
}

.silver-gray-button:hover {
  color: $color-silver-gray;
}

.cerulean-button {
  color: $color-cerulean;
}

.cerulean-button:hover {
  color: $color-cerulean;
}

.green-button {
  background-color: $color-green;
}

.fresh-button {
  background-color: $color-pass-french;
}

.fresh-button:hover {
  background-color: $color-pass-french-08;
}

.green-button:hover {
  background-color: $color-green-08;
}

.orange-button {
  background-color: $color-blaze-orange;
}

.orange-button:hover {
  background-color: $color-blaze-orange-08;
}

.red-button {
  background-color: $color-red;
}

r .red-button:hover {
  background-color: $color-red-08;
}

.blue-button {
  background-color: $color-cyan-aqua-v2;
}

.blue-button:hover {
  background-color: $color-cyan-aqua-v2-08;
}

.yellow-button {
  background-color: $color-selective-yellow;
}

.yellow-button:hover {
  background-color: $color-selective-yellow-08;
}

.red-light-button {
  background-color: $color-red-color !important;
}

.red-light-button:hover {
  background-color: $color-red-color-08 !important;
}

label.error {
  color: $color-mona-lisa;
  font-size: $font-size-default;
  display: block;
  margin-top: 5px;
}

.loading-screen {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  background-color: $color-black;
  background-image: url(../img/pageloader.gif);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50px;
  @include transition(all 0.3s ease-in-out);
  @include opacity(0);
  z-index: 999999;
}

.loading-screen.show {
  @include opacity(0.8);
}

.loading-screen-gpt {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  background-color: $color-black;
  background-image: url(../img/map.gif);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 150px;
  @include transition(all 0.3s ease-in-out);
  @include opacity(0);
  z-index: 999999;
}

.loading-screen-gpt.show {
  @include opacity(0.8);
}

.modalm {
  left: 0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9001;
  background: transparent;
  width: 40%;
  margin: 0 auto;

  @include phone {
    margin: 0 auto;
    z-index: 9001;
    width: 90%;
  }
}

.modalm .wrapper {
  background-color: $color-white;
  padding: 20px 10px;
  -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

.modalm .exit-button {
  position: absolute;
  right: 12px;
  top: 8px;
  font-size: 20px;
  color: #666;
  cursor: pointer;

  @include phone {
    position: absolute;
    right: 11px;
    top: 7px;
    font-size: 20px;
    color: #666;
    cursor: pointer;
    z-index: 99999;
  }
}

.modalm .wrapper .header {
  background: none;
}

.modalm .wrapper .section {
  margin: 30px 0 25px 0;
  text-align: center;
}

.modalm .wrapper .section p {
  color: #333333;
}

.modalm .wrapper .header .title {
  margin-bottom: 15px;
  text-align: center;
  font-size: 22px;
  font-weight: 400;
}

.modalm .wrapper .footer {
  text-align: center;
  padding-top: 10px;

  .button {
    color: $color-white !important;
  }

  .button+.button {
    margin-left: 20px;
  }
}

.modalm-overlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  background-color: #ffffff;
  z-index: 9000;
}

.fadeOut {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  visibility: hidden;
}

.fadeIn {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  visibility: visible;
}

.fadeInHalf {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
  -moz-opacity: 0.6;
  -khtml-opacity: 0.6;
  opacity: 0.6;
  visibility: visible;
}

.smooth {
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
  // -webkit-backface-visibility: hidden;
}

.file-buttons {
  display: inline-block;
  width: 100%;
  margin: 15px auto;
  text-align: center;

  li {
    display: inline-block;
    margin-right: 5%;

    a {
      cursor: pointer;
      background-color: $color-picton-blue;
      @include border-radius(20px);
      padding: 10px 15px;
      color: $color-white !important;
      font-size: 13px;

      i {
        color: $color-white;
        font-size: 16px;
        margin-right: 5px;
      }

      i.fa-pencil {
        font-size: 17px;
      }
    }
  }

  li:last-of-type {
    margin-right: 0;
  }
}

.ui {
  z-index: 25;

  .toolbox-option {
    margin-bottom: 25px;

    label {
      color: $color-silver-chalice;
      font-size: 13px;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      font-weight: 500;
      display: block;

      small {
        font-size: 82%;
        font-weight: 500;
        color: $color-tundora;
      }
    }

    select {
      width: 100%;
      border: 0;
      background-color: $color-concrete;
      padding: 10px;
      font-size: 13px;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      font-weight: 500;
      color: $color-tundora;

      option {
        font-size: 13px;
        font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
        font-weight: 500;
      }
    }

    .icon-select {
      font-family: fontAwesome, sans-serif;
      font-size: 13px !important;

      option {
        font-family: fontAwesome, sans-serif;
        font-size: 13px !important;
      }
    }

    .jscolor {
      width: 100%;
      border-radius: 0;
      border: 1px solid $color-alto;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      text-indent: -999999px;
      cursor: default;
      height: 18px;
    }

    .color-apply-button {
      border: 1px solid $color-alto !important;
      font-size: 11px;
      width: 62px !important;
      margin: 0 !important;
      color: $color-gray;
    }
  }

  .map-name {
    text-align: center;
    // margin-bottom: 20px;
    color: $color-tundora;
    font-size: 20px;
  }

  .help-button {
    width: 36px !important;
    height: 36px;
    color: $color-white;
    font-size: 23px;
    border-radius: 20px;
    background: #fcd04d !important;
  }
}

.toogle-button.visible {
  right: 294px;
}

.toogle-button {
  position: fixed;
  right: -5px;
  top: 50%;
  padding: 0 !important;
  font-size: 26px !important;
  color: $color-dusty-gray;
  background: none;
  @include transition(all 0.5s);
  z-index: 28 !important;
}

.help-button {
  position: fixed;
  right: 300px;
  top: 95px;
  width: 30px !important;
  height: 30px;
  max-width: 30px !important;
  padding: 0 !important;
  color: $color-white;
  font-size: 20px;
  border-radius: 20px;
  background: #fcd04d;
  @include transition(all 0.5s);
  z-index: 11;
}

.help-button.toolbox-closed {
  position: relative;
  bottom: 157px;
  line-height: 33px;
  left: 16px;
  top: auto;
  // @include box-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.table-list {
  .action-button {
    i {
      font-family: fontAwesome;
    }
  }
}

.profile-panel {
  margin: 0 auto;
  padding: 30px 0;

  >.row {
    width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin: 50px 0px;
  }
}

.dashboard-panel {
  background-color: $color-white;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 25px;
  // position: absolute;
  margin: 30px 0px;
  border-radius: 25px;
  // height: 30rem;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin: 50px 0px;
  }

  @include phone {
    padding-top: 40px;
  }

  @include tablet {
    padding: 25px 30px;
  }

  .sub-bar {
    display: flex;
    align-items: center;
    @include border-radius(40px);
    color: $color-blue-shine;
    position: relative;

    .circle-icon {
      color: $color-yellow-shine;
    }

    .sitemap-icon {
      color: $color-white;
    }

    i {
      @include desktop {
        margin-left: 0;
      }
    }

    .title {
      color: $color-blue-shine;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      font-size: 22px;
      font-weight: bold;
      line-height: 64px;
      display: inline-block;
      margin-left: 8px;

      @include tablet {
        font-size: 18px;
      }
    }

    // .fa-stack { font-size: 1.5em; }
    // span{
    //     position: absolute;
    //     left: 90px;
    //     top: 9px;
    //     font-family:  Century Gothic,CenturyGothic,AppleGothic,sans-serif;
    //     font-size: 20px;
    //     font-weight: 300;
    //     @include tablet{
    //         left: 14px;
    //         top: 11px;
    //         font-size: 18px;
    //     }

    //     @include desktop{
    //         left: 93px;
    //         top: 9px;
    //         font-size: 19px;
    //     }
    // }
  }

  .info,
  .comming-soon {
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
    font-size: 13px;
    color: $color-silver;
    margin: 35px 0 15px 0;
    display: inline-block;

    @include tablet {
      margin-top: 20px;
    }
  }

  .comming-soon {
    font-size: 15px;
  }

  .mind-map-board {
    .item-list {
      .new-map-button {
        border: 1px solid $color-silver-gray;
        height: 36px;
        width: 100%;
        border-radius: 60px;
        background-color: transparent;
        margin-top: 10px;
        @include transition(all 0.3s ease-in-out);

        i {
          color: $color-silver-gray;
          @include transition(all 0.3s ease-in-out);
        }
      }

      .new-map-button:hover {
        border-color: $color-dodger-blue-v2;

        i {
          color: $color-dodger-blue-v2;
        }
      }

      .new-map-input-name {
        font-family: FontAwesome, "Open Sans", Verdana, sans-serif;
        padding: 15px;
      }

      .new-map-input-name::placeholder {
        color: $color-silver-gray;
      }

      ul {
        li {
          display: inline-block;
          width: 100%;

          .item-name {
            font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
            font-size: 14px;
            color: $color-tundora;
            font-weight: 500;
            cursor: pointer;
          }

          .item-name-update-input {
            font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
            border: 1px solid $color-silver-gray;
            height: 43px;
            width: 100%;
            border-radius: 60px;
            background-color: transparent;
            color: $color-tundora;
            font-weight: 500;
            margin-bottom: 7px;
            margin-top: 10px;
            font-size: 15px;
            padding: 15px;
            // font-family:  Century Gothic,CenturyGothic,AppleGothic,sans-serif;
            // font-size: 15px;
            // color: $color-tundora;
            // font-weight: 500;
            // border: 0;
            // border-bottom: 1px solid;
            // margin-bottom: 7px;
            // width: 100%;
          }

          .buttons {
            i {
              cursor: pointer;
              color: $color-tundora;
              @include opacity(0.4);
              @include transition(all 0.3s ease-in-out);
            }

            i:first-of-type {
              margin-right: 12px;
            }

            .item-update-name {
              margin-right: 15px;
              position: relative;
              top: -40px;
            }

            .item-update-cancel {
              margin-right: 15px;
              position: relative;
              top: -40px;
            }
          }
        }

        li:hover {
          .buttons {
            i {
              @include opacity(1);
              color: $color-dodger-blue-v2;
            }
          }
        }
      }
    }
  }
}

.template-panel {
  background-color: $color-white;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 40px auto;

  @media screen and (min-width: 1025px) {
    max-width: 1040px;
    min-height: 500px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 680px;
  }

  .sub-template-panel {
    padding: 45px 20px 10px 20px;
  }

  .sub-bar {
    display: flex;
    align-items: center;
    @include border-radius(40px);
    color: $color-blue-shine;
    position: relative;

    .circle-icon {
      color: $color-yellow-shine;
      font-family: fontAwesome;
    }

    .sitemap-icon {
      color: $color-white;
      font-family: fontAwesome;
    }

    i {
      @include desktop {
        margin-left: 0;
      }
    }

    .sub-title {
      color: $color-blue-shine;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      font-size: 22px;
      font-weight: bold;
      margin-left: 8px;

      @include tablet {
        font-size: 18px;
      }
    }
  }

  .title {
    height: 24px;
    display: flex;
    align-items: center;

    span {
      margin: 8px 8px 8px 34px;
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      font-size: 20px;
      font-weight: bold;
      color: #3e3e3e;

      @include phone {
        font-size: 20px;
        margin: 11px 0px 0px 6px !important;
      }
    }
  }

  .mind-map-board2 {
    min-width: max-content;
    padding: 40px 0px;

    @include phone {
      font-size: 15px;
      margin: 0px -16px 0px -16px;
    }

    .title {
      i {
        color: #33bdd8;
        font-size: 24px;
        padding: 0;
      }

      span {
        @include phone {
          font-size: 15px;
          margin: 11px 0px 0px 6px;
        }
      }
    }
  }



  .file-box {
    padding: 0 15px;
    max-height: 540px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #e3e3e3;
      border-radius: 3px;
    }

    .new-map-wrapper {
      margin: 0px 10px;
    }

    .text {
      font-size: 15px;
      font-weight: 500;
    }

    input.new-map {
      font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
      border: 1px solid #cccccc;
      height: 43px;
      width: 100%;
      border-radius: 60px;
      background-color: transparent;
      color: #454545;
      font-weight: 500;
      margin-bottom: 7px;
      margin-top: 10px;
      font-size: 15px;
      padding: 15px;
    }

    input.new-map::placeholder {
      @include opacity(0.4);
    }
  }

  .item-name {
    font-size: 15px;
    text-align: center;
    color: $color-blue-shine;
    font-size: 14px;
    font-weight: bold;
    margin-top: 8px;
    margin-bottom: 20px;
  }

  .text {
    margin-bottom: 15px;
    color: $color-black;
  }
}

.gpt-image {
  height: 70px !important;
}

.gpt-modal {
  position: fixed;
  z-index: 12;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);

}

.modal-main {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  width: 40%;
  height: auto;
  border-radius: 22px;



  h1 {
    color: #33bdd8;
    font-weight: bold;
    font-size: 22px;
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    label {
      margin-right: auto;
      padding-left: 1em;
      color: #a3a9ad;
      font-weight: bold;
      margin-bottom: -6px;
    }


    button {
      border-radius: 19px;
      cursor: pointer;
      font-size: 12px;
      line-height: 38px;
      padding: 0 30px;
      position: relative;
      text-align: center;


      &:hover {
        background-color: #f7fafa;
      }

      &:focus {
        border-color: #008296;
        box-shadow: rgba(213, 217, 217, 0.5) 0 2px 5px 0;
        outline: 0;
      }
    }

    #gpt-subject {
      box-sizing: border-box;
      font-family: inherit;
      font-size: 14px;
      vertical-align: baseline;
      font-weight: 400;
      line-height: 1.29;
      letter-spacing: .16px;
      border-radius: 0;
      outline: 2px solid transparent;
      outline-offset: -2px;
      width: 95%;
      height: 110px;
      border: none;
      background-color: #f4f4f4;
      padding-left: 16px;
      padding-bottom: 10%;
      color: #767c80;
      transition: background-color 70ms cubic- bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9);
      border-radius: 8px;
      margin: 2%;

      &::placeholder {
        font-size: 14px;
        color: #a3a9ad;
      }
    }
  }
}

#save-gpt {
  background: #33BDD8;
  color: #ffffff;
  font-weight: bold;
}

#cancel-gpt {
  color: #33BDD8;
  background: #ffffff;
  font-weight: bold;
  border: 1px solid #33BDD8;
  margin-right: 13px;
}

.chat-gpt-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3%;

  img {
    margin-right: 10px;
    object-fit: contain;
    margin-bottom: auto;
  }

  h1 {
    margin-right: auto;
    margin-top: 6px;
    line-height: unset;
    font-size: 23px;
  }

}

.form-check {
  color: #a3a9ad;
  margin-right: auto;

  h5 {
    font-size: 15px;
    font-weight: bold;
  }
}


.form-check-label {
  margin-bottom: 0;
  padding-left: 4px;
}

.language-options {
  display: flex;

  label {
    font-weight: lighter !important;
    font-size: 14px;
  }
}

.language-option {
  margin-right: 60px;
  padding-left: 3px;
}

.action-buttons {
  display: flex;
  margin-left: auto;
}

.template-item-list-wrapper {
  border: solid 1px #e3e3e3;
  border-radius: 4px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 120px;
  position: relative;

  &.for-template {
    &:hover {
      cursor: unset;
    }
  }

  .zoom-plus-icon {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    // top: calc(50% - 20px);
    color: #33bdd8;
    font-size: 45px;
    // height: 46px;
    padding: 0 !important;
    transition: visibility 0s, opacity 0.2s linear;

    &>div>img {
      position: relative;
      top: -15px;
    }
  }

  &:hover {
    border: solid 2px #33bdd8;
    cursor: pointer;

    .zoom-plus-icon {
      visibility: visible;
      opacity: 1;

      &:hover {
        cursor: pointer;
      }
    }
  }

  img {
    width: 100%;
    max-height: 85px;
  }

  // image modal style ----
  .__react_modal_image__header {
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .__react_modal_image__modal_content {
    &>div {
      height: inherit;
      max-height: 55%;
      background: white;
      width: 50%;
      position: relative;
      top: 22%;
      margin: 0 auto;
      padding: 40px 15px 40px 15px;
      object-fit: contain;
      border-radius: 22px;

      img {
        height: unset !important;
        max-height: 70%;
        // width: auto;
      }

      @media (max-width: 1024px) {
        // width: 85%;
      }
    }
  }

  .__react_modal_image__modal_container {
    background-color: rgba(0, 0, 0, 0.6);

    img {
      width: 60%;
      max-height: unset;
      background: white;
    }
  }

  // ---- image modal style

  .template-blank img {
    height: 24px;
    width: auto;
  }
}

.__react_modal_image__medium_img {
  height: unset !important;
}

.template-list-board {
  margin: 10px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 190px);
  justify-content: space-between;

  &.no-data {
    display: block;

    .no-template-info {
      text-align: center;
      font-size: 13px;
    }
  }

  .list-item-wrap {
    position: relative;

    .more-options-btn {
      position: absolute;
      right: 19px;
      top: 0;
      z-index: 10;
      display: block;

      &:hover {
        cursor: pointer;

        .options {
          display: block;
        }
      }

      .options {
        display: none;
        position: absolute;
        right: 0;
        padding: 8px 15px 8px 8px;
        border-radius: 5px;
        @include box-shadow(0 0 6px 0 rgba(0, 0, 0, 0.16));
        background-color: white;

        div {
          font-size: 10px;
          font-weight: bold;
          line-height: 1.22;
          color: #393939;

          &:not(:last-of-type) {
            margin-bottom: 10px;
          }
        }

        &.show {
          display: block;
        }
      }
    }

    .create-map-btn {
      position: absolute;
      // right: 16px;
      right: 0;
      top: 91px;

      img {
        height: 25px;
      }

      &:hover {
        cursor: pointer;
        @include box-shadow(0 0 6px rgba(0, 0, 0, 0.15));
        background-color: white;
        border-radius: 5px;
      }
    }

    .generated-map-count {
      height: 24px;
      text-align: center;
      border-radius: 5px;
      font-size: 10px;
      font-weight: 600;
      padding: 1px 2px;
      z-index: 2;
      color: #bab6b6;
      font-style: italic;
    }
  }

  .list-item {
    margin-bottom: 32px;
  }

  .item-name {
    color: #393939;
    font-size: 12px;
    line-height: 1.25;
    margin: 8px 0 0 0;
    height: 15px;
    max-height: 26px;
    word-break: break-all;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.item-image-click {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px;

  &.add-new-cat {
    flex-direction: column;
    width: 100%;
    height: 100%;

    img {
      height: 24px;
      width: 24px;
    }

    .item-name {
      font-size: 13px;
    }
  }
}

.back-to-template-list {
  color: #33bdd8;
  font-size: 13px;
  width: 105px;
  margin: auto;
  margin-left: 20px;

  &:hover {
    color: #33bdd8;
    text-decoration: underline;
  }

  @include phone {
    display: flex;
    margin: auto;
  }
}

.template-blank {
  cursor: pointer;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action-column {
  .action-button {
    i {
      cursor: pointer;
      font-size: 18px;
      color: #33bdd8;
      padding: 0 10px 0 0;
    }
  }
}

.my-planner-toolbox {
  position: fixed;
  right: 0;
  top: 78px;
  height: 100%;
  background-color: #fff;
  width: 450px;
  border-left: 2px solid #ddd;
  box-sizing: border-box;
  overflow-y: auto;
  transition: all 500ms;
  padding: 16px 0px 0px 16px;

  i {
    font-size: 23px;
    color: $color-alto;
    // padding: 0px 0px 0px 16px;
  }

  .myplanner-photo {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: inline-block;
    background-color: yellow;
  }

  .myplanner-select-box {
    width: 85%;
    height: 35px;
    border: none;
  }

  //************************************IMPORTANCE INPUT***************************************************//

  .importance-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .importance-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .importance-checkmark-yellow {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: inherit;
    border-radius: 100%;
    border: 3px solid $color-yellow-amber;
  }

  .importance-container input:checked~.importance-checkmark-yellow {
    background-color: $color-yellow-amber;
  }

  .importance-checkmark-yellow:after {
    content: "";
    position: absolute;
    display: none;
  }

  .importance-container input:checked~.importance-checkmark-yellow:after {
    display: block;
  }

  //       .importance-container .importance-checkmark-yellow:after {
  //         top: 9px;
  //        left: 9px;
  //        width: 8px;
  //        height: 8px;
  //        border-radius: 50%;
  //        background: white;
  //    }

  .importance-checkmark-orange {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: inherit;
    border-radius: 100%;
    border: 3px solid $color-pizazz;
  }

  .importance-container input:checked~.importance-checkmark-orange {
    background-color: $color-pizazz;
  }

  .importance-checkmark-orange:after {
    content: "";
    position: absolute;
    display: none;
  }

  .importance-container input:checked~.importance-checkmark-orange:after {
    display: block;
  }

  //   .importance-container .importance-checkmark-orange:after {
  //     top: 9px;
  //    left: 9px;
  //    width: 8px;
  //    height: 8px;
  //    border-radius: 50%;
  //    background: $color-pizazz;
  // }

  .importance-checkmark-red {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: inherit;
    border-radius: 100%;
    border: 3px solid $color-red;
  }

  .importance-container input:checked~.importance-checkmark-red {
    background-color: $color-red;
  }

  .importance-checkmark-red:after {
    content: "";
    position: absolute;
    display: none;
  }

  .importance-container input:checked~.importance-checkmark-red:after {
    display: block;
  }

  //   .importance-container .importance-checkmark-orange:after {
  //     top: 9px;
  //    left: 9px;
  //    width: 8px;
  //    height: 8px;
  //    border-radius: 50%;
  //    background: $color-pizazz;
  // }

  //************************************************************************************************************************//

  //**********************************************************URGENCY INPUT*************************************************//

  .urgency-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .urgency-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .urgency-checkmark-yellow {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: inherit;
    border-radius: 100%;
    border: 3px solid $color-yellow-amber;
  }

  .urgency-container input:checked~.urgency-checkmark-yellow {
    background-color: $color-yellow-amber;
  }

  .urgency-checkmark-yellow:after {
    content: "";
    position: absolute;
    display: none;
  }

  .urgency-container input:checked~.urgency-checkmark-yellow:after {
    display: block;
  }

  //       .urgency-container .urgency-checkmark-yellow:after {
  //         top: 9px;
  //        left: 9px;
  //        width: 8px;
  //        height: 8px;
  //        border-radius: 50%;
  //        background: white;
  //    }

  .urgency-checkmark-orange {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: inherit;
    border-radius: 100%;
    border: 3px solid $color-pizazz;
  }

  .urgency-container input:checked~.urgency-checkmark-orange {
    background-color: $color-pizazz;
  }

  .urgency-checkmark-orange:after {
    content: "";
    position: absolute;
    display: none;
  }

  .urgency-container input:checked~.urgency-checkmark-orange:after {
    display: block;
  }

  //   .urgency-container .urgency-checkmark-orange:after {
  //     top: 9px;
  //    left: 9px;
  //    width: 8px;
  //    height: 8px;
  //    border-radius: 50%;
  //    background: $color-pizazz;
  // }

  .urgency-checkmark-red {
    position: absolute;
    top: 0;
    left: 0;
    height: 28px;
    width: 28px;
    background-color: inherit;
    border-radius: 100%;
    border: 3px solid $color-red;
  }

  .urgency-container input:checked~.urgency-checkmark-red {
    background-color: $color-red;
  }

  .urgency-checkmark-red:after {
    content: "";
    position: absolute;
    display: none;
  }

  .urgency-container input:checked~.urgency-checkmark-red:after {
    display: block;
  }

  //   .urgency-container .urgency-checkmark-orange:after {
  //     top: 9px;
  //    left: 9px;
  //    width: 8px;
  //    height: 8px;
  //    border-radius: 50%;
  //    background: $color-pizazz;
  // }
  //************************************************************************************************************************//

  //************************************MYPLANNER INPUT***************************************************//

  .myplanner-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .myplanner-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .myplanner-checkmark-now {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 58px;
    background-color: inherit;
    border-radius: 13px;
    border: 1px solid $color-mercury;
    font-size: 13px;
    padding: 3px 0px 0px 13px;
  }

  .myplanner-container input:checked~.myplanner-checkmark-now {
    background-color: $color-mercury;
  }

  .myplanner-checkmark-now:after {
    content: "";
    position: absolute;
    display: none;
  }

  .myplanner-container input:checked~.myplanner-checkmark-now:after {
    display: block;
  }

  //       .myplanner-container .myplanner-checkmark-now:after {
  //         top: 9px;
  //        left: 9px;
  //        width: 8px;
  //        height: 8px;
  //        border-radius: 50%;
  //        background: white;
  //    }

  .myplanner-checkmark-next {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 58px;
    background-color: inherit;
    border-radius: 13px;
    border: 1px solid $color-mercury;
    font-size: 13px;
    padding: 3px 0px 0px 13px;
  }

  .myplanner-container input:checked~.myplanner-checkmark-next {
    background-color: $color-mercury;
  }

  .myplanner-checkmark-next:after {
    content: "";
    position: absolute;
    display: none;
  }

  .myplanner-container input:checked~.myplanner-checkmark-next:after {
    display: block;
  }

  //   .myplanner-container .myplanner-checkmark-next:after {
  //     top: 9px;
  //    left: 9px;
  //    width: 8px;
  //    height: 8px;
  //    border-radius: 50%;
  //    background: $color-pizazz;
  // }

  .myplanner-checkmark-soon {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 58px;
    background-color: inherit;
    border-radius: 13px;
    border: 1px solid $color-mercury;
    font-size: 13px;
    padding: 3px 0px 0px 13px;
  }

  .myplanner-container input:checked~.myplanner-checkmark-soon {
    background-color: $color-mercury;
  }

  .myplanner-checkmark-soon:after {
    content: "";
    position: absolute;
    display: none;
  }

  .myplanner-container input:checked~.myplanner-checkmark-soon:after {
    display: block;
  }

  //   .myplanner-container .myplanner-checkmark-nex:after {
  //     top: 9px;
  //    left: 9px;
  //    width: 8px;
  //    height: 8px;
  //    border-radius: 50%;
  //    background: $color-pizazz;
  // }

  //************************************************************************************************************************//

  .calendar-title {
    font-size: 13;
    font-weight: bold;
    margin-left: 5px;
  }

  .remindMe-title {
    margin-right: 22px;
    font-size: 13;
  }

  .days-title {
    margin: 22px;
    font-size: 13;
  }

  .send-email {
    font-size: 13;
    margin-left: 5px;
  }

  .history-title {
    font-weight: 500;
    font-size: 13;
  }
}

.myplanner-dropdown-item {
  position: fixed;
  // right: -5px;
  right: 19px;
  top: 50%;
  padding: 0 !important;
  font-size: 26px !important;
  color: $color-dusty-gray !important;
  background: none;
  transition: all 500ms;

  .rotate {
    transform: rotate(180deg);
    position: fixed;
    right: 450px;
    top: 50%;
  }
}

.open-close {
  width: 0;
}

.close {
  opacity: unset;
  margin-bottom: auto;
}

.range-box {
  background: rgba(255, 255, 255, 0.8);
  height: 12px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.noUi-target {
  height: 9px;
  border: none;
  // margin: 60px 0 0 0;
  width: 200px;
}

@media screen and (max-width: 767px) {
  .noUi-target {
    margin: 40px 0 50px 0;
  }
}

.noUi-target .noUi-base {
  // background: $color-heliotrope;
  border: none;
  border-radius: 15px;
  cursor: pointer;
}

.noUi-target .noUi-base .noUi-connect {
  background: $color-heliotrope;
}

.noUi-target .noUi-base .noUi-tooltip {
  bottom: -35px;
  color: $color-heliotrope;
  border: none;
  outline: none;
  font-weight: bold;
}

.noUi-handle.noUi-handle-lower {
  background: $color-heliotrope;
  border-radius: 20px;
  width: 25px;
  height: 25px;
  outline: none;
  right: 0px;
  top: -8px;
  cursor: pointer;
  box-shadow: none;
  border: 3px solid #ffffff;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.noUi-handle.noUi-handle-lower.noUi-active {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}

.noUi-handle.noUi-handle-lower::before,
.noUi-handle.noUi-handle-lower::after {
  display: none;
}

.noUi-handle.noUi-handle-lower .noUi-touch-area {
  width: 30px;
  height: 30px;
}

.item.root {
  transform: scale(0.9, 0.9);

  // for transiton effect on present
  &.present-mode-root {
    transition: top 1s, left 1s;
  }

  >.order-value-wrapper {
    opacity: .6;

    input {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

  >.options {

    .fa.add-child.left,
    .fa.add-child.right {
      display: block;
    }

    .fa.add-child.left {
      left: -8px;
    }

    .fa.add-child.right {
      right: -8px;
    }
  }
}

li.item {
  cursor: pointer;

  .content {
    font-family: cursive, sans-serif;

    &.only {
      position: relative;

      padding: 0;
      min-width: 100px;
      min-height: 100px;

      &:hover {
        .get-item-format-btn {
          display: none;
        }
      }

      .bg-color {
        &.only {
          left: 0 !important;
          top: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
        }
      }
    }

    .get-item-format-btn {
      display: none;
      position: absolute;
      left: calc(50% - 10px);
      top: 2px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      border-radius: 100%;
      border-width: 2px;
      border-style: solid;
      background-color: #ffffff;
      width: 20px;
      height: 20px;
      font-size: 10px;

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .select-item-for-present {
    height: 32px;
    width: auto;
    position: absolute;
    top: -32px;
    left: calc(50% - 15px);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;

    .select-item-btn {
      padding: 1px 6px;
      font-size: 13px;
      line-height: 2;
      background-color: red;
      color: white;
      @include box-shadow(1px 1px 15px 0 rgba(0, 0, 0, 0.4));
      @include border-radius(50%);
    }

    .select-item-id-text {
      // display: none;
      font-weight: bold;
    }
  }

  .order-value-wrapper {
    height: 32px;
    width: 34px;
    position: absolute;
    top: -13px;
    left: calc(50% - 17px);
    z-index: 100;
    background-color: red;
    @include box-shadow(1px 1px 15px 0 rgba(0, 0, 0, 0.4));
    @include border-radius(6px);

    input {
      height: 32px;
      width: 34px;
      font-size: 15px;
      background: transparent;
      border: none;
      color: white;
      font-weight: bold;
      text-align: center;
    }
  }

  // image butonlairi baslangicta hepsinde gizleniyor
  &>.img-buttons {
    display: none;
  }

  // .content.only + .options {

  // image olanlarda toolbox i gizlemek icin
  // &.image-item,
  // &.image-item-box {
  //   .options {
  //     .show-hide-toolbox {
  //       display: none;
  //     }
  //   }
  // }

  // &.non-background:not(.image-item-box), // text item ama image box degilse
  // &:not(.non-background):not(.image-item-box) { // text item ve image box degilse
  //   > .options {
  //     .show-hide-toolbox {
  //       display: block;
  //     }
  //   }
  // }

  // root a image bg eklendiyse
  &.root[data-child-direction="right"] {
    .remove-img-btn {
      top: calc(50% - 60px);
      right: auto;
      left: auto;
    }

    .upload-img-btn {
      top: calc(50% - 40px);
      right: auto;
      left: auto;
    }

    .size-down-btn {
      top: calc(50% - -20px);
      right: -9px;
      left: auto;
    }

    .size-up-btn {
      top: calc(50% - -40px);
      right: -9px;
      left: auto;
    }
  }

  // root degilse
  &:not(.root)[data-child-direction="right"] {
    >.img-buttons {
      position: absolute;
      right: 0;
      left: 0;
      top: calc(50%);

      .remove-img-btn {
        top: calc(50% - 50px);
        left: -9px;
      }

      .upload-img-btn {
        top: calc(50% - 25px);
        left: -9px;
      }

      .size-down-btn {
        top: calc(50% - 0px);
        left: -9px;
      }

      .size-up-btn {
        top: calc(50% - -25px);
        left: -9px;
      }
    }
  }

  // root degilse
  &:not(.root)[data-child-direction="left"] {
    >.img-buttons {
      position: absolute;
      right: 0;
      left: auto;
      top: calc(50%);

      .remove-img-btn {
        top: calc(50% - 50px);
        right: -9px;
        left: auto;
      }

      .upload-img-btn {
        top: calc(50% - 25px);
        right: -9px;
        left: auto;
      }

      .size-down-btn {
        top: calc(50% - 0px);
        right: -9px;
        left: auto;
      }

      .size-up-btn {
        top: calc(50% - -25px);
        right: -9px;
        left: auto;
      }
    }

  }

  .upload-img-btn,
  .remove-img-btn {
    // display: none;
    position: absolute;
    // top:-4px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    border-radius: 100%;
    border-width: 2px;
    border-style: solid;
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    font-size: 10px;
    z-index: 15;

    &:hover {
      transform: scale(1.2);
    }
  }

  .size-up-btn,
  .size-down-btn {
    // display: none;
    position: absolute;
    // top: -4px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    border-radius: 100%;
    border-width: 2px;
    border-style: solid;
    background-color: #ffffff;
    width: 20px;
    height: 20px;
    font-size: 10px;
    z-index: 15;

    &:hover {
      transform: scale(1.2);
    }
  }

  .size-up-btn {
    border-color: #41c1db;
    color: #41c1db;
    padding: 3.4px 2px 1px 3.4px;

    &::before {
      content: "\f067";
    }
  }

  .size-down-btn {
    border-color: #41c1db;
    color: #41c1db;
    padding: 3.4px 2px 1px 3.4px;

    &::before {
      content: "\f068";
    }
  }

  .upload-img-btn {
    border-color: #5bb35b;
    color: #5bb35b;
    padding: 3.4px 2px 1px 3.4px;

    &::before {
      content: "\f093";
    }
  }

  .remove-img-btn {
    border-color: red;
    color: red;
    padding: 3.5px 0px 0px 3.5px;

    &::before {
      content: "\f00d";
    }
  }

  // }

  .options {
    position: absolute !important;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 12;
    @include opacity(0);
    visibility: hidden;
    transition: opacity 0.2s ease-in-out;

    .fa {
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      border-radius: 100%;
      border-width: 2px;
      border-style: solid;
      background-color: #ffffff;
      padding: 2px;
      font-size: 12px;
      padding: 2px 2px 1px 2.5px;
      width: 18px;
      height: 18px;
    }

    .fa:hover {
      transform: scale(1.3);
    }


    .upload-img-btn,
    .remove-img-btn {
      display: none;
    }

    .size-up-btn,
    .size-down-btn {
      display: none;
    }


    .get-item-format-btn {
      position: absolute;
      top: -7px;
      left: calc(50% - 11px);
      border-color: #d27254;
      color: #d27254;
      padding: 3.4px 2px 1px 3.4px;
      font-size: 11px;
      width: 21px;
      height: 21px;

      &::before {
        content: "\f1fc";
      }
    }

    .add-child {
      border-color: #5bb35b;
      color: #5bb35b;
      align-self: center;
      display: none;

      .add-child-options {
        display: none;
        width: 18px;
        height: 58px;
        margin-top: -37px;
        margin-left: 5px;
        padding: 0 10px;

        .add-text,
        .add-box {
          font-size: 10px;
          padding: 1px 2px 1px 2.5px;
        }

        .add-image {
          font-size: 9px;
          padding: 3.2px 2px 1px 2px;
        }

        .add-text {
          &:before {
            content: "\f031";
          }
        }

        .add-box {
          &:before {
            content: "\f0e5";
          }
        }

        .add-image {
          &:before {
            content: "\f03e";
          }
        }

        .fa {
          margin-right: 0;
          margin-bottom: 2px;
        }
      }

      &:hover {
        .add-child-options {
          display: table;
        }
      }
    }

    .add-child:before {
      content: "\f067";
    }

    .fa.add-child.left,
    .fa.add-child.right {
      display: none;
      position: absolute;
      top: calc(50% - 9px);
    }

    .fa.add-child.left {
      .add-child-options {
        margin-left: -34px;
      }
    }

    .expand-compress {
      border-color: #5b8ab3;
      color: #5b8ab3;
      position: absolute;
      top: -8px;
      left: calc(50% - 10px);
    }

    .expand-compress:before {
      content: "\f066";
    }


    .show-hide-toolbox {
      width: 18px;
      height: 18px;
      cursor: pointer;
      transition: all 0.1s ease-in-out;
      @include border-radius(100%);
      background-color: #fff;
      border: solid 2px #33bdd8;
      color: #33bdd8;
      position: absolute;
      bottom: -10px;
      left: calc(50% - 10px);
      font-size: 14px;

      &:hover {
        transform: scale(1.3);
      }
    }
  }
}

#port li.item.non-background> {
  .content {
    background-color: transparent;
    clip-path: none;
    -webkit-clip-path: none;
    padding: 0;

    .bg-color {
      background-color: transparent;
      clip-path: none;
      -webkit-clip-path: none;
    }

    .text {
      background-color: transparent;
      transition: border 0.2s ease-in-out;
      padding: 5px 5px;
      border: 1px solid transparent;
      // min-width: 60px;
      min-height: 35px;
      min-width: 120px !important;
      max-width: 130px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
        border-color: #cccccc;
      }
    }
  }
}

#port li.item.image-item> {
  .content {
    // width: 100px;
    // height: 100px;
    min-width: 100px;
    min-height: 100px;
    padding: 0 !important;

    .bg-color {
      // width: 100px;
      // height: 100px;
      min-width: 100px;
      min-height: 100px;
      padding: 0 !important;

      background-size: contain;
    }

    .text {
      display: none;
    }
  }

  .options {
    .show-hide-toolbox {
      display: none;
    }
  }
}

li.item:not(.has-children) {
  >.options {
    .expand-compress.fa {
      display: none;
    }
  }
}

li.item.collapsed {
  >.options {
    .expand-compress:before {
      content: "\f065";
    }
  }

  >canvas {
    display: none;
  }
}

li.item[data-child-direction="right"] {
  >.options {
    .add-child.right {
      display: block;
      right: -8px;
    }
  }
}

li.item[data-child-direction="left"] {
  >.options {
    .add-child.left {
      display: block;
      left: -8px;
    }
  }
}

li.item.current {
  >.content {
    animation: zoominout 1s infinite;

    &.only {
      // .size-up-btn,
      // .size-down-btn {
      //   display: block;
      // }
    }
  }

  >.options {
    @include opacity(1);
    visibility: visible;
  }

  // sadece image kutulari secilince image buttonlari gorunsun diye
  &.image-item,
  &.image-item-box,
  &.non-background.image-item.image-item-box {
    >.img-buttons {
      display: block;
    }
  }

  &.non-background {
    >.content {
      .text {
        background-color: rgba(255, 255, 255, 0.5) !important;
        border-color: #cccccc !important;
      }
    }

    .options {
      .show-hide-toolbox {
        bottom: -14px;
      }
    }
  }
}

.google-captcha-input {
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  line-height: 0 !important;
  font-size: 0 !important;
  width: 0 !important;
  display: block;
  border: 0 !important;
}

.icon-select {

  .custom-option-icon,
  .custom-option-label {
    display: inline-block;
    text-transform: capitalize;
  }

  .custom-option-icon {
    margin-right: 5px;
    color: $color-dodger-blue-v3;
  }
}

.select {
  box-shadow: 0 !important;
  font-size: 13px;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-weight: 500;
  color: $color-tundora;

  >div {
    box-shadow: none !important;
    border-color: hsl(0, 0%, 80%) !important;
    border-style: solid !important;
    border-width: 1px !important;
  }

  [class*="-menu"] {
    [class*="-Group"] {
      [class*="-option"] {
        padding-left: 30px;
      }
    }
  }

  label.error {
    font-weight: 400;
  }
}

.form-group {
  .select {
    [class*="-control"] {
      [class*="Value"] {
        color: #333333 !important;
        font-weight: 400 !important;
        margin-left: 9px;
      }

      [class*="placeholder"] {
        margin-left: 9px;
        font-weight: 400 !important;
      }
    }

    [class*="-menu"] {
      [class*="-option"] {
        padding-left: 18px;
        color: #333333 !important;
        font-weight: 400 !important;
      }
    }
  }
}

#shape svg {
  width: 15px;
  height: 15px;
  margin: -3px;
}

.select.relative-menu {
  [class*="-menu"] {
    position: relative;
  }
}

.layout-select.select,
.language-select.select {
  [class*="-menu"] {
    [class*="-option"] {
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }
}

.language-select.select {
  width: 75px;

  [class*="-menu"] {
    [class*="-option"] {
      text-align: left;
    }
  }
}

.pinch-zoom-container {
  position: initial !important;
}

.zoom-in {
  position: fixed;
  bottom: 19px;
  top: auto;
  right: 138px;
  width: 30px !important;
  height: 30px;
  max-width: 30px !important;
  padding: 0 !important;
  color: $color-white;
  font-size: 24px;
  line-height: 24px;
  border-radius: 20px;
  background: $color-dar-gray;
  z-index: 23;
}

.zoom-out {
  position: fixed;
  bottom: 19px;
  top: auto;
  right: 102px;
  width: 30px !important;
  height: 30px;
  max-width: 30px !important;
  padding: 0 !important;
  color: $color-white;
  font-size: 24px;
  line-height: 24px;
  border-radius: 20px;
  background: $color-dar-gray;
  z-index: 23;
}

.zoom-ratio {
  position: fixed;
  bottom: 22px;
  top: auto;
  right: 65px;
  color: $color-black;
  font-weight: 800;
  font-size: 15px;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
}

.plus-minus {
  position: relative;
  top: 1px;
}

.only {
  background-color: transparent !important;
}

.small-size {
  position: relative;
  float: left;
  left: 8x;
  width: 100%;
  width: 20px !important;
  border-radius: 0;
  border: 2px solid #d8d8d8 !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  text-indent: -999999px;
  cursor: pointer;
  height: 20px;
  top: 5px;

  @include phone {
    left: 0;
    top: 0;
  }
}

.small-size-title {
  margin-left: 6px;

  @include phone {
    margin-left: 7px;
  }
}

.medium-size {
  position: relative;
  float: left;
  left: -5px;
  width: 100%;
  width: 30px !important;
  border-radius: 0;
  border: 2px solid #d8d8d8 !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  text-indent: -999999px;
  cursor: pointer;
  height: 20px;
  top: 5px;

  @include phone {
    left: 0;
    top: 0;
  }
}

.medium-size-title {
  margin-left: 4px;

  @include phone {
    margin-left: 7px;
  }
}

.large-size {
  position: relative;
  float: left;
  left: -2px;
  width: 100%;
  width: 40px !important;
  border-radius: 0;
  border: 2px solid #d8d8d8 !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  text-indent: -999999px;
  cursor: pointer;
  height: 20px;
  top: 5px;

  @include phone {
    left: 0;
    top: 0;
  }
}

.large-size-title {
  margin-left: 10px;

  @include phone {
    margin-left: 12px;
  }
}

.auto-size {
  position: relative;
  float: left;
  left: 10px;
  width: 100%;
  width: 20px !important;
  border-radius: 0;
  border: 2px solid #d8d8d8 !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  text-indent: -999999px;
  cursor: pointer;
  height: 20px;
  top: 5px;

  @include phone {
    left: 0;
    top: 0;
  }
}

.auto-size-title {
  margin-left: 6px;

  @include phone {
    margin-left: 7px;
  }
}

.toolbox-tab {
  .toolbox-tab-buttons {
    display: flex;
    justify-content: center;
    align-items: center;

    .button {
      @include transition(all 0.2s ease-in-out);
      display: inline-block;
      min-width: 0px;
      min-height: 0px;
      background: white;
      height: 34px;
      width: 34px;
      border-radius: 100%;
      border: 2px solid #34c8f5;
      padding: 0 !important;
      margin: 10px;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        color: #34c8f5;
      }
    }
  }

  .toolbox-tab-contents {
    label {
      padding: 2px 3px 0px !important;
      padding-top: 3px !important;
    }

    .content {
      display: none;
      @include transition(all 0.2s ease-in-out);
      @include opacity(0);
      visibility: hidden;
      position: relative;
      top: -40px;
    }

    .content.active {
      display: block;
      @include opacity(1);
      visibility: visible;
      color: $color-silver-chalice !important;
    }
  }
  .toolbox-hr-right {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    transition: auto;
    top: 43px;
    width: 68px;
    right: 95px;
  }

  .toolbox-hr-left {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: relative;
    transition: auto;
    top: -43px;
    width: 68px;
    left: 95px;
  }

  .option {
    padding: 10px 0px;

    label {
      padding: 0px 0px;
    }

    small {
      padding: 0px 1px;
      color: black;
      font-weight: bold;
    }

    .option-list {
      img {
        height: 30px;
      }

      li {
        display: flex;
        align-items: center;
        margin: auto;
        padding: 12px 5px;
        float: left;
      }

      .line-1 {
        border: 1.5px solid #a700bf;
        width: 50px;
        margin: 8px;
      }

      .line-2 {
        border: 1.5px dashed #a700bf;
        width: 50px;
        margin: 8px;
      }

      .line-3 {
        border: 1.5px dotted #a700bf;
        width: 50px;
        margin: 8px;
      }
    }
  }

  .radio-small {
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 0px;

      +.radio-label {
        &:before {
          content: "";
          background: $color-concrete;
          border-radius: 4px;
          border: 1px solid #d2d2d2;
          display: inline-block;
          width: 27px;
          height: 21px;
          position: relative;
          top: -0.2em;
          margin-right: 1.2em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        +.radio-label {
          &:before {
            background-color: $color-picton-blue;
            border: 1px solid transparent;
          }
        }
      }
    }
  }

  .radio-auto {
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 0px;

      +.radio-label {
        &:before {
          content: "";
          background: $color-concrete;
          border-radius: 4px;
          border: 1px solid #d2d2d2;
          display: inline-block;
          width: 35px;
          height: 21px;
          position: relative;
          top: -0.2em;
          margin-left: -2em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        +.radio-label {
          &:before {
            background-color: $color-picton-blue;
            border: 1px solid transparent;
          }
        }
      }
    }

    span {
      text-transform: uppercase;
      font-size: 62% !important;
      position: relative;
      float: left;
      left: -2px;
      color: black;
    }
  }

  .radio-medium {
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 0px;

      +.radio-label {
        &:before {
          content: "";
          background: $color-concrete;
          border-radius: 4px;
          border: 1px solid #d2d2d2;
          display: inline-block;
          width: 40px;
          height: 21px;
          position: relative;
          top: -0.2em;
          margin-left: 1.2em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        +.radio-label {
          &:before {
            background-color: $color-picton-blue;
            border: 1px solid transparent;
          }
        }
      }
    }
  }

  .radio-large {
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 0px;

      +.radio-label {
        &:before {
          content: "";
          background: $color-concrete;
          border-radius: 4px;
          border: 1px solid #d2d2d2;
          display: inline-block;
          width: 45px;
          height: 21px;
          position: relative;
          top: -0.2em;
          margin-left: 1.2em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        +.radio-label {
          &:before {
            background-color: $color-picton-blue;
            border: 1px solid transparent;
          }
        }
      }
    }
  }

  .radio-map {
    input[type="radio"] {
      position: absolute;
      opacity: 0;
      width: 0px;

      +.radio-label {
        &:before {
          content: "";
          background: $color-concrete;
          border-radius: 100%;
          border: 1px solid darken($color-concrete, 25%);
          display: inline-block;
          width: 20px;
          height: 20px;
          position: relative;
          top: -0.2em;
          margin-left: 10px;
          margin-right: 5px;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        +.radio-label {
          &:before {
            background-color: $color-picton-blue;
            box-shadow: inset 0 0 0 4px $color-concrete;
          }
        }
      }
    }

    span {
      img {
        height: auto !important;
        width: auto !important;
      }
    }
  }

  .radio-map-6 {
    margin-left: 44px;
  }

  .shapeSize-checkbox-active {
    input[type="radio"] {
      position: absolute;
      opacity: 0;

      +.radio-label {
        &:before {
          content: "";
          background: $color-concrete;
          border-radius: 4px;
          border: 1px solid #d2d2d2;
          display: inline-block;
          width: 45px;
          height: 21px;
          position: relative;
          top: -0.2em;
          margin-left: 1.2em;
          vertical-align: top;
          cursor: pointer;
          text-align: center;
          transition: all 250ms ease;
        }
      }

      &:checked {
        +.radio-label {
          &:before {
            background-color: $color-picton-blue;
            border: 1px solid transparent;
          }
        }
      }
    }
  }
}

.ui-wrapper {
  padding: 20px 15px 90px 15px;
}

 .active-ai {
    img {
      height: 34px;
      width: 34px;
      border-radius: 100%;
      padding: 1px;
      background: #33bdd859 !important;

    }
  }