.ui {
  left: 0;
  top: 0px;
  width: 100%;
  -webkit-transition: -webkit-transform 500ms;
  transition: transform 500ms;
}

.ui h3 {
  background-color: #34495e;
  color: #fff;
  padding: 0.2em 0;
  margin: 0;
  text-transform: capitalize;
  text-align: center;
}

.ui .go {
  font-weight: bold;
}

.ui p {
  margin: 20px 16px 5px 16px;
}

.ui p:first-of-type {
  margin-top: 14px;
}

.ui p.desc {
  font-size: 13px;
}

.ui:not([id]) button {
  border: none;
  padding: 0;
  margin: 0 4px;
  background-color: transparent;
  width: 32px;
}

.ui button:first-child {
  margin-left: 0 !important;
}

.ui button:last-child {
  margin-right: 0 !important;
}

/* .ui button[data-command="Help"] {
  position: absolute;
  left: -60px;
    top: 20px;
} */

.ui table {
  width: 100%;
  border-collapse: collapse;
}

.ui table td:first-child {
  padding-left: 16px;
}

.ui table td:last-child {
  text-align: right;
  padding-right: 16px;
}

.ui span {
  text-transform: uppercase;
  font-size: 80%;
}

.ui #color {
  display: block;
}

.ui #color::after {
  clear: both;
  content: "";
  display: block;
}

.ui [data-color] {
  display: block;
  float: left;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  margin-right: 2px;
  border: 1px solid transparent;
}

.ui [data-color]:hover,
.ui [data-color]:first-child {
  border-color: #000;
}

.ui #github {
  position: absolute;
  right: 8px;
  bottom: 6px;
}

.ui #github img {
  vertical-align: middle;
}


/* present items menu */
.present-items-menu {
  position: fixed;
  /* overflow-y: scroll; */
  border-radius: 0 15px 0 0;
  top: 106px;
  right: auto;
  left: 0;
  bottom: 0;
  margin: auto;
  padding: 30px 0 45px 10px;
  z-index: 31;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 355px;
  border-bottom: none;
  border-right: none;
  background-color: #fff;

  transform: translate(-100%, 0);
  z-index: 24 !important;
  transition: transform 500ms;
}
.present-items-menu.visible {
  transform: translate(0, 0);
}
.present-items-menu-items {
  overflow-y: scroll;
  padding-right: 10px;
}
.present-items-menu-items::-webkit-scrollbar {
  width: 6px;
}
.present-items-menu-items::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.present-items-menu-items::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.present-selected-item {
  display: flex;
  flex-direction: column;
  padding: 10px 25px 10px 25px;
  background-color: white;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.2);
  margin-bottom: 12px;
  border-radius: 5px;
  height: 100px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}
.present-selected-item img {
  height: 70px;
  width: 140px;
}
.present-selected-item span.remove-btn {
  position: absolute;
  top: -3px;
  right: -5px;
  font-size: 17px;
  color: white;
  background-color: red;
  width: 28px;
  height: 28px;
  text-align: center;
  border-radius: 100%;
  box-shadow: 1px 1px 5px 1px rgba(0,0,0,0.2);
}
.present-selected-item span.remove-btn:hover {
  cursor: pointer;
}
.present-selected-item span.drag-btn {
  position: absolute;
  top: 12px;
  left: 5px;
  font-size: 11px;
  color: lightgrey;
}
.present-selected-item:hover {
  cursor: move;
}
.present-selected-text span {
  font-size: 12px;
  display: block;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}
.save-present-items-btn {
  cursor: pointer;
  position: absolute;
  bottom: 8px;
  right: 10px;
  background-color: #33bdd8;
  color: white !important;
  padding: 4.5px 10px;
  font-size: 12px;
  border-radius: 6px;
}
.not-present-item > .fa-desktop{
  color: gainsboro;
  font-size: 50px;
  margin: 70% 40% 10% 40%;
  text-align: center;
}
.not-present-item > span{
  padding-right: 10px;
  text-align: center;
  display: block;
  font-size: 17px;
  color: gainsboro;
  font-weight: 800;
}
/* help menu */
.help-wrap {
  overflow-y: scroll;
  padding: 0 20px 0 0;
  width: 355px;
  background-color: #fff;
}

.help-wrap::-webkit-scrollbar {
  width: 6px;
}
.help-wrap::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.help-wrap::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.ui#help {
  /* position: absolute;
	overflow-y: auto;
	right: auto;
	left: 0;
	background-color: rgba(255, 255, 255, 0.7);
	width: 327px;
	padding: 0;
	border: 0;
	border-right: 2px solid #dddddd;
	padding-bottom: 90px;
	z-index: 24;
	display: block; */

  position: fixed;
  /* overflow-y: scroll; */
  border-radius: 0 15px 0 0;
  top: 190px;
  right: auto;
  bottom: 0;
  margin: auto;
  /* padding: 20px 0; */
  padding: 45px 20px 20px 0;
  left: 0;
  z-index: 31;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 355px;
  border-bottom: none;
  border-right: none;
  background-color: #fff;
}
@media screen and (min-width: 500px) and (max-width: 991px) {
  .ui#help {
    top: 100px;
  }
}
/* .ui#help::-webkit-scrollbar {
  width: 6px;
}
.ui#help::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.ui#help::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
} */

.close-help {
  cursor: pointer;
  right: 0;
  position: absolute;
  top: 5px;
  transition: all 200ms;
  font-size: 26px;
  font-weight: 500;
  text-decoration: none;
  color: #333;
  margin: 0 15px;
}

.close-help:hover {
  color: #34c8f5;
  text-decoration: none;
}

.ui#help:not(.visible) {
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
  z-index: 24 !important;
}

.ui#help table tr td {
  font-size: 13px;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-weight: 400;
}

.ui#help p span {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
  font-weight: 600;
  font-size: 14px;
}

.ui#io button {
  width: 45%;
}

.ui#io button:nth-child(odd) {
  float: left;
}

.ui#io button:nth-child(even) {
  float: right;
}

.ui select.fa-select,
.ui select.fa-select option {
  font-family: fontAwesome;
  font-size: x-large;
}

#io {
  display: none;
}

#port {
  background-size: cover !important;
}
