@import url(ui.css);
@import url(item.css);
@import url(shape.css);
@import url(menu.css);
@import url(fonts.css);

/* * {
	font-family:  Century Gothic,CenturyGothic,AppleGothic,sans-serif;
} */

html,
body {
  margin: 0;
  height: 100%;
  -webkit-user-select: none; /* no magnifier on hold */
}

[contenteditable] {
  /* allow for editable items */
  -webkit-user-select: auto;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#port {
  overflow: hidden;
  font-size: 65%;
  background: #f2f2f2 url(../../img/background-image.png) no-repeat;
}

.ghost {
  position: absolute !important; /* to prevent collision with .content */
  opacity: 0.5;
  z-index: 1;
}

#tip {
  position: fixed;
  z-index: 1;
  left: 10px;
  bottom: 5px;
  transition: transform 0.2s;
  font-size: 1rem; /* do not scale with map zoom */
}

#tip:before {
  content: "Tip: ";
}

#tip.hidden {
  opacity: 0;
}
