@font-face {
  font-family: 'icomoon';
  src:  url('./../fonts/icomoon/icomoon.eot?6kfev9');
  src:  url('./../fonts/icomoon/icomoon.eot?6kfev9#iefix') format('embedded-opentype'),
    url('./../fonts/icomoon/icomoon.ttf?6kfev9') format('truetype'),
    url('./../fonts/icomoon/icomoon.woff?6kfev9') format('woff'),
    url('./../fonts/icomoon/icomoon.svg?6kfev9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-verified:before {
  content: "\e933";
}

.icon-gpt:before {
  content: "\1F916";
}
.icon-logout:before {
  content: "\e934";
}
.icon-mail:before {
  content: "\e935";
}
.icon-settings:before {
  content: "\e936";
}
.icon-template-icon:before {
  content: "\e932";
}
.icon-basket-icon:before {
  content: "\e931";
}
.icon-people:before {
  content: "\e930";
}
.icon-maps:before {
  content: "\e92f";
}
.icon-teacher:before {
  content: "\e92e";
}
.icon-toolbox:before {
  content: "\e92d";
}
.icon-chevron-down:before {
  content: "\e92c";
}
.icon-chevron-up:before {
  content: "\e92b";
}
.icon-colorize:before {
  content: "\e92a";
}
.icon-bold-icon:before {
  content: "\e929";
}
.icon-italic-icon:before {
  content: "\e928";
}
.icon-underlined-icon:before {
  content: "\e927";
}
.icon-align-left-icon:before {
  content: "\e926";
}
.icon-align-center-icon:before {
  content: "\e925";
}
.icon-align-right-icon:before {
  content: "\e924";
}
.icon-aspect-ratio-icon:before {
  content: "\e923";
}
.icon-shape-icon:before {
  content: "\e922";
}
.icon-brush-icon:before {
  content: "\e921";
}
.icon-border-icon:before {
  content: "\e920";
}
.icon-emoji-icon:before {
  content: "\e91f";
}
.icon-arrow-left:before {
  content: "\e91e";
}
.icon-arrow-right:before {
  content: "\e91d";
}
.icon-favorite-fill:before {
  content: "\e91c";
}
.icon-arrow-back:before {
  content: "\e91b";
}
.icon-done-black:before {
  content: "\e91a";
}
.icon-add-icon:before {
  content: "\e919";
}
.icon-photo-black:before {
  content: "\e918";
}
.icon-minus-icon:before {
  content: "\e917";
}
.icon-file-download:before {
  content: "\e916";
}
.icon-share-black:before {
  content: "\e915";
}
.icon-favorite-border:before {
  content: "\e914";
}
.icon-help-outline:before {
  content: "\e913";
}
.icon-copy-black:before {
  content: "\e912";
}
.icon-lock-black:before {
  content: "\e911";
}
.icon-lock-open-black:before {
  content: "\e910";
}
.icon-list-black:before {
  content: "\e90f";
}
.icon-zoom_in:before {
  content: "\e90e";
}
.icon-zoom_out:before {
  content: "\e90d";
}
.icon-check_circle:before {
  content: "\e90c";
}
.icon-down-arrow:before {
  content: "\e90b";
}
.icon-edit-icon:before {
  content: "\e90a";
}
.icon-map-shared-icon:before {
  content: "\e909";
}
.icon-search-icon:before {
  content: "\e908";
}
.icon-show_icon:before {
  content: "\e907";
}
.icon-delete-icon:before {
  content: "\e906";
}
.icon-share_list_icon:before {
  content: "\e905";
}
.icon-map_list_icon:before {
  content: "\e904";
}
.icon-list-icon:before {
  content: "\e903";
}
.icon-grid-icon:before {
  content: "\e902";
}
.icon-favorite-icon:before {
  content: "\e901";
}
.icon-create_new_map_icon:before {
  content: "\e900";
}
