.toolbox {
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif;
}

.item {
  position: absolute;
  list-style-type: none;
}

.item.cut {
  opacity: 0.5 !important;
}

.item.collapsed .children {
  display: none;
}

.content {
  position: relative;
  display: inline-block;
  /* white-space: nowrap; */
  cursor: pointer;
  z-index: 11;
}

.content > * {
  vertical-align: middle;
}

.content textarea::-webkit-scrollbar {
  width: 7px;
}
.content textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}
.content textarea {
  resize: none;
  border: none;
  max-height: 40px;
  min-width: 50px;
  max-width: 170px;
  background: transparent;
}

.item .text {
  display: inline-block;
  text-align: center;
  /* min-width: 2px; */
  min-width: 165px;
  /* max-width: 500px; */
  max-width: 165px;
  min-height: 1.2em;
  line-height: 1.2em;
  outline: none;
  color: inherit;
  font-size: 17px;
  font-weight: normal;
  /* line-break: anywhere; */
  /* word-break: break-all; */
}
.item .text span {
  background-color: transparent !important;
  color: unset !important;
}

.item .content.only {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  z-index: 11;
}
.item .content.only  .text {
  display: inline-block;
  text-align: center;
  min-height: 1.2em !important;
  min-width: 2px !important;
  max-width: unset !important;
  line-height: 1.2em;
  outline: none;
  color: inherit;
  font-size: 17px;
  font-weight: normal;
}

.value,
.status {
  margin-right: 0.2em;
}

.text ~ .value,
.text ~ .status {
  margin-left: 0.2em;
  margin-right: 0;
}

.value {
  opacity: 0.7;
}

.value:not(:empty):before {
  content: "(";
}

.value:not(:empty):after {
  content: ")";
}

.status {
  font-size: 150%;
  line-height: 0.8;
}

.status.yes:after {
  content: "✔";
  color: #0f0;
}

.status.no:after {
  content: "✘";
  color: #f00;
}

.root > canvas {
  z-index: -15;
}

canvas {
  position: absolute;
  left: 0;
  top: 0;
  cursor: not-allowed;
  pointer-events: none;
}

.toggle {
  position: absolute;
  width: 13px;
  height: 13px;
  line-height: 12px;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #006b8c;
  background-color: #34c8f5;
  text-align: center;
  font-weight: bold;
  font-family: sans-serif;
  color: #fff;
  opacity: 0.7;
}

.toggle:hover {
  opacity: 1;
}

.toggle:before {
  content: "−";
}

.collapsed > .toggle:before {
  content: "+";
}

:not(.current):not(.collapsed) > .toggle {
  /* NOT display:none - we need to have non-zero dimensions for layouting */
  visibility: hidden;
}
