.coupon-area {
  display: flex !important;
  justify-content: flex-end;
}

.inner-container {
  display: flex;
}

.price-area {
  display: flex;
  flex-direction: column;

  &__old-price {
    height: 25px !important;
    font-size: 18px !important;
    text-decoration: line-through;
  }
}
.new-price {
  font-size: 34px !important;
}

.month-card {
  height: 220px !important;
}

.year-card {
  height: 220px !important;
}
