/* Map Fonts */

@font-face {
  font-family: 'Architects Daughter';
  src: url('../../fonts/Architects_Daughter/ArchitectsDaughter-Regular.eot');
  src: url('../../fonts/Architects_Daughter/ArchitectsDaughter-Regular.eot#iefix') format('embedded-opentype'),
       url('../../fonts/Architects_Daughter/ArchitectsDaughter-Regular.woff') format('woff'),
       url('../../fonts/Architects_Daughter/ArchitectsDaughter-Regular.woff2') format('woff2'),
       url('../../fonts/Architects_Daughter/ArchitectsDaughter-Regular.svg#ArchitectsDaughter-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nerko One';
  src: url('../../fonts/Nerko_One/NerkoOne-Regular.eot');
  src: url('../../fonts/Nerko_One/NerkoOne-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Nerko_One/NerkoOne-Regular.woff') format('woff'),
       url('../../fonts/Nerko_One/NerkoOne-Regular.woff2') format('woff2'),
       url('../../fonts/Nerko_One/NerkoOne-Regular.svg#NerkoOne-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Indie Flower';
  src: url('../../fonts/Indie_Flower/IndieFlower.eot');
  src: url('../../fonts/Indie_Flower/IndieFlower.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Indie_Flower/IndieFlower.woff') format('woff'),
       url('../../fonts/Indie_Flower/IndieFlower.woff2') format('woff2'),
       url('../../fonts/Indie_Flower/IndieFlower.svg#IndieFlower') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Caveat';
  src: url('../../fonts/Caveat/Caveat-Regular.eot');
  src: url('../../fonts/Caveat/Caveat-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Caveat/Caveat-Regular.woff') format('woff'),
       url('../../fonts/Caveat/Caveat-Regular.woff2') format('woff2'),
       url('../../fonts/Caveat/Caveat-Regular.svg#Caveat-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Courgette';
  src: url('../../fonts/Courgette/Courgette-Regular.eot');
  src: url('../../fonts/Courgette/Courgette-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Courgette/Courgette-Regular.woff') format('woff'),
       url('../../fonts/Courgette/Courgette-Regular.woff2') format('woff2'),
       url('../../fonts/Courgette/Courgette-Regular.svg#Courgette-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Patrick Hand';
  src: url('../../fonts/Patrick_Hand/PatrickHand-Regular.eot');
  src: url('../../fonts/Patrick_Hand/PatrickHand-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Patrick_Hand/PatrickHand-Regular.woff') format('woff'),
       url('../../fonts/Patrick_Hand/PatrickHand-Regular.woff2') format('woff2'),
       url('../../fonts/Patrick_Hand/PatrickHand-Regular.svg#PatrickHand-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Cairo';
  src: url('../../fonts/Cairo/Cairo-Regular.eot');
  src: url('../../fonts/Cairo/Cairo-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Cairo/Cairo-Regular.woff') format('woff'),
       url('../../fonts/Cairo/Cairo-Regular.woff2') format('woff2'),
       url('../../fonts/Cairo/Cairo-Regular.svg#Cairo-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('../../fonts/Source_Sans_Pro/SourceSansPro-Regular.eot');
  src: url('../../fonts/Source_Sans_Pro/SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Source_Sans_Pro/SourceSansPro-Regular.woff') format('woff'),
       url('../../fonts/Source_Sans_Pro/SourceSansPro-Regular.woff2') format('woff2'),
       url('../../fonts/Source_Sans_Pro/SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../fonts/Nunito/Nunito-Regular.eot');
  src: url('../../fonts/Nunito/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Nunito/Nunito-Regular.woff') format('woff'),
       url('../../fonts/Nunito/Nunito-Regular.woff2') format('woff2'),
       url('../../fonts/Nunito/Nunito-Regular.svg#Nunito-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Grape Nuts';
  src: url('../../fonts/Grape_Nuts/GrapeNuts-Regular.eot');
  src: url('../../fonts/Grape_Nuts/GrapeNuts-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Grape_Nuts/GrapeNuts-Regular.woff') format('woff'),
       url('../../fonts/Grape_Nuts/GrapeNuts-Regular.woff2') format('woff2'),
       url('../../fonts/Grape_Nuts/GrapeNuts-Regular.svg#GrapeNuts-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kalam';
  src: url('../../fonts/Kalam/Kalam-Regular.eot');
  src: url('../../fonts/Kalam/Kalam-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Kalam/Kalam-Regular.woff') format('woff'),
       url('../../fonts/Kalam/Kalam-Regular.woff2') format('woff2'),
       url('../../fonts/Kalam/Kalam-Regular.svg#Kalam-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Forum';
  src: url('../../fonts/Forum/Forum.eot');
  src: url('../../fonts/Forum/Forum.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Forum/Forum.woff') format('woff'),
       url('../../fonts/Forum/Forum.woff2') format('woff2'),
       url('../../fonts/Forum/Forum.svg#Forum') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Shadows Into Light Two';
  src: url('../../fonts/Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.eot');
  src: url('../../fonts/Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.woff') format('woff'),
       url('../../fonts/Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.woff2') format('woff2'),
       url('../../fonts/Shadows_Into_Light_Two/ShadowsIntoLightTwo-Regular.svg#ShadowsIntoLightTwo-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lemonada';
  src: url('../../fonts/Lemonada/Lemonada-Regular.eot');
  src: url('../../fonts/Lemonada/Lemonada-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Lemonada/Lemonada-Regular.woff') format('woff'),
       url('../../fonts/Lemonada/Lemonada-Regular.woff2') format('woff2'),
       url('../../fonts/Lemonada/Lemonada-Regular.svg#Lemonada-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Syne';
  src: url('../../fonts/Syne/Syne-Regular.eot');
  src: url('../../fonts/Syne/Syne-Regular.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Syne/Syne-Regular.woff') format('woff'),
       url('../../fonts/Syne/Syne-Regular.woff2') format('woff2'),
       url('../../fonts/Syne/Syne-Regular.svg#Syne-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
