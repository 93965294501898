.emoji-grinning-face:before {
  // "\01F600" 😀
  content: "";
  // background: transparent url(../img/emoji-sprite.png) 0 0 no-repeat;
  // background-position: -581px 0;
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -30px 0;
}
.emoji-beaming-face-with-smiling-eyes:before {
  // "\01F601" 😁
  content: "";
  // background: transparent url(../img/emoji-sprite.png) 0 0 no-repeat;
  // background-position: -37px 0;
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -90px 0;
}
.emoji-grinning-squinting-face:before {
  // "\01F606" 😆
  content: "";
  // background: transparent url(../img/emoji-sprite.png) 0 0 no-repeat;
  // background-position: -421px 0;
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -120px 0;
}
.emoji-grinning-face-with-sweat:before {
  // "\01F605" 😅
  content: "";
  // background: transparent url(../img/emoji-sprite.png) 0 0 no-repeat;
  // background-position: -1029px 0;
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -150px 0;
}
.emoji-face-with-tears-of-joy:before {
  // "\01F602" 😂
  content: "";
  // background: transparent url(../img/emoji-sprite.png) 0 0 no-repeat;
  // background-position: -1413px 0;
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -210px 0;
}
.emoji-slightly-smiling-face:before {
  // "\01F642" 🙂
  content: "";
  // background: transparent url(../img/emoji-sprite.png) 0 0 no-repeat;
  // background-position: -69px 0;
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -240px 0;
}
.emoji-upside-down-face:before {
  // "\01F643" 🙃
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -270px 0;
}
.emoji-winking-face:before {
  // "\01F609" 😉
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -300px 0;
}
.emoji-smiling-face-with-halo:before {
  // "\01F607" 😇
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -360px 0;
}
.emoji-smiling-face-with-hearts:before {
  // "\01F970" 🥰
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -390px 0;
}
.emoji-smiling-face-with-heart-eyes:before {
  // "\01F60D" 😍
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -420px 0;
}
.emoji-star-struck:before {
  // "\01F929" 🤩
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -450px 0;
}
.emoji-face-blowing-a-kiss:before {
  // "\01F618" 😘
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: 0 -30px;
}
.emoji-smiling-face:before {
  // "\0263A" ☺
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -60px -30px;
}
.emoji-winking-face-with-tongue:before {
  // "\01F61C" 😜
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -240px -30px;
}
.emoji-money-mouth-face:before {
  // "\01F911" 🤑
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -330px -30px;
}
.emoji-hugging-face:before {
  // "\01F917" 🤗
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -360px -30px;
}
.emoji-shushing-face:before {
  // "\01F92B" 🤫
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -420px -30px;
}
.emoji-zipper-mouth-face:before {
  // "\01F910" 🤐
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: 0 -60px;
}
.emoji-neutral-face:before {
  // "\01F610" 😐
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -60px -60px;
}
.emoji-expressionless-face:before {
  // "\01F611" 😑
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -90px -60px;
}
.emoji-face-without-mouth:before {
  // "\01F636" 😶
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -120px -60px;
}
.emoji-smirking-face:before {
  // "\01F60F" 😏
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -150px -60px;
}
.emoji-grimacin-face:before {
  // "\01F62C" 😬
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -240px -60px;
}
.emoji-lying-face:before {
  // "\01F925" 🤥
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -270px -60px;
}
.emoji-pensive-face:before {
  // "\01F614" 😔
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -330px -60px;
}
.emoji-sleepy-face:before {
  // "\01F62A" 😪
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -360px -60px;
}
.emoji-drooling-face:before {
  // "\01F924" 🤤
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -390px -60px;
}
.emoji-sleeping-face:before {
  // "\01F634" 😴
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -420px -60px;
}
.emoji-face-with-medical-mask:before {
  // "\01F637" 😷
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -450px -60px;
}
.emoji-face-with-thermometer:before {
  // "\01F912" 🤒
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: 0 -90px;
}
.emoji-face-with-head-bandage:before {
  // "\01F915" 🤕
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -30px -90px;
}
.emoji-nauseated-face:before {
  // "\01F922" 🤢
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -60px -90px;
}
.emoji-face-vomiting:before {
  // "\01F92E" 🤮
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -90px -90px;
}
.emoji-sneezing-face:before {
  // "\01F927" 🤧
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -120px -90px;
}
.emoji-hot-face:before {
  // "\01F975" 🥵
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -150px -90px;
}
.emoji-cold-face:before {
  // "\01F976" 🥶
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -180px -90px;
}
.emoji-woozy-face:before {
  // "\01F974" 🥴
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -210px -90px;
}
.emoji-knocked-out-face:before {
  // "\01F635" 😵
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -240px -90px;
}
.emoji-exploding-head:before {
  // "\01F92F" 🤯
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -270px -90px;
}
.emoji-cowboy-hat-face:before {
  // "\01F920" 🤠
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -300px -90px;
}
.emoji-partying-face:before {
  // "\01F973" 🥳
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -330px -90px;
}
.emoji-smiling-face-with-sunglasses:before {
  // "\01F60E" 😎
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -390px -90px;
}
.emoji-nerd-face:before {
  // "\01F913" 🤓
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -420px -90px;
}
.emoji-face-with-monocle:before {
  // "\01F9D0" 🧐
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -450px -90px;
}
.emoji-face-screaming-in-fear:before {
  // "\01F631" 😱
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: 0 -150px;
}
.emoji-confounded-face:before {
  // "\01F616" 😖
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -30px -150px;
}
.emoji-persevering-face:before {
  // "\01F623" 😣
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -60px -150px;
}
.emoji-disappointed-face:before {
  // "\01F61E" 😞
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -90px -150px;
}
.emoji-yawning-face:before {
  // "\01F971" 🥱
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -210px -150px;
}
.emoji-face-with-steam-from-nose:before {
  // "\01F624" 😤
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -240px -150px;
}
.emoji-pouting-face:before {
  // "\01F621" 😡
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -270px -150px;
}
.emoji-face-with-symbols-on-mouth:before {
  // "\01F92C" 🤬
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -330px -150px;
}
.emoji-smiling-face-with-horns:before {
  // "\01F608" 😈
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -360px -150px;
}
.emoji-skull-and-crossbones:before {
  // "\02620" ☠
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -450px -150px;
}
.emoji-pile-of-poo:before {
  // "\01F4A9" 💩
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: 0 -180px;
}
.emoji-clown-face:before {
  // "\01F921" 🤡
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -30px -180px;
}
.emoji-ogre:before {
  // "\01F479" 👹
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -60px -180px;
}
.emoji-goblin:before {
  // "\01F47A" 👺
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -90px -180px;
}
.emoji-see-no-evil-monkey:before {
  // "\01F648" 🙈
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -30px -210px;
}
.emoji-hear-no-evil-monkey:before {
  // "\01F649" 🙉
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -60px -210px;
}
.emoji-speak-no-evil-monkey:before {
  // "\01F64A" 🙊
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -90px -210px;
}
.emoji-kiss-mark:before {
  // "\01F48B" 💋
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -120px -210px;
}
.emoji-heart-with-arrow:before {
  // "\01F498" 💘
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -180px -210px;
}
.emoji-sparkling-heart:before {
  // "\01F496" 💖
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -240px -210px;
}
.emoji-red-heart:before {
  // "\02764" ❤
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: 0 -240px;
}
.emoji-orange-heart:before {
  // "\01F9E1" 🧡
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -30px -240px;
}
.emoji-yellow-heart:before {
  // "\01F49B" 💛
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -60px -240px;
}
.emoji-green-heart:before {
  // "\01F49A" 💚
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -90px -240px;
}
.emoji-blue-heart:before {
  // "\01F499" 💙
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -120px -240px;
}
.emoji-purple-heart:before {
  // "\01F49C" 💜
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -150px -240px;
}
.emoji-hundred-points:before {
  // "\01F4AF" 💯
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -270px -240px;
}
.emoji-anger-symbol:before {
  // "\01F4A2" 💢
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -300px -240px;
}
.emoji-collision:before {
  // "\01F4A5" 💥
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -330px -240px;
}
.emoji-sweat-droplets:before {
  // "\01F4A6" 💦
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -390px -240px;
}
.emoji-dashing-away:before {
  // "\01F4A8" 💨
  content: "";
  background: transparent url(../img/emoji-smileys.png) 0 0 no-repeat;
  background-position: -420px -240px;
}
.emoji-waving-hand:before {
  // "\01F44B" 👋
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -30px 0;
}
.emoji-raised-back-of-hand:before {
  // "\01F91A" 🤚
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -210px 0;
}
.emoji-hand-with-fingers-splayed:before {
  // "\01F590" 🖐
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -390px 0;
}
.emoji-raised-hand:before {
  // "\0270B" ✋
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -90px -30px;
}
.emoji-vulcan-salute:before {
  // "\01F596" 🖖
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -270px -30px;
}
.emoji-ok-hand:before {
  // "\01F44C" 👌
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -450px -30px;
}
.emoji-pinching-hand:before {
  // "\01F90F" 🤏
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -150px -60px;
}
.emoji-victory-hand:before {
  // "\0270C" ✌
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -330px -60px;
}
.emoji-crossed-fingers:before {
  // "\01F91E" 🤞
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -30px -90px;
}
.emoji-love-you-gesture:before {
  // "\01F91F" 🤟
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -210px -90px;
}
.emoji-sign-of-the-horns:before {
  // "\01F918" 🤘
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -390px -90px;
}
.emoji-call-me-hand:before {
  // "\01F919" 🤙
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -90px -120px;
}
.emoji-backhand-index-pointing-left:before {
  // "\01F448" 👈
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -270px -120px;
}
.emoji-backhand-index-pointing-right:before {
  // "\01F449" 👉
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -450px -120px;
}
.emoji-backhand-index-pointing-up:before {
  // "\01F446" 👆
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -150px -150px;
}
.emoji-backhand-index-pointing-down:before {
  // "\01F447" 👇
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -30px -180px;
}
.emoji-index-pointing-up:before {
  // "\0261D" ☝
  content: "";
  background: transparent url(../img/emoji-body01.png) 0 0 no-repeat;
  background-position: -210px -180px;
}
.emoji-baby:before {
  // "\01F476" 👶
  content: "";
  background: transparent url(../img/emoji-body02.png) 0 0 no-repeat;
  background-position: -30px 0;
}
.emoji-boy:before {
  // "\01F466" 👦
  content: "";
  background: transparent url(../img/emoji-body02.png) 0 0 no-repeat;
  background-position: -390px 0;
}
.emoji-girl:before {
  // "\01F467" 👧
  content: "";
  background: transparent url(../img/emoji-body02.png) 0 0 no-repeat;
  background-position: -90px -30px;
}
.emoji-man-red-hair:before {
  // "\01F468", "\0200D", "\01F9B0" 👨‍🦰
  content: "";
  background: transparent url(../img/emoji-body02.png) 0 0 no-repeat;
  background-position: -150px -150px;
}
.emoji-woman:before {
  // "\01F469" 👩
  content: "";
  background: transparent url(../img/emoji-body02.png) 0 0 no-repeat;
  background-position: -450px -210px;
}
