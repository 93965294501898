/* Screen Dimensions*/
$screen-xs            : 320px;
$screen-xs-min        : $screen-xs;
$screen-phone         : $screen-xs-min;

$screen-sm            : 768px;
$screen-sm-min        : $screen-sm;
$screen-tablet        : $screen-sm-min;

$screen-md            : 1024px;
$screen-md-min        : $screen-md;
$screen-desktop       : $screen-md-min;

$screen-lg            : 1366px;
$screen-lg-min        : $screen-lg;
$screen-lg-desktop    : $screen-lg-min;

$screen-xlg           : 1600px;
$screen-xlg-min       : $screen-xlg;
$screen-xlg-desktop   : $screen-xlg-min;

$screen-xs-max        : ($screen-sm-min - 1);
$screen-sm-max        : ($screen-md-min - 1);
$screen-md-max        : ($screen-lg-min - 1);

$screen-phone-max     : ($screen-sm-min - 1);
$screen-tablet-max    : ($screen-md-min - 1);
$screen-desktop-max   : ($screen-lg-min - 1);
$screen-l-desktop-max : ($screen-xlg-min - 1);

/* Font Sizes */
$font-size-default: 11px;

/* Color Variables */
$color-alto: rgba(221, 221, 221, 1); // #dddddd
$color-anakiwa: rgba(147, 230, 255, 1); // #93e6ff
$color-bittersweet: rgba(255, 98, 98, 1); // #ff6262
$color-black: rgba(0, 0, 0, 1); // #000000
$color-blue-gem: rgba(50, 13, 139, 1); // #320d8b
$color-cobalt: rgba(0, 66, 191, 1); // #0042bf
$color-concrete: rgba(242, 242, 242, 1); // #f2f2f2
$color-crimson: rgba(232, 25, 50, 1); // #e81932
$color-cyan-aqua: rgba(0, 255, 245, 1); // #00fff5
$color-cyan-aqua-08: rgba(0, 255, 245, 0.8); // #00fff5
$color-cyan-aqua-v2: rgba(0, 206, 255, 1); // #00ceff
$color-cyan-aqua-v2-08: rgba(0, 206, 255, 0.8); // #00ceff
$color-dodger-blue: rgba(0, 177, 255, 1); // #00b1ff
$color-dodger-blue-v2: rgba(38, 153, 251, 1); // #2699fb
$color-dodger-blue-v3: rgba(74, 162, 253, 1); // #4aa2fd
$color-dusty-gray: rgba(153, 153, 153, 1); // #999999
$color-gray: rgba(128, 128, 128, 1); // #808080
$color-green: rgba(21, 185, 0, 1); // #15b900
$color-green-08: rgba(21, 185, 0, 0.8); // #15b900
$color-kurnikova: rgba(255, 218, 119, 1); // #ffda77
$color-mine-shaft: rgba(51, 51, 51, 1); // #333333
$color-mona-lisa: rgba(255, 149, 149, 1); // #ff9595
$color-picton-blue: rgba(52, 200, 245, 1); // #34c8f5
$color-red: rgba(255, 0, 0, 1); // #ff0000
$color-red-08: rgba(255, 0, 0, 0.8); // #ff0000
$color-selective-yellow: rgba(255, 177, 0, 1); // #ffb100
$color-selective-yellow-08: rgba(255, 177, 0, 0.8); // #ffb100
$color-selective-yellow-v2: rgba(255,186,0,1); //#ffba00
$color-silver: rgba(187, 187, 187, 1); // #bbbbbb
$color-silver-chalice: rgba(178, 178, 178, 1); // #b2b2b2
$color-sweet-pink: rgba(255, 163, 163, 1); // #ffa3a3
$color-tundora: rgba(69, 69, 69, 1); // #454545
$color-white: rgba(255, 255, 255, 1); // #ffffff
$color-blue-picton: rgba(67,204,245,1); //#43ccf5
$color-blue-jordy: rgbs(124, 174, 238,1); //#7CAEEE
$color-yellow-selective:rgba(255, 177, 0,1); //#FFB100
$color-pass-french:rgba(188, 224, 253,1); //#bce0fd
$color-pass-french-08:rgba(188, 224, 253,0.8); //#bce0fd
$color-alto: rgba(216, 216, 216,1); //#d8d8d8
$color-yellow-amber: rgba(255, 196, 0,1); //#ffc400
$color-pizazz: rgba(255,137,0,1); //#ff8900
$color-malibu: rgba(74,199,255,1); //#4ac7ff
$color-mercury: rgba(228, 228, 228,1); //#e4e4e4
$color-heliotrope: rgba(239, 130, 243,1); //#ef82f3
$color-blaze-orange: rgba(255,111,0,1); //#ff6f00
$color-blaze-orange-08: rgba(255,111,0,0.8); //#ff6f00
$color-blue-shine : rgba(51,189,216,1); //#33bdd8
$color-yellow-shine : rgba(252,208,77,1); //#fcd04d
$color-aqua-blue : rgba(51, 189, 216, 0.5);
$color-light-black : rgba(57, 57, 57, 1); //#393939
$color-red-color : rgba(209,63,45,1); //#d13f2d
$color-red-color-08 : rgba(209,63,45,0.8); //#d13f2d

/* Effects */
$cubicEffect: cubic-bezier(0.05, 0.74, 0.27, 0.99);
$color-silver-gray: rgba(204,204,204,1);
$color-dar-gray: #a3a9ad;
$color-cerulean: rgba(0, 150, 235,1);

$color-login-input: #f5f5f5;
$color-checkbox: #33bdd8;
$color-login-btn-hover: #fec32d;
$color-login-btn-active: #f2b029;
$color-google-btn-hover: #fafafa;
$color-google-btn-active: #ededed;
$color-facebook-btn: #395b98;
$color-facebook-btn-hover: #3f66aa;
$color-facebook-btn-active: #35538b;
